export interface IApplicationTheme {
  defaultTextColor: string;
  disabledBackgroundColor: string;

  defaultLinkColor: string;
  hoveredLinkColor: string;
  mainBrandColor: string;
  defaultBorderColor: string;

  disabledTextColor: string;
  alarmaColor: string;
  mainCtaColor: string;
}

export const BLACK_THEME: IApplicationTheme = {
  defaultTextColor: '#243237',
  disabledTextColor: '#8097A2',
  disabledBackgroundColor: '#F9F9F9',

  alarmaColor: '#E91F63',

  defaultBorderColor: '#E8EDEE',
  defaultLinkColor: '#4FCEE0',
  hoveredLinkColor: '#47BCCD', // '#E91F63',
  mainBrandColor: '#4FCEE0',
  mainCtaColor: '#8BC34A',
};
