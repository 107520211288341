import { DefaultInternalLink } from '@components/DefaultLink';
import { VerificationMark } from '@components/marks/verification-mark/VerificationMark';
import styled from '@styles/styled-helpers';

export const Container = styled.form`
  flex: 1 1 auto;
`;

export const ConfirmationContainer = styled.section`
  flex: 1 1 auto;
`;

export const MainSection = styled.div`
  width: 100%;
  padding: 2em 1em;
`;
export const SecondarySection = styled(MainSection)`
  /* background-color: #f6f8f8; */
`;

export const Header = styled.h2`
  width: 100%;
  max-width: ${800 / 32}em;
  margin: auto;
  font-size: 2em;
  line-height: 1.25;
  text-align: center;
  padding-bottom: 0.5em;
`;
export const Description = styled.div`
  width: 100%;
  max-width: ${800 / 16}em;
  margin: auto;
  line-height: 1.5;
  text-align: center;
  padding-bottom: 1.3em;
`;

export const ProceedButton = styled.button`
  display: block;
  width: auto;
  min-height: 3em;
  margin: 0 auto 0 auto;
  padding: 0.57142857em 1.14285714em;
  color: #fff;
  background-color: #8bc34a;
  line-height: 1.714em;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #7cb03f;
  }

  &:disabled {
    background: ${(props) => props.theme.disabledBackgroundColor} !important;
    color: ${(props) => props.theme.disabledTextColor} !important;

    cursor: default;
  }
`;
export const SearchLink = styled(DefaultInternalLink)`
  display: block;
  width: 100%;
  max-width: 16em;
  min-height: 3em;
  margin: 1em auto 0 auto;
  padding: 0.57142857em 1.14285714em;
  text-align: center;
  font-weight: 500;
  line-height: 1.714em;
  color: #fff;
  background-color: #8bc34a;
  border-radius: 4px;
  cursor: pointer;

  &:visited {
    color: #fff;
  }

  &:hover {
    color: #fff;
    background-color: #7cb03f;
  }
`;

export const ExampleBlock = styled.div`
  width: 100%;
  max-width: 45em;
  margin: 2.875em auto 0 auto;
  border-radius: 4px;
  border: 1px solid #e6edf1;
`;
export const Window = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5em;
  height: 1.5em;
  background-color: #e6edf1;

  &::before {
    content: ' ';
    width: 0.5em;
    height: 0.5em;
    background-color: #fff;
    border-radius: 50%;
  }

  &::after {
    content: ' ';
    width: 0.5em;
    height: 0.5em;
    background-color: #fff;
    border-radius: 50%;
  }
`;
export const Point = styled.div`
  width: 0.5em;
  height: 0.5em;
  margin: 0 0.5em;
  background-color: #fff;
  border-radius: 50%;
`;
export const MainData = styled.div`
  padding: 0.5em 0.75em;
`;
export const ExampleData = styled(MainData)`
  background-color: #d6f8b0;
`;

export const CompanyName = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.3666667;
  padding: 0.3em 0;
`;

export const CustomVerificationMark = styled(VerificationMark)`
  margin-right: 0.5em;
  margin-top: -0.2em;
  vertical-align: middle;
`;

export const FutureStatusLabel = styled.span`
  font-weight: 700;
`;
export const PageLink = styled(DefaultInternalLink)`
  display: block;
  position: relative;
`;
export const ExampleDescription = styled.span`
  display: block;
  width: 100%;
  padding: 0.5em 0;
  max-width: ${45 / 0.875}em;
  margin: auto;
  color: #8097a2;
  font-size: 0.875em;
  font-style: italic;
  line-height: ${1.5 / 0.875};
`;
export const AboutBlock = styled.div`
  display: flex;
  width: 100%;
  max-width: ${1280 / 16}em;
  margin: auto;
  justify-content: space-around;
  flex-wrap: wrap;
`;
export const AboutItem = styled.div`
  flex: 1 1 auto;
  padding: 1em;
  max-width: 23.5em;
`;
export const AboutImage = styled.img`
  display: block;
  margin: auto;
`;
export const AboutHeader = styled.div`
  width: 100%;
  font-size: 1.375em;
  font-weight: 700;
  line-height: 1.1em;
  text-align: center;
  padding: 1.6em 0 0.9em 0;
`;
export const AboutDescription = styled.div`
  line-height: 1.5;
  text-align: center;
`;

export const Confirmation = styled.div`
  width: 100%;
  max-width: ${840 / 16}em;
  margin: auto;
`;

export const StepContainer = styled.div<{ current: boolean }>`
  padding: 1.25em;
  margin-bottom: 2em;

  /* border: 1px solid #f5f5f5; */
  box-shadow: ${(props) => (props.current ? '0 2px 4px 0 rgba(0, 0, 0, 0.075)' : 'none')};
`;
export const FirstStepContainer = styled(StepContainer)`
  margin: 0;
`;
export const Step = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const CurrentStep = styled.span`
  display: block;
  padding: 1em 0;
  color: #8097a2;
  font-size: 0.875em;
  line-height: ${1.5 / 0.875}em;
  font-style: italic;
  text-align: center;
`;
export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 ${4 / 1.75}em;
  height: ${4 / 1.75}em;
  margin-right: 1em;
  line-height: ${4 / 1.75};
  text-align: center;
  color: #fff;
  background-color: #4fcee0;

  font-weight: 500;
  font-size: 1.75em;

  border-radius: 50%;
`;
export const StepData = styled.div``;
export const StepDataFirst = styled(StepData)`
  align-self: center;
`;
export const StepHeader = styled.div`
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1.43;
`;
export const StepDescription = styled.div`
  padding: 0.5em 0 1em 0;
  line-height: 1.5;
`;

export const FAQBlock = styled.div`
  width: 100%;
  max-width: ${1280 / 16}em;
  margin: auto;
  padding: 0.5em 0;
`;
export const FAQLastBlock = styled(FAQBlock)`
  padding-bottom: 3em;
`;

export const Question = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1.75em;
  line-height: 1.43;
`;
export const Answer = styled.span`
  display: block;
  padding: 0.5em 0;
  line-height: 1.5;
`;

export const Complete = styled.div`
  display: block;
  margin: auto;
  height: 0.45em;
  width: 0.7em;

  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;

  transform: rotate(-45deg);
`;

export const BaloonWrapper = styled.div`
  position: relative;
  padding-top: 1.5em;
  width: 100%;
  max-width: 30.625em;
  margin: auto;
`;
export const BaloonArrow = styled.div`
  position: absolute;
  top: 1em;
  left: 0;
  width: 100%;
`;
export const BaloonIcon = styled.div`
  width: 1em;
  height: 1em;
  margin: auto;
  transform: rotate(45deg);
  background-color: #4fcee0;
`;
export const BaloonContent = styled.div`
  padding: 1.57em 0.857em;
  color: #fff;
  font-weight: 700;
  font-size: 0.875em;
  line-height: ${1.5 / 0.875};
  text-align: center;
  border-radius: 4px;
  background-color: #4fcee0;
`;
