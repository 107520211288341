import { OrganizationStatus, FutureStatus } from '../types';
import { UnknownError } from '@app/common-declarations/errors';

export const formatStatus = (status: OrganizationStatus) => {
  switch (status) {
    case OrganizationStatus.Active:
      return `Действующее предприятие`;
    case OrganizationStatus.Liquidated:
      return `Прекратило деятельность`;
    case OrganizationStatus.Reorganization:
      return `Находится в процессе реорганизации в форме присоединения к нему других ЮЛ`;
    case OrganizationStatus.Liquidation:
      return `Находится в стадии ликвидации`;
    case OrganizationStatus.ChangeOfLocation:
      return `Принято решении о изменении места нахождения`;
    case OrganizationStatus.AuthorizedCapitalDecrease:
      return `В процессе уменьшения уставного капитала`;
    default:
      throw new UnknownError(`Ошибка при отображении статуса: ${status}`);
  }
};

export const formatFutureStatus = (status: FutureStatus) => {
  switch (status) {
    case FutureStatus.Active:
      return `продолжит деятельность`;
    case FutureStatus.Liquidated:
      return `прекратит деятельность`;
    default:
      throw new UnknownError(`Ошибка при отображении будущего статуса: ${status}`);
  }
};
