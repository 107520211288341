import gql from 'graphql-tag';
import { OrganizationStatus } from '@components/user-flow/SmartSearchLink';

enum FutureStatus {
  Active = 'ACTIVE',
  Liquidated = 'LIQUIDATED',
}
export interface BasicCompanyInfo {
  __typename: 'Company';
  ogrn: string;
  shortName: string;
  fullName: string;
  correctedName: string;
  inn?: string;
  kpp?: string;
  form: {
    compact: string;
    full: string;
  };
  status: {
    currentStatus: OrganizationStatus;
    futureStatus: FutureStatus;
    grnDate: number;
    text: string;
  };
  address?: {
    full?: string;
  };
}

export interface BasicIndividualInfo {
  __typename: 'Individual';
  ogrnip: string;
  inn?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  form: {
    compact: string;
    full: string;
  };
  status: {
    currentStatus: OrganizationStatus;
    futureStatus: FutureStatus;
    grnDate: number;
    text: string;
  };
  address?: {
    full?: string;
  };
}

export interface BasicPersonInfo {
  __typename: 'Person';
  firstName?: string;
  lastName?: string;
  middleName?: string;
  inn?: string;
}

export interface BasicForeignOrganizationInfo {
  __typename: 'ForeignOrganization';
  name?: string;
  inn?: string;
  registrationNumber: string;
  address: {
    full?: string;
    country?: string;
  };
}

export interface BasicUnverifiedEntityInfo {
  __typename: 'UnverifiedEntity';
  name?: string;
  address?: {
    full?: string;
    country?: string;
  };
}

export const basicCompanyInfoFragment = gql`
  fragment basicCompanyInfo on Company {
    ogrn
    shortName
    fullName
    correctedName: fullName(autoCorrection: true)
    ogrn
    inn
    kpp
    form {
      full
      compact
    }
    status {
      currentStatus
      futureStatus
      grnDate
      text
    }
    address {
      full
    }
  }
`;

export const basicIndividualInfoFragment = gql`
  fragment basicIndividualInfo on Individual {
    inn
    ogrnip
    lastName
    firstName
    middleName
    form {
      compact
      full
    }
    status {
      currentStatus
      futureStatus
      grnDate
      text
    }
    address {
      full
    }
  }
`;

export const basicPersonInfoFragment = gql`
  fragment basicPersonInfo on Person {
    firstName
    lastName
    middleName
    inn
  }
`;

export const basicForeignOrganizationInfoFragment = gql`
  fragment basicForeignOrganizationInfo on ForeignOrganization {
    name
    inn
    registrationNumber
    address {
      full
      country
    }
  }
`;

export const basicUnverifiedEntityInfoFragment = gql`
  fragment basicUnverifiedEntityInfo on UnverifiedEntity {
    name
    address {
      full
      country
    }
  }
`;
