import styled from '@styles/styled-helpers';
import * as React from 'react';
import { Link } from 'react-router-dom';

const isUrlExternal = (url?: string) => {
  if (!url) {
    return false;
  }

  const tempLink = document.createElement('a');
  tempLink.href = url;
  return tempLink.hostname !== window.location.hostname;
};

interface IDefaultLinkProps {
  newTab?: boolean;
}

export const DefaultLink = styled.a`
  border-bottom: 1px solid transparent;
  color: ${(props) => props.theme.defaultLinkColor};
  text-decoration: none;
  cursor: pointer;

  &:active,
  &:visited {
    color: ${({ theme }) => theme.defaultLinkColor};
  }

  &:hover {
    color: ${({ theme }) => theme.hoveredLinkColor};
  }
`;

export const DefaultExternalLink = styled(DefaultLink).attrs<IDefaultLinkProps>({
  target: (props: React.AnchorHTMLAttributes<HTMLAnchorElement> & { newTab?: boolean }) =>
    isUrlExternal(props.href) || props.newTab ? '_blank' : undefined,
  rel: (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) =>
    isUrlExternal(props.href) ? 'noreferrer noopener' : '',
})<IDefaultLinkProps>``;

export const DefaultInternalLink = DefaultLink.withComponent(Link);
