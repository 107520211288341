import { GET_CONVERSATION_COUNT_QUERY, GetCountResponse, GetMessagesVariables } from '../queries/list';
import { useQuery } from '@apollo/react-hooks';
import { useAuthority } from '@app/components/providers/authorities/useAuthority';

export const useUnreadCount = (pollInterval: number = 0) => {
  const authority = useAuthority();
  const { data } = useQuery<GetCountResponse, GetMessagesVariables>(GET_CONVERSATION_COUNT_QUERY, {
    variables: { first: 0, status: 'UNREAD' },
    skip: authority.pending || !authority.authorities.has('dialog'),
    pollInterval,
  });

  if (data && data.conversations) {
    return data.conversations.totalCount;
  }

  return undefined;
};
