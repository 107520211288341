export interface IntrospectionResultData {
  __schema: {
    types: Array<{
      kind: string;
      name: string;
      possibleTypes: Array<{
        name: string;
      }>;
    }>;
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'CompanyHead',
        possibleTypes: [
          {
            name: 'Person',
          },
          {
            name: 'Company',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'StructureMember',
        possibleTypes: [
          {
            name: 'Person',
          },
          {
            name: 'Company',
          },
          {
            name: 'ForeignOrganization',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Entity',
        possibleTypes: [
          {
            name: 'Individual',
          },
          {
            name: 'Company',
          },
          {
            name: 'ForeignOrganization',
          },
          {
            name: 'UnverifiedEntity',
          },
          {
            name: 'Person',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Description',
        possibleTypes: [
          {
            name: 'DescriptionPerson',
          },
          {
            name: 'DescriptionCompany',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LegalPublisherEntity',
        possibleTypes: [
          {
            name: 'Individual',
          },
          {
            name: 'Company',
          },
          {
            name: 'Person',
          },
          {
            name: 'ForeignOrganization',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LegalFactInfo',
        possibleTypes: [
          {
            name: 'LegalFactPledge',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GroupFilterValue',
        possibleTypes: [
          {
            name: 'KadFilterCategory',
          },
          {
            name: 'KadFilterResult',
          },
          {
            name: 'KadFilterYear',
          },
          {
            name: 'KadFilterSide',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'LegalEntity',
        possibleTypes: [
          {
            name: 'Individual',
          },
          {
            name: 'Company',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'BillingPointsSubs',
        possibleTypes: [
          {
            name: 'BillingPointsGrnSubs',
          },
        ],
      },
    ],
  },
};

export default result;
