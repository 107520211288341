import React, { Suspense } from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';
const AuthorityDebugPage = React.lazy(() =>
  import(/* webpackChunkName: "debug-page" */ './AuthorityDebugPage').then((result) => ({
    default: result.AuthorityDebugPage,
  })),
);

export const AsyncAuthorityDebugPage = () => {
  return (
    <Suspense fallback={<SlimLoader />}>
      <AuthorityDebugPage />
    </Suspense>
  );
};
