import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps } from 'react-router';
import { EntityQueryInput, OrganizationStatus, FutureStatus } from '../../report/content/common/types';
import { EgrulDataResponse, egrulQuery } from '../../report/content/company/sections/card/queries/egrul';
import { EgripDataResponse, egripQuery } from '../../report/content/individual/sections/card/queries/egrip';
import { confirmationMutation, ConfirmationMutationInput, ConfirmationMutationResponse } from './query';
import { formatLongDate, formatAsDistance } from '@helpers/formatters';
import { HardcodeEgrulData, HardcodeDialogData, LoaderEgrulData } from './parts/HardcodeDataBlock';
import { useAccountData } from '@app/components/providers/authentication/useAccountData';
import { useMutation } from '@apollo/react-hooks';
import { UnknownError } from '@app/common-declarations/errors';
import { TitledBlock } from './parts/PromoTitledBlock';
import { DefaultExternalLink } from '@components/DefaultLink';
import { validateOgrnWeak } from '@helpers/data-validation';
import { getEntityName } from '@helpers/entity-helpers';
import { BasicCompanyInfo, BasicIndividualInfo } from '../../report/content/common/queries/common';
import { RiskMarker } from '../../report/content/company/sections/card/parts/RiskNotification';
import { formatStatus, formatFutureStatus } from '../../report/content/common/formatters/status';
import { SeverityType } from '../../report/content/company/sections/compliance/helpers';
import { Helmet } from 'react-helmet-async';
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude';
import { RRAmplitudeProvider } from '@components/amplitude-wrapper/amplitude-wrapper';
import { IUserDataProviderRenderProps } from '@app/components/providers/authentication/UserDataProvider';

import {
  Container,
  ConfirmationContainer,
  MainSection,
  SecondarySection,
  Header,
  Description,
  ProceedButton,
  ExampleBlock,
  Window,
  Point,
  MainData,
  ExampleData,
  CompanyName,
  CustomVerificationMark,
  FutureStatusLabel,
  PageLink,
  ExampleDescription,
  AboutBlock,
  AboutItem,
  AboutImage,
  AboutHeader,
  AboutDescription,
  Confirmation,
  FirstStepContainer,
  StepContainer,
  Step,
  CurrentStep,
  StepNumber,
  StepData,
  StepDataFirst,
  StepHeader,
  StepDescription,
  FAQBlock,
  FAQLastBlock,
  Question,
  Answer,
  Complete,
  SearchLink,
  BaloonWrapper,
  BaloonArrow,
  BaloonIcon,
  BaloonContent,
} from './parts';
// Выкинули даду из промоблока ниже - выкинем и её импорт
// import { saleDate } from '@app/constants/constant';

const NO_DATA_FOUND_PLACEHOLDER = 'Нет данных';

interface IConairmationPromo {
  grn?: string;
  accountData?: IUserDataProviderRenderProps;
  loading?: boolean;
  unconfirmedData?: React.ReactNode;
}

export const ConfirmationPromoComponent = (props: IConairmationPromo) => {
  const isCompany = !!(props.grn && validateOgrnWeak(props.grn).valid);
  const basicCompanyData = useQuery<EgrulDataResponse, EntityQueryInput>(egrulQuery, {
    variables: { grn: props.grn || '' },
    skip: !props.grn || !isCompany,
  });
  const basicIndividualData = useQuery<EgripDataResponse, EntityQueryInput>(egripQuery, {
    variables: { grn: props.grn || '' },
    skip: !props.grn || isCompany,
  });

  const isLoading = isCompany
    ? basicCompanyData.loading || isEmpty(basicCompanyData.data)
    : basicIndividualData.loading || isEmpty(basicIndividualData.data);

  const basic =
    props.grn && !isLoading ? (isCompany ? basicCompanyData.data!.basic : basicIndividualData.data!.basic) : undefined;

  return (
    <Amplitude
      eventProperties={{
        grn: props.grn,
      }}
    >
      <LogOnMount eventType="Просмотр — confirmation promo" />
      <ConfirmationContainer>
        <MainSection>
          <Header>{`Подтверждение ${
            !props.grn || basicCompanyData.loading || basicIndividualData.loading
              ? 'вашей компании'
              : getEntityName(basic!)
          } в сервисе Контрагентио`}</Header>
          <Description>
            Вы можете подтвердить право управления компанией, указать контактные данные и стать официальным
            представителем вашей организации в сервисе Контрагентио.
          </Description>

          {!!props.grn && (
            <>
              <RRAmplitudeProvider>
                {({ logEvent }) => (
                  <ProceedButton
                    disabled={props.loading || (props.accountData && !props.accountData.account)}
                    onClick={() => logEvent('Промо подтверждения — CTA: подтвердить организацию', { grn: props.grn })}
                  >
                    {props.accountData && props.accountData.account
                      ? props.accountData.account.isAuthenticated
                        ? props.loading
                          ? 'Подтверждение...'
                          : 'Подтвердить организацию'
                        : 'Зарегистрироваться и подтвердить организацию'
                      : 'Подождите, идёт загрузка...'}
                  </ProceedButton>
                )}
              </RRAmplitudeProvider>
              <BaloonWrapper>
                <BaloonArrow>
                  <BaloonIcon />
                </BaloonArrow>
                <BaloonContent>
                  {
                    // И тут я вспомнил, нам не надо убирать весь этот блок, нам только надо убрать информацию о скидке - делаем
                  }
                  {`Подтвердите компанию и получите подписку на тариф «Бизнес» на неделю в подарок!`}
                </BaloonContent>
              </BaloonWrapper>
            </>
          )}

          <ExampleBlock>
            <Window>
              <Point />
            </Window>
            <MainData>
              {props.grn ? (
                !isLoading ? (
                  <>
                    <CompanyName>
                      <CustomVerificationMark />
                      {getEntityName(basic!)}
                    </CompanyName>
                    {isCompany && (
                      <TitledBlock title="Полное наименование" content={(basic! as BasicCompanyInfo).fullName} />
                    )}
                    <TitledBlock
                      title="Статус"
                      content={
                        isCompany ? (
                          <>
                            {basic!.status.currentStatus !== OrganizationStatus.Active && (
                              <RiskMarker
                                severity={
                                  basic!.status.futureStatus === FutureStatus.Active
                                    ? SeverityType.Medium
                                    : SeverityType.Severe
                                }
                              />
                            )}
                            {basic!.status.text || formatStatus(basic!.status.currentStatus)}
                            {basic!.status.currentStatus !== OrganizationStatus.Active &&
                              ` – ${formatLongDate(basic!.status.grnDate)}`}
                            {basic!.status.currentStatus === OrganizationStatus.Active && ' по данным ЕГРЮЛ'}
                            {basic!.status.currentStatus === OrganizationStatus.Reorganization && (
                              <FutureStatusLabel>, {formatFutureStatus(basic!.status.futureStatus)}</FutureStatusLabel>
                            )}
                            {basic!.status.currentStatus === OrganizationStatus.Reorganization && (
                              <PageLink to="reorganization">→ Участники реорганизации</PageLink>
                            )}
                          </>
                        ) : (
                          <>
                            {formatStatus(basic!.status.currentStatus)}
                            {basic!.status.currentStatus !== OrganizationStatus.Active &&
                              ` – ${formatLongDate(basic!.status.grnDate)}`}
                            {basic!.status.currentStatus === OrganizationStatus.Active && ' по данным ЕГРИП'}
                          </>
                        )
                      }
                    />
                    <TitledBlock title="Дата регистрации">
                      {formatLongDate(basic!.registration.grnDate)} ({formatAsDistance(basic!.registration.grnDate)})
                    </TitledBlock>
                    {isCompany ? (
                      <TitledBlock
                        title="ОГРН"
                        content={(basic! as BasicCompanyInfo).ogrn || NO_DATA_FOUND_PLACEHOLDER}
                      />
                    ) : (
                      <TitledBlock
                        title="ОГРНИП"
                        content={(basic! as BasicIndividualInfo).ogrnip || NO_DATA_FOUND_PLACEHOLDER}
                      />
                    )}
                    {isCompany ? (
                      <TitledBlock
                        title="ИНН / КПП"
                        content={`${(basic! as BasicCompanyInfo).inn ||
                          NO_DATA_FOUND_PLACEHOLDER} / ${(basic! as BasicCompanyInfo).kpp ||
                          NO_DATA_FOUND_PLACEHOLDER}`}
                      />
                    ) : (
                      <TitledBlock
                        title="ИНН"
                        content={(basic! as BasicIndividualInfo).inn || NO_DATA_FOUND_PLACEHOLDER}
                      />
                    )}
                    <TitledBlock title="Юридический адрес">
                      {basic!.address.full || NO_DATA_FOUND_PLACEHOLDER}
                    </TitledBlock>
                  </>
                ) : (
                  <LoaderEgrulData />
                )
              ) : (
                <HardcodeEgrulData />
              )}
            </MainData>
            <ExampleData>{props.unconfirmedData || <HardcodeDialogData />}</ExampleData>
          </ExampleBlock>
          <ExampleDescription>
            {props.grn && !basicCompanyData.loading && !basicIndividualData.loading
              ? `Пример отображения данных карточки ${getEntityName(
                  basic!,
                )} после подтверждения компании в сервисе Контрагентио`
              : ' '}
          </ExampleDescription>
        </MainSection>

        <SecondarySection>
          <Header>Зачем подтверждать компанию в сервисе Контрагентио?</Header>
          <AboutBlock>
            <AboutItem>
              <AboutImage src={require('./assets/info.svg')} />
              <AboutHeader>
                Знак <br /> «Проверенный контрагент»
              </AboutHeader>
              <AboutDescription>
                Прозрачность – признак честности. Подтвержденные компании отмечаются особым знаком в карточке компании и
                вызовут больше доверия у ваших контрагентов
              </AboutDescription>
            </AboutItem>
            <AboutItem>
              <AboutImage src={require('./assets/conference.svg')} />
              <AboutHeader>
                Не теряйте <br />
                своих клиентов
              </AboutHeader>
              <AboutDescription>
                Нас часто спрашивают о том, как можно связаться с компанией. Если вы добавите актуальные контакты, ваши
                клиенты легко смогут найти вас
              </AboutDescription>
            </AboutItem>
            <AboutItem>
              <AboutImage src={require('./assets/documents.svg')} />
              <AboutHeader>Готовый пакет документов вашей компании</AboutHeader>
              <AboutDescription>
                Когда у вас в следующий раз запросят реквизиты компании, просто отправьте им ссылку на страницу в
                сервисе Контрагентио
              </AboutDescription>
            </AboutItem>
          </AboutBlock>
        </SecondarySection>

        <MainSection>
          <Header>Как подтвердить компанию в сервисе Контрагентио?</Header>
          <Confirmation>
            <FirstStepContainer>
              <Step>
                <StepNumber>1</StepNumber>
                <StepDataFirst>
                  <StepHeader>Выберите вашу компанию в сервисе Контрагентио</StepHeader>
                </StepDataFirst>
              </Step>
              {!props.grn && (
                <RRAmplitudeProvider>
                  {({ logEvent }) => (
                    <SearchLink to="/search" onClick={() => logEvent('Промо подтверждения — CTA: найти компанию')}>
                      Найдите вашу компанию
                    </SearchLink>
                  )}
                </RRAmplitudeProvider>
              )}
            </FirstStepContainer>
            <CurrentStep>{props.grn ? 'вы находитесь здесь' : ''}</CurrentStep>

            <StepContainer current>
              <Step>
                <StepNumber>2</StepNumber>
                <StepData>
                  <StepHeader>Приложите пакет документов компании</StepHeader>
                  <StepDescription>
                    Вам осталось совсем немного: прикрепите недостающие документы, мы их проверим, подтвердим вашу
                    компанию в сервисе и в подарок дадим подписку на тариф «Бизнес» сервиса Контрагентио на 1 неделю.
                  </StepDescription>
                </StepData>
              </Step>
              {!!props.grn && (
                <RRAmplitudeProvider>
                  {({ logEvent }) => (
                    <ProceedButton
                      disabled={props.loading || (props.accountData && !props.accountData.account)}
                      onClick={() => logEvent('Промо подтверждения — CTA: приложить документы', { grn: props.grn })}
                    >
                      {props.accountData && props.accountData.account
                        ? props.accountData.account.isAuthenticated
                          ? props.loading
                            ? 'Подтверждение...'
                            : 'Приложить документы'
                          : 'Зарегистрироваться и приложить документы'
                        : 'Подождите, идёт загрузка...'}
                    </ProceedButton>
                  )}
                </RRAmplitudeProvider>
              )}
            </StepContainer>

            <StepContainer>
              <Step>
                <StepNumber>3</StepNumber>
                <StepDataFirst>
                  <StepHeader>Мы проверим все документы</StepHeader>
                  <StepDescription>В рабочее время это занимает не более пары часов.</StepDescription>
                </StepDataFirst>
              </Step>
            </StepContainer>

            <StepContainer>
              <Step>
                <StepNumber>
                  <Complete />
                </StepNumber>
                <StepDataFirst>
                  <StepHeader>Ваша компания подтверждена!</StepHeader>
                  <StepDescription>
                    И мы подарим вам подписку на{' '}
                    <DefaultExternalLink href={`${window.rrenv.urls.dialogWizard}/billing/plans`} newTab>
                      тариф «Бизнес»
                    </DefaultExternalLink>{' '}
                    сервиса Контрагентио на неделю.
                  </StepDescription>
                </StepDataFirst>
              </Step>
            </StepContainer>
          </Confirmation>
        </MainSection>

        <SecondarySection>
          <Header>Вопросы и ответы</Header>
          <FAQBlock>
            <Question>Это бесплатно?</Question>
            <Answer>
              Да, это бесплатно. Более того, за подтверждение вашей организации мы подарим вам неделю подписки на тариф
              «Бизнес» сервиса Контрагентио.{' '}
              <DefaultExternalLink href={`${window.rrenv.urls.dialogWizard}/billing/plans`} newTab>
                Подробнее о тарифах
              </DefaultExternalLink>
              .
            </Answer>
          </FAQBlock>
          <FAQBlock>
            <Question>Какие данные я могу разместить?</Question>
            <Answer>
              Вы сможете указать следующие сведения о компании:
              <br />
              1. Телефон, сайт и адрес электронной почты
              <br />
              2. Фактический и почтовый адреса, при наличии – адреса торговых, производственных и складских помещений
              <br />
              3. Краткое описание деятельности компании
              <br />
            </Answer>
          </FAQBlock>
          <FAQBlock>
            <Question>Какие документы мне нужно предоставить?</Question>
            <Answer>
              Чтобы убедиться в том, что вы действительно имеете права на управление организацией, мы запросим у вас
              сканы учредительных документов компании, приказ о назначении руководителя, а также скан присоединения к
              оферте. Мы не запрашиваем никаких документов, которые являются коммерческой тайной. Загруженные документы
              по умолчанию не будут видны другим пользователям сервиса.
            </Answer>
          </FAQBlock>
          <FAQBlock>
            <Question>Смогу ли я обмениваться документами со своими контрагентами?</Question>
            <Answer>
              После того, как вы подтвердите организацию, мы подарим вам неделю подписки на тариф «Бизнес», который
              позволит вам обмениваться пакетами учредительных документов с вашими контрагентами в рамках сервиса
              Контрагентио.Диалог.
              <DefaultExternalLink href={window.rrenv.urls.dialogLanding} newTab>
                {' '}
                Подробнее о сервисе
              </DefaultExternalLink>
              .
            </Answer>
          </FAQBlock>
          <FAQLastBlock>
            <Question>Как будет выглядеть карточка моей компании после подтверждения?</Question>
            <Answer>
              Примеры карточек компаний, которые уже подтвердили себя в сервисе Контрагентио:
              <DefaultExternalLink
                href={`${window.rrenv.urls.dialogWizard}/company/1117746390277/7731407330/info`}
                newTab
              >
                {' '}
                ООО ЕАЕ-КОНСАЛТ
              </DefaultExternalLink>
              ,
              <DefaultExternalLink
                href={`${window.rrenv.urls.dialogWizard}/company/1127746512926/7734682952/info`}
                newTab
              >
                {' '}
                ООО ГК ЭНСИ
              </DefaultExternalLink>
            </Answer>
          </FAQLastBlock>

          {!!props.grn ? (
            <RRAmplitudeProvider>
              {({ logEvent }) => (
                <ProceedButton
                  disabled={props.accountData && !props.accountData.account}
                  onClick={() => logEvent('Промо подтверждения — CTA: подтвердить организацию', { grn: props.grn })}
                >
                  {props.accountData && props.accountData.account
                    ? props.accountData.account.isAuthenticated
                      ? 'Подтвердить организацию'
                      : 'Зарегистрироваться и подтвердить организацию'
                    : 'Подождите, идёт загрузка...'}
                </ProceedButton>
              )}
            </RRAmplitudeProvider>
          ) : (
            <RRAmplitudeProvider>
              {({ logEvent }) => (
                <SearchLink to="/search" onClick={() => logEvent('Промо подтверждения — CTA: найти компанию')}>
                  Найдите вашу компанию
                </SearchLink>
              )}
            </RRAmplitudeProvider>
          )}
        </SecondarySection>
      </ConfirmationContainer>
    </Amplitude>
  );
};

interface ConfirmationPromoPageProps {
  grn?: string;
  create?: 'create';
}

export const ConfirmationPromoPage = (
  props: RouteComponentProps<ConfirmationPromoPageProps> & { unconfirmedData?: React.ReactNode },
) => {
  const { grn, create } = props.match.params;
  const isConfirmed = useRef(false);
  const [loading, setLoading] = useState(false);

  const accountData = useAccountData({ fetchPolicy: 'network-only' });
  const [createConfirmationDialog] = useMutation<ConfirmationMutationResponse, ConfirmationMutationInput>(
    confirmationMutation,
  );

  useEffect(() => {
    if (accountData.account && accountData.account.isAuthenticated && grn && create && !isConfirmed.current) {
      isConfirmed.current = true;
      createConfirmationDialog({ variables: { grn: grn || '' } }).then((result) => {
        if (result.errors) {
          throw new UnknownError('Произошла ошибка при подтверждении компании.');
        }
        props.history.push(`/dialog/${result.data!.created.package}/confirmation`);
      });
    }
  }, [accountData.account]);

  const proceed = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const activeConfirmation =
        accountData.account &&
        accountData.account.verifications
          .map((ver) => ({
            grn:
              (ver.target.organization.__typename === 'Company' && ver.target.organization.ogrn) ||
              (ver.target.organization.__typename === 'Individual' && ver.target.organization.ogrnip),
            id: ver.id,
          }))
          .find((ver) => ver.grn === grn);

      if (activeConfirmation) {
        props.history.push(`/dialog/${activeConfirmation.id}/confirmation`);
        return;
      }

      if (accountData.account && accountData.account.isAuthenticated) {
        setLoading(true);
        createConfirmationDialog({ variables: { grn: grn || '' } }).then((result) => {
          if (result.errors) {
            throw new UnknownError('Произошла ошибка при подтверждении компании.');
          }
          setLoading(false);
          props.history.push(`/dialog/${result.data!.created.package}/confirmation`);
        });
      } else {
        props.history.replace('/auth/sign-up', { returnTo: `${props.match.url}/create` });
      }
    },
    [grn, accountData.account],
  );

  return (
    <Container onSubmit={proceed}>
      <Helmet>
        <title>Подтверждение компании в сервисе</title>
      </Helmet>
      <ConfirmationPromoComponent
        grn={grn}
        accountData={accountData}
        loading={loading}
        unconfirmedData={props.unconfirmedData}
      />
    </Container>
  );
};
