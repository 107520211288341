import { RiskData } from './query';

export enum SeverityType {
  Severe = 'SEVERE',
  Medium = 'MEDIUM',
  Low = 'LOW',
  Notice = 'NOTICE',
  None = 'NONE',
}

export const riskBlock = {
  selfAssessment: {
    title: 'Критерии для самостоятельной оценки контрагента',
    codes: [
      'LIQUIDATED',
      'INVALID_ADDRESS',
      'MASS_ADDRESS',
      'POTENTIAL_MASS_ADDRESS',
      'DISHONEST_CONTRACTOR',
      'RECENTLY_REGISTERED',
    ],
  },
  leadership: {
    title: 'Руководство',
    codes: [
      'INVALID_MANAGEMENT_PERSON',
      'INVALID_MANAGEMENT_LEGAL',
      'LIQUIDATED_MANAGEMENT_LEGAL',
      'DISQUALIFIED_MANAGEMENT',
      'DISQUALIFIED_LEGAL',
      'MASS_MANAGEMENT_PERSON',
      'POTENTIAL_MASS_MANAGEMENT_PERSON',
      'POTENTIAL_MASS_MANAGEMENT_LEGAL',
      'SAME_MANAGER_FOUNDER',
    ],
  },
  structure: {
    title: 'Структура капитала',
    codes: [
      'MASS_FOUNDER',
      'POTENTIAL_MASS_FOUNDER',
      'INVALID_FOUNDER',
      'MIN_CAPITAL',
      'CAPITAL_SHARE_IS_OWNED_BY_SELF',
      'CANT_ESTABLISH_BENEFICIAL_OWNER',
      'CAPITAL_IS_LESS_THAN_MIN, MIN_CAPITAL',
    ],
  },
  compliance: {
    title: 'Риски, связанные с легализацией доходов, полученных преступным путем и финансированием терроризма',
    codes: [
      'TERRORIST_PERSON',
      'TERRORIST_LEGAL',
      'OPERATIONAL_CHARITY',
      'OPERATIONAL_CASH',
      'OPERATIONAL_WEAPONS',
      'OPERATIONAL_GAMBLING',
      'OPERATIONAL_CREDIT',
      'OPERATIONAL_ART',
      'OPERATIONAL_ANTIQ',
      'OPERATIONAL_REAL_ESTATE',
    ],
  },
};

function orderRisks(list: RiskData[]) {
  const severityOrder = [
    SeverityType.Severe,
    SeverityType.Medium,
    SeverityType.Low,
    SeverityType.Notice,
    SeverityType.None,
  ];
  return list.sort((a, b) => {
    const diff = severityOrder.indexOf(a.occurrences[0].severity) - severityOrder.indexOf(b.occurrences[0].severity);

    if (diff > 0) {
      return 1;
    }

    if (diff < 0) {
      return -1;
    }

    if (diff === 0) {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
    }

    return 0;
  });
}

// Убираем из списка риски не на SELF
export function getSelfComplianceList(list: ReadonlyArray<RiskData>) {
  return (list || []).map((item) => {
    return {
      ...item,
      occurrences: item.occurrences.filter((occ) => occ.relation === 'SELF'),
    };
  });
}

// Список сработавших рисков
export function getRiskList(list: RiskData[]) {
  return orderRisks(list.filter((item) => item.occurrences.length));
}

function orderAlphabetically(list: ReadonlyArray<RiskData>) {
  return [...list].sort((a, b) => {
    if (a.title > b.title) {
      return 1;
    }
    if (a.title < b.title) {
      return -1;
    }

    return 0;
  });
}
// Возвращаем список рисков, соответствующих заданному блоку, за исключением тех по которым есть алерты
export function getBlockItems(list: ReadonlyArray<RiskData>, codes: string[]) {
  return orderAlphabetically(list.filter((item) => codes.includes(item.risk) && !item.occurrences.length));
}
