import gql from 'graphql-tag';

export interface IAuthenticationResponse {
  authentication: {
    token: string;
  };
}

export interface IAuthenticationRequestVariables {
  email: string;
  password: string;
}

export const AUTHENTICATION_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    authentication {
      token: authenticate(email: $email, password: $password)
    }
  }
`;

export const ANONYMOUS_AUTHENTICATION_MUTATION = gql`
  mutation anonymousLogin {
    authentication {
      token: anonymousLogin
    }
  }
`;
