import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncOrganizationPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "mail" */ './OrganizationsPage').then((res) => res.OrganizationsPage),
  loading() {
    return <SlimLoader pending />;
  },
});
