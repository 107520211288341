import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConfirmationPromoPage } from './confirmation/ConfirmationPromo';
import { ComplianceReportPage } from './compliance-report/ComplianceReport';
import { PresalePromoPage } from './presale/PresalePromo';

export const PromoPage = (props: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${props.match.url}/confirmation/:grn?/:create?`} component={ConfirmationPromoPage} />
      <Route path={`${props.match.url}/compliance-report/:grn`} component={ComplianceReportPage} />
      <Route path={`${props.match.url}/presale`} component={PresalePromoPage} />
      <Redirect to={`${props.match.url}/confirmation`} />
    </Switch>
  );
};
