export interface EntityQueryInput {
  grn: string;
}

export interface DualEntityQueryInput extends EntityQueryInput {
  isCompany: boolean;
}

export enum EntityType {
  Company = 'Company',
  Individual = 'Individual',
  ForeignOrganization = 'ForeignOrganization',
  Unverified = 'UnverifiedEntity',
}

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Liquidated = 'LIQUIDATED',
  Reorganization = 'REORGANIZATION',
  Liquidation = 'LIQUIDATION',
  ChangeOfLocation = 'CHANGE_OF_LOCATION',
  AuthorizedCapitalDecrease = 'AUTHORIZED_CAPITAL_DECREASE',
}

export enum FutureStatus {
  Active = 'ACTIVE',
  Liquidated = 'LIQUIDATED',
}
