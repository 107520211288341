import React from 'react';
import { TokenProvider } from '../../common/tokens/token-provider';
import { UnknownError } from '@app/common-declarations/errors';
export const TokenContext = React.createContext<TokenProvider>({
  clearToken: () => {
    throw new UnknownError('Провайдер не настроен');
  },
  getToken: () => {
    throw new UnknownError('Провайдер не настроен');
  },
  setToken: () => {
    throw new UnknownError('Провайдер не настроен');
  },
  addChangeEventListener: () => {
    throw new UnknownError('Провайдер не настроен');
  },
});
