import './global-initializers';
import './async-imports';

import * as React from 'react';
import * as ReactDom from 'react-dom';

import { Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { createBrowserHistory } from 'history';
import { RootPage } from './components/root/RootPage';
import { ErrorBoundary } from './components/root/ErrorBoundary';

import { BLACK_THEME } from '@styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, FontStyles } from '@styles/global/global-styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { RRApolloProvider } from './connectivity/components/RRApolloProvider';
import { AuthorizationTokenAuthorityProvider } from './components/providers/authorities/AuthorizationTokenAuthorityProvider';
import { TokenContext } from './connectivity/components/token-provider/Context';
import { LocalStorageTokenProvider } from './connectivity/common/tokens/LocalStorageTokenProvider';
import { isTokenOutdated } from './upgrade-adjusters/OldTokenFilter';
import { warn } from './debug';

const history = createBrowserHistory();

history.listen((location) => {
  try {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    const url = `${location.pathname}${location.search}${location.hash}`;
    window.yaCounter43403804.hit(url, { title: document.title });
  } catch (error) {
    // ничего не делаем, нам это не так важно
  }
});

const muiBreakpoints = createMuiTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 700,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const tokenProvider = new LocalStorageTokenProvider('authentication_token');

/** Проверка на старый формат токенов, которые не будут поддерживаться более */
if (isTokenOutdated(tokenProvider.getToken())) {
  tokenProvider.clearToken();
  window.location.reload();
}

/** Для того, чтобы открытая вкладка пользователя не хостила старый фронтенд слишком долго: раз в сутки перезагружаем вкладку */
setTimeout(() => window.location.reload(), 24 * 60 * 60 * 1000);

/** Проверка на таймзону: разница в мировоззрении на текущее время между сервером и клиентом, в минутах */
const timezoneDifference = Math.abs(Date.now() - (window.rr.fetchTimeUtc || Date.now())) / 1000 / 60;
const timezoneIsCorrect =
  timezoneDifference <= 30; /* Допускаем получасовой интервал (цифра от фонаря, посмотрим на поведение) */

if (!timezoneIsCorrect) {
  warn('Таймзона пользователя выглядит неправильной.');
}

ReactDom.render(
  <Router history={history}>
    <MuiThemeProvider theme={muiBreakpoints}>
      <ThemeProvider theme={BLACK_THEME}>
        <HelmetProvider>
          <>
            <GlobalStyles />
            <FontStyles />
            <Helmet titleTemplate="%s | Контрагентио" defaultTitle="Контрагентио – Только проверенные контрагенты" />

            <ErrorBoundary>
              <TokenContext.Provider value={tokenProvider}>
                <AuthorizationTokenAuthorityProvider>
                  <RRApolloProvider enableTokenPredictions={timezoneIsCorrect}>
                    <RootPage />
                  </RRApolloProvider>
                </AuthorizationTokenAuthorityProvider>
              </TokenContext.Provider>
            </ErrorBoundary>
          </>
        </HelmetProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </Router>,
  document.getElementById('root'),
);
