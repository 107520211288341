import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaymentServiceType } from '../../../../../types.generated';
import { SlimLoader } from 'kontragentio-reusable-components';
import { useAccountData } from '@app/components/providers/authentication/useAccountData';
import { UnknownError } from '@app/common-declarations/errors';
import { DefaultExternalLink } from '@components/DefaultLink';
import { validateOgrnWeak } from '@helpers/data-validation';
import { LogOnMount } from '@amplitude/react-amplitude';
import isEmpty from 'lodash/isEmpty';
import {
  useIndividualDataQuery,
  useCompanyDataQuery,
  useGetTariffsDataQuery,
  GetTariffsDataQuery,
  useCreateReportPaymentMutation,
  CreateReportPaymentMutation,
} from './complianceReport-query.generated';
import {
  Container,
  HeaderWrapper,
  HeaderBlock,
  Header,
  HeaderDescription,
  InputBlock,
  InputText,
  EmailInput,
  MainActionButton,
  ExampleWrapper,
  ExampleBlock,
  Example,
  DownloadExample,
  DescriptionWrapper,
  DescriptionBlock,
  DescriptionItem,
  DescriptionPoint,
  PointNumber,
  DescriptionTitle,
  DescriptionText,
  ActionWrapper,
  ActionBlock,
  ActionHeader,
  InputActionBlock,
  ActionBlockButton,
  ActionText,
  FaqWrapper,
  FAQBlock,
  FAQLastBlock,
  Question,
  Answer,
} from './parts';

import { getEntityName } from '@helpers/entity-helpers';

const descriptionList = [
  {
    number: 1,
    title: 'Основные сведения о компании',
    text: 'Сведения из ЕГРЮЛ, контакты, бенефициарный владелец',
  },
  {
    number: 2,
    title: 'Признаки финансово-хозяйственной деятельности',
    text:
      'В отчёте будут перечислены все признаки реальной деятельности компании за 2 года, которые мы смогли найти в 37 источниках',
  },
  {
    number: 3,
    title: 'Финансовая отчетность компании из Росстата',
    text:
      'Краткая выписка из бухгалтерской отчетности контрагента поможет вам проанализировать финансовое состояние компании',
  },
  {
    number: 4,
    title: 'Протокол проверки контрагента',
    text: 'Протокол проверки контрагента, который вы сможете предъявить при проверке ФНС в случае проверки',
  },
  {
    number: 5,
    title: 'Приложение 1. Риски работы с контрагентом',
    text: 'Все необходимые проверки контрагента по 42 факторам риска, рекомендованным ФНС для самостоятельной оценки',
  },
  {
    number: 6,
    title: 'Приложение 2. Отчет о проверке для налоговой',
    text:
      'Отчет о проверках в соответствии с Приказом ФНС России от 30.05.2007 N ММ-3-06/333@ «Об утверждении Концепции системы планирования выездных налоговых проверок»',
  },
];

const getTariff = (data?: GetTariffsDataQuery) => {
  const tariffData =
    data &&
    data.tariffsData &&
    data.tariffsData.tariffs.list.find((tariff) => tariff.code === 'CONTRACTOR_REPORT_SINGLE');
  const product = tariffData && tariffData.products.find((pr) => pr.type === 'PDO_REPORT');
  const cost = product && product.cost;

  return {
    tariff: tariffData,
    cost,
  };
};

interface ComplianceReportRouteParams {
  grn: string;
}

export const ComplianceReportPage = withRouter((props: RouteComponentProps<ComplianceReportRouteParams>) => {
  const grn = props.match.params.grn;
  const userData = useAccountData();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [replyEmail, setReplyEmail] = useState('');
  const [pending, setPending] = useState(false);

  const [createPayment] = useCreateReportPaymentMutation();

  const isCompany = !!(grn && validateOgrnWeak(grn).valid);
  const basicCompanyData = useCompanyDataQuery({
    variables: { grn: grn || '' },
    skip: !grn || !isCompany,
  });
  const basicIndividualData = useIndividualDataQuery({
    variables: { grn: grn || '' },
    skip: !!grn || isCompany,
  });

  const basicTariffData = useGetTariffsDataQuery();

  const isLoading =
    (isCompany
      ? basicCompanyData.loading || isEmpty(basicCompanyData.data)
      : basicIndividualData.loading || isEmpty(basicIndividualData.data)) ||
    basicTariffData.loading ||
    isEmpty(basicTariffData.data);

  const displayCompanyName = !isLoading
    ? getEntityName(isCompany ? basicCompanyData.data!.companyData : basicIndividualData.data!.individualData)
    : 'компании';

  const tariffData = getTariff(basicTariffData.data);
  const tariff = tariffData.tariff;
  const tariffCost = tariffData.cost;

  const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setReplyEmail(e.target.value);
  }, []);

  useEffect(() => {
    if (userData.account && userData.account.isAuthenticated && !replyEmail) {
      setReplyEmail(userData.account.email);
    }
  }, [userData.account]);

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!replyEmail || !tariff) {
        return;
      }
      if (pending) {
        return;
      }
      setPending(true);

      // Запрос в робокассу
      createPayment({
        variables: {
          email: replyEmail,
          grn,
          cart: {
            payServiceType: PaymentServiceType.ContractorReportSingle,
            tariffId: tariff.id,
            items: [
              {
                type: 'PDO_REPORT',
                count: 1,
              },
            ],
          },
        },
      }).then(
        (result) => {
          performRobokassaPostRedirectPlease(result.data!);
        },
        (queryError) => {
          setError(queryError);
        },
      );
    },
    [replyEmail, tariff],
  );

  if (error) {
    throw new UnknownError('Произошла ошибка при попытке сформировать ссылку для оплаты', error);
  }

  return (
    <Container onSubmit={submit}>
      <SlimLoader pending={pending} />
      <LogOnMount eventType="Просмотр — страница покупки отчета" />
      <HeaderWrapper>
        <HeaderBlock>
          <Header>Отчет о проверке {displayCompanyName} в сервисе Контрагентио</Header>
          <HeaderDescription>
            Получите отчет о проверке компании в полном соответствии с требованиями ФНС РФ по самостоятельной проверке
            контрагента
          </HeaderDescription>
          <InputBlock>
            <EmailInput
              type="email"
              placeholder="Укажите email для получения отчёта"
              value={replyEmail}
              onChange={onEmailChange}
              required
            />
            <MainActionButton>Получить отчёт</MainActionButton>
          </InputBlock>
          <InputText>{!isLoading && `Услуга платная. Стоимость отчёта составляет ${tariffCost} рублей.`}</InputText>
        </HeaderBlock>
      </HeaderWrapper>

      <ExampleWrapper>
        <ExampleBlock>
          <Example src={require('./assets/1.png')} />
          <Example src={require('./assets/2.png')} />
          <Example src={require('./assets/3.png')} />
        </ExampleBlock>
        <DownloadExample
          target="_blank"
          rel="noreferrer noopener"
          href="https://drive.google.com/file/d/1YRjPgKiopB5a-Btzkc7x3Zwol4PsCP94/view"
        >
          Скачать пример отчёта в формате PDF
        </DownloadExample>
      </ExampleWrapper>

      <DescriptionWrapper>
        <Header>Из чего состоит отчёт?</Header>
        <DescriptionBlock>
          {descriptionList.map((item) => (
            <DescriptionItem key={item.number}>
              <DescriptionPoint>
                <PointNumber>{item.number}</PointNumber>
              </DescriptionPoint>
              <DescriptionTitle>{item.title}</DescriptionTitle>
              <DescriptionText>{item.text}</DescriptionText>
            </DescriptionItem>
          ))}
        </DescriptionBlock>
      </DescriptionWrapper>

      <ActionWrapper>
        <ActionBlock>
          <ActionHeader>Скачайте отчёт о проверке компании прямо сейчас</ActionHeader>
          <InputActionBlock>
            <EmailInput
              type="email"
              placeholder="Укажите email для получения отчёта"
              value={replyEmail}
              onChange={onEmailChange}
              required
            />
            <ActionBlockButton>Получить отчёт</ActionBlockButton>
          </InputActionBlock>
          <ActionText>{!isLoading && `Услуга платная. Стоимость отчёта составляет ${tariffCost} рублей.`}</ActionText>
        </ActionBlock>
      </ActionWrapper>

      <FaqWrapper>
        <Header>Вопросы и ответы</Header>
        {!isLoading && (
          <FAQBlock>
            <Question>Сколько стоит отчёт?</Question>
            <Answer>{tariffCost} рублей!</Answer>
          </FAQBlock>
        )}
        <FAQBlock>
          <Question>Подойдет ли отчёт при проверке налоговой?</Question>
          <Answer>Да, подойдет.</Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Как происходит оплата отчета?</Question>
          <Answer>Мы можем принять оплату всеми популярными способами через сервис ROBOKASSA.</Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Где мне найти оплаченный отчет?</Question>
          <Answer>Ссылка на скачивание отчета придет на указанный вами при покупке e-mail.</Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Как быстро приходит отчет на почту после оплаты?</Question>
          <Answer>В течение 5 минут.</Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Какое время доступно скачивания отчета после оплаты?</Question>
          <Answer>Вы можете скачать отчет в течение суток после оплаты.</Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Что входит в состав отчета?</Question>
          <Answer>
            Отчет формируется на основании требований ФНС РФ по проверке контрагента: сведения из ЕГРЮЛ, контакты,
            бенефициарный владелец, признаки финансовой деятельности, выписка из бухгалтерской отчетности, протокол
            проверки, проверка контрагента по 42 факторам риска (критерии должностной осмотрительности), отчет о
            проверках в соответствии с Приказом ФНС России от 30.05.2007 N ММ-3-06/333@.
          </Answer>
        </FAQBlock>
        <FAQBlock>
          <Question>Можно ли посмотреть пример отчета?</Question>
          <Answer>
            Вы можете посмотреть пример формируемого отчета.{' '}
            <DefaultExternalLink href={`https://drive.google.com/file/d/1YRjPgKiopB5a-Btzkc7x3Zwol4PsCP94/view`} newTab>
              Пример отчета.
            </DefaultExternalLink>
          </Answer>
        </FAQBlock>
        <FAQLastBlock>
          <Question>Могу я приобрести полноценный доступ к сервису, а не покупать отчеты отдельно?</Question>
          <Answer>
            Да, можете. Ознакомьтесь, пожалуйста, с нашими тарифами.{' '}
            <DefaultExternalLink href={`${window.rrenv.urls.dialogWizard}/billing/plans`} newTab>
              Тарифы.
            </DefaultExternalLink>
          </Answer>
        </FAQLastBlock>
      </FaqWrapper>
    </Container>
  );
});

const performRobokassaPostRedirectPlease = (data: CreateReportPaymentMutation) => {
  const form = document.createElement('form');
  const formData = data.billing.payment.formData;
  document.body.appendChild(form);
  form.method = 'post';
  form.action = data.billing.payment.formUrl || '';
  for (const name in formData) {
    if (formData.hasOwnProperty(name)) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = formData[name];
      form.appendChild(input);
    }
  }
  form.submit();
};
