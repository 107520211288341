import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncPaymentStatusPage = Loadable<any, any>({
  loader: () =>
    import(/* webpackChunkName: "payment-status-page" */ './PaymentStatusPage').then((res) => res.PaymentStatusPage),
  loading() {
    return <SlimLoader pending />;
  },
});
