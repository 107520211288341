import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncSearchResultPage = Loadable<any, any>({
  loader: () =>
    import(/* webpackChunkName: "search-result" */ './SearchResultPage').then((res) => res.SearchResultPage),
  loading() {
    return <SlimLoader pending />;
  },
});
