import styled from '@styles/styled-helpers';
import * as React from 'react';
import { Redirect, Route, Switch, RouteComponentProps, withRouter } from 'react-router';
import { SmartAuthenticationPage } from '../sections/auth/AuthenticationPage';
import { AsyncDialogPage } from '../sections/conversations/dialog/async';
import { AsyncMessageListPage } from '../sections/conversations/mail/async';
import { AsyncAccountPage } from '../sections/account/async';
import { MainLogo, VerticalMenu, MenuItem, UserMenu, AnonymousUserIcon } from 'shared-modules';
import { UserDataProvider } from '@app/components/providers/authentication/UserDataProvider';
import { AsyncOrganizationPage } from '../sections/organizations/async';
import { AsyncReportPage } from '../sections/report/async';
import { AsyncSearchResultPage } from '../sections/search/result/async';
import { AsyncSearchEntryPage } from '../sections/search/entry/async';
import { BoundSearchBar } from './search-bar/BoundSearchBar';
import { BillingRoot } from '../sections/billing/BillingRoot';
import { useSignOffAction } from '@app/components/providers/authentication/authenticationHooks';
import { DownloadPage } from '../sections/download/DownloadPage';
import { PromoPage } from '../sections/promo/Promo';
import { AsyncAuthorityDebugPage } from '../sections/debug/authorities/async';
import { useAuthority } from '../providers/authorities/useAuthority';
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from '@amplitude/react-amplitude';
import { RRAmplitudeProvider } from '@components/amplitude-wrapper/amplitude-wrapper';
import { log } from '@app/debug';
import { useUnreadCount } from '../sections/conversations/mail/ConversationList/useUnreadCount';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: stretch;
  max-width: 100vw;
`;

const VerticalMenuContainer = styled.div`
  display: none;

  @media (min-width: ${600 / 16}em) {
    display: flex;
    flex: 0 0 auto;
  }
`;

const MainBlock = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  overflow: hidden;
`;

const ContentZone = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: stretch;
  align-content: stretch;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  background: ${(props) => props.theme.mainBrandColor};
`;

const SearchFiller = styled.div`
  flex: 1 1 auto;
`;

const AnonymousUserIconContainer = styled.div<{ noBorder?: boolean }>`
  display: block;
  background-color: #ffffff;

  ${(props) => !props.noBorder && `border-bottom: 1px solid #e8edee;`}
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
`;

interface MenuEntry {
  route?: string;
  url: string;
  component?: React.ComponentType;
  icon: string;
  hidden?: boolean;
  disabled?: boolean;
  external?: boolean;
  disableHighlight?: boolean;
  amplitudeEvent?: string;
  counter?: string;
  title?: string;
}

export const RootPage = withRouter((props: RouteComponentProps<{}>) => {
  const logout = useSignOffAction();
  const { authorities } = useAuthority();
  const unreadCount = useUnreadCount(/* pollInterval */ 5 * 60 * 1000);
  return (
    <UserDataProvider>
      {({ account, pending }) => {
        log('Account data changed', account);
        const isDialogEnabled = authorities.has('dialog');

        const entries: MenuEntry[] = [
          // Эти два дублируют друг друга из-за уникального дизайна синей страницы
          {
            icon: require('./assets/icon-search.svg'),
            route: '/search/:query',
            url: '/search',
            component: AsyncSearchResultPage,
            hidden: props.location.pathname === '/search',
            title: 'Поиск',
          },
          {
            icon: require('./assets/icon-search.svg'),
            url: '/search',
            component: AsyncSearchEntryPage,
            disableHighlight: true,
            hidden: props.location.pathname !== '/search',
            amplitudeEvent: 'Меню — поиск',
            title: 'Поиск',
          },
          {
            icon: require('./assets/icon-docs.svg'),
            route: '/mail',
            url: '/mail',
            component: AsyncMessageListPage,
            hidden: !account || !isDialogEnabled,
            disabled: account && !isDialogEnabled,
            amplitudeEvent: 'Меню — диалог',
            counter: unreadCount ? unreadCount.toString() : undefined,
            title: 'Диалоги',
          },
          {
            icon: require('./assets/icon-organizations.svg'),
            url: '/organizations',
            component: AsyncOrganizationPage,
            hidden: account && !account.isAuthenticated,
            amplitudeEvent: 'Меню — мои организации',
            title: 'Компании',
          },
          {
            icon: require('./assets/eae-logo.svg'), 
            url: 'https://eae-consult.ru',
            external: true,
            hidden: false,
            amplitudeEvent: 'ЕАЕ-Консалт',
            title: 'ЕАЕ-Консалт',
          },
          ...(process.env.NODE_ENV !== 'production'
            ? [
                {
                  icon: require('./assets/icon-debug.svg'),
                  url: '/debug/authorities',
                  component: AsyncAuthorityDebugPage,
                },
              ]
            : []),
        ];

        return (
          <AmplitudeProvider
            amplitudeInstance={amplitude.getInstance()}
            apiKey={window.rrenv.AMPLITUDE_ID}
            userId={account && account.email}
            key={(account && account.email) || 'ampl'}
          >
            <RRAmplitudeProvider>
              {({ logEvent }) => (
                <VerticalContainer>
                  <Header>
                    <MainLogo href="/" kind="default" />
                    {props.location.pathname === '/search' ? (
                      <>
                        <SearchFiller />
                        <AnonymousUserIconContainer
                          onClick={() =>
                            props.history.push({
                              pathname: account && account.isAuthenticated ? '/account' : '/auth/sign-up',
                            })
                          }
                          noBorder
                        >
                          <AnonymousUserIcon bg="#4FCEE0" fg="#f9f9f9" />
                        </AnonymousUserIconContainer>
                      </>
                    ) : (
                      <>
                        <BoundSearchBar />
                        {!pending &&
                          (account && account.isAuthenticated ? (
                            <UserMenu
                              currentUser={account}
                              authenticated={(account && account.isAuthenticated) || false}
                              actions={{
                                onLogin: () =>
                                  props.history.push({
                                    pathname: '/auth/sign-in',
                                    state: {
                                      returnTo: `${props.location.pathname}${props.location.search}${props.location.hash}`,
                                    },
                                  }),
                                onRegister: () =>
                                  props.history.push({
                                    pathname: '/auth/sign-up',
                                    state: {
                                      returnTo: `${props.location.pathname}${props.location.search}${props.location.hash}`,
                                    },
                                  }),
                                onEdit: () => props.history.push('/account'),
                                onLogout: () => {
                                  logEvent('Аутентификация — разлогинился');
                                  logout();
                                },
                              }}
                            />
                          ) : (
                            <AnonymousUserIconContainer
                              onClick={() =>
                                props.history.push({
                                  pathname: '/auth/sign-up',
                                  state: {
                                    returnTo: `${props.location.pathname}${props.location.search}${props.location.hash}`,
                                  },
                                })
                              }
                            >
                              <AnonymousUserIcon bg="transparent" />
                            </AnonymousUserIconContainer>
                          ))}
                      </>
                    )}
                  </Header>
                  <MainBlock>
                    <VerticalMenuContainer>
                      <VerticalMenu>
                        {entries
                          .filter((entry) => !entry.hidden)
                          .map((entry) => (
                            <MenuItem
                              icon={entry.icon}
                              title={entry.title}
                              to={entry.url}
                              external={entry.external || undefined}
                              key={entry.route || entry.url}
                              disableHighlight={entry.disableHighlight}
                              onClick={() => logEvent(entry.amplitudeEvent || '')}
                              counter={entry.counter}
                            />
                          ))}
                      </VerticalMenu>
                    </VerticalMenuContainer>
                    <ContentZone>
                      <Switch>
                        <Route path="/dialog/:dialogId/:status" component={AsyncDialogPage} />
                        <Route path="/account" component={AsyncAccountPage} />
                        <Route path="/auth/:mode" component={SmartAuthenticationPage} />
                        <Route path="/company/:grn/:inn?" component={AsyncReportPage} />
                        <Route path="/billing" component={BillingRoot} />
                        <Route path="/download/:documentId" component={DownloadPage} />
                        <Route path="/promo" component={PromoPage} />
                        {entries
                          .filter((entry) => !entry.disabled && !entry.external)
                          .map((entry) => (
                            <Route
                              path={entry.route || entry.url}
                              exact={!entry.route}
                              component={entry.component}
                              key={entry.route || entry.url}
                            />
                          ))}
                        <Redirect to="/search" />
                      </Switch>
                    </ContentZone>
                  </MainBlock>
                </VerticalContainer>
              )}
            </RRAmplitudeProvider>
          </AmplitudeProvider>
        );
      }}
    </UserDataProvider>
  );
});
