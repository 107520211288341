
import React from 'react';
import { ScriptLoader } from './ScriptLoader';
import styled from 'styled-components';

interface IAdsBlockProps {
  renderTo: string;
  type?: string;
  blockId: string;
  maxHeight?: string;
  platform?: string;
}


const Container = styled.div.attrs<{ $maxHeight?: string; }>(props => ({
  $maxHeight: props.$maxHeight || '30vh',
}))`
  max-height: ${props => props.$maxHeight};
`;


export const AdsBlock = (props: IAdsBlockProps) => {
  return (
    <ScriptLoader
      async = "true"
      src = "https://an.yandex.ru/system/context.js"
      onLoad={() => {
        window.yaContextCb.push(() => {
          Ya.Context.AdvManager.render({
            blockId: props.blockId,
            renderTo: props.renderTo,
            type: props.type || '',
            platform: props.platform || '',
            async: true,
          });
        });
      }}
    >
    <Container $maxHeight={props.maxHeight}  id={props.renderTo}> </Container>
    </ScriptLoader>
  );
};



