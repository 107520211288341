import * as Types from '../../../../../types.generated';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyDataQueryVariables = {
  grn: Types.Scalars['String'];
};

export type CompanyDataQuery = { readonly __typename: 'Query' } & {
  readonly companyData: { readonly __typename: 'Company' } & Pick<Types.Company, 'ogrn' | 'shortName' | 'fullName'> & {
      readonly correctedName: Types.Company['fullName'];
    } & {
      readonly form: Types.Maybe<
        { readonly __typename: 'OrganizationForm' } & Pick<Types.OrganizationForm, 'full' | 'compact'>
      >;
    };
};

export type IndividualDataQueryVariables = {
  grn: Types.Scalars['String'];
};

export type IndividualDataQuery = { readonly __typename: 'Query' } & {
  readonly individualData: { readonly __typename: 'Individual' } & Pick<
    Types.Individual,
    'ogrnip' | 'inn' | 'lastName' | 'firstName' | 'middleName'
  > & {
      readonly form: Types.Maybe<
        { readonly __typename: 'OrganizationForm' } & Pick<Types.OrganizationForm, 'full' | 'compact'>
      >;
    };
};

export type GetTariffsDataQueryVariables = {};

export type GetTariffsDataQuery = { readonly __typename: 'Query' } & {
  readonly tariffsData: { readonly __typename: 'BillingRootQueryResult' } & {
    readonly tariffs: { readonly __typename: 'BillingTariffResult' } & {
      readonly list: ReadonlyArray<
        { readonly __typename: 'BillingTariff' } & Pick<Types.BillingTariff, 'id' | 'code' | 'discount'> & {
            readonly products: ReadonlyArray<
              { readonly __typename: 'BillingTariffProduct' } & Pick<
                Types.BillingTariffProduct,
                'type' | 'productUnitName' | 'defaultValue' | 'step' | 'cost'
              >
            >;
          }
      >;
    };
  };
};

export type CreateReportPaymentMutationVariables = {
  email?: Types.Maybe<Types.Scalars['String']>;
  grn: Types.Scalars['String'];
  cart: Types.BillingCart;
};

export type CreateReportPaymentMutation = { readonly __typename: 'Mutation' } & {
  readonly billing: { readonly __typename: 'BillingOperation' } & {
    readonly payment: { readonly __typename: 'BillingPaymentCreateResult' } & Pick<
      Types.BillingPaymentCreateResult,
      'formUrl' | 'formData'
    >;
  };
};

export const CompanyDataDocument = gql`
  query companyData($grn: String!) {
    companyData: company(ogrn: $grn) {
      ogrn
      shortName
      fullName
      correctedName: fullName(autoCorrection: true)
      form {
        full
        compact
      }
    }
  }
`;
export type CompanyDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CompanyDataQuery, CompanyDataQueryVariables>,
  'query'
> &
  ({ variables: CompanyDataQueryVariables; skip?: boolean } | { skip: boolean });

export const CompanyDataComponent = (props: CompanyDataComponentProps) => (
  <ApolloReactComponents.Query<CompanyDataQuery, CompanyDataQueryVariables> query={CompanyDataDocument} {...props} />
);

export type CompanyDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<CompanyDataQuery, CompanyDataQueryVariables> &
  TChildProps;
export function withCompanyData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompanyDataQuery,
    CompanyDataQueryVariables,
    CompanyDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<TProps, CompanyDataQuery, CompanyDataQueryVariables, CompanyDataProps<TChildProps>>(
    CompanyDataDocument,
    {
      alias: 'withCompanyData',
      ...operationOptions,
    },
  );
}

export function useCompanyDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDataQuery, CompanyDataQueryVariables>,
) {
  return ApolloReactHooks.useQuery<CompanyDataQuery, CompanyDataQueryVariables>(CompanyDataDocument, baseOptions);
}
export type CompanyDataQueryHookResult = ReturnType<typeof useCompanyDataQuery>;
export type CompanyDataQueryResult = ApolloReactCommon.QueryResult<CompanyDataQuery, CompanyDataQueryVariables>;
export const IndividualDataDocument = gql`
  query individualData($grn: String!) {
    individualData: individual(ogrnip: $grn) {
      ogrnip
      inn
      lastName
      firstName
      middleName
      form {
        full
        compact
      }
    }
  }
`;
export type IndividualDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<IndividualDataQuery, IndividualDataQueryVariables>,
  'query'
> &
  ({ variables: IndividualDataQueryVariables; skip?: boolean } | { skip: boolean });

export const IndividualDataComponent = (props: IndividualDataComponentProps) => (
  <ApolloReactComponents.Query<IndividualDataQuery, IndividualDataQueryVariables>
    query={IndividualDataDocument}
    {...props}
  />
);

export type IndividualDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  IndividualDataQuery,
  IndividualDataQueryVariables
> &
  TChildProps;
export function withIndividualData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    IndividualDataQuery,
    IndividualDataQueryVariables,
    IndividualDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    IndividualDataQuery,
    IndividualDataQueryVariables,
    IndividualDataProps<TChildProps>
  >(IndividualDataDocument, {
    alias: 'withIndividualData',
    ...operationOptions,
  });
}

export function useIndividualDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IndividualDataQuery, IndividualDataQueryVariables>,
) {
  return ApolloReactHooks.useQuery<IndividualDataQuery, IndividualDataQueryVariables>(
    IndividualDataDocument,
    baseOptions,
  );
}
export type IndividualDataQueryHookResult = ReturnType<typeof useIndividualDataQuery>;
export type IndividualDataQueryResult = ApolloReactCommon.QueryResult<
  IndividualDataQuery,
  IndividualDataQueryVariables
>;
export const GetTariffsDataDocument = gql`
  query getTariffsData {
    tariffsData: billing {
      tariffs(payServiceType: CONTRACTOR_REPORT_SINGLE) {
        list {
          id
          code
          discount
          products {
            type
            productUnitName
            defaultValue
            step
            cost
          }
        }
      }
    }
  }
`;
export type GetTariffsDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetTariffsDataQuery, GetTariffsDataQueryVariables>,
  'query'
>;

export const GetTariffsDataComponent = (props: GetTariffsDataComponentProps) => (
  <ApolloReactComponents.Query<GetTariffsDataQuery, GetTariffsDataQueryVariables>
    query={GetTariffsDataDocument}
    {...props}
  />
);

export type GetTariffsDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetTariffsDataQuery,
  GetTariffsDataQueryVariables
> &
  TChildProps;
export function withGetTariffsData<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetTariffsDataQuery,
    GetTariffsDataQueryVariables,
    GetTariffsDataProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetTariffsDataQuery,
    GetTariffsDataQueryVariables,
    GetTariffsDataProps<TChildProps>
  >(GetTariffsDataDocument, {
    alias: 'withGetTariffsData',
    ...operationOptions,
  });
}

export function useGetTariffsDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTariffsDataQuery, GetTariffsDataQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetTariffsDataQuery, GetTariffsDataQueryVariables>(
    GetTariffsDataDocument,
    baseOptions,
  );
}
export type GetTariffsDataQueryHookResult = ReturnType<typeof useGetTariffsDataQuery>;
export type GetTariffsDataQueryResult = ApolloReactCommon.QueryResult<
  GetTariffsDataQuery,
  GetTariffsDataQueryVariables
>;
export const CreateReportPaymentDocument = gql`
  mutation createReportPayment($email: String, $grn: String!, $cart: BillingCart!) {
    billing {
      payment: createReportPayment(email: $email, reportGrn: $grn, cart: $cart) {
        formUrl
        formData
      }
    }
  }
`;
export type CreateReportPaymentMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportPaymentMutation,
  CreateReportPaymentMutationVariables
>;
export type CreateReportPaymentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateReportPaymentMutation, CreateReportPaymentMutationVariables>,
  'mutation'
>;

export const CreateReportPaymentComponent = (props: CreateReportPaymentComponentProps) => (
  <ApolloReactComponents.Mutation<CreateReportPaymentMutation, CreateReportPaymentMutationVariables>
    mutation={CreateReportPaymentDocument}
    {...props}
  />
);

export type CreateReportPaymentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateReportPaymentMutation,
  CreateReportPaymentMutationVariables
> &
  TChildProps;
export function withCreateReportPayment<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateReportPaymentMutation,
    CreateReportPaymentMutationVariables,
    CreateReportPaymentProps<TChildProps>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateReportPaymentMutation,
    CreateReportPaymentMutationVariables,
    CreateReportPaymentProps<TChildProps>
  >(CreateReportPaymentDocument, {
    alias: 'withCreateReportPayment',
    ...operationOptions,
  });
}

export function useCreateReportPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReportPaymentMutation, CreateReportPaymentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateReportPaymentMutation, CreateReportPaymentMutationVariables>(
    CreateReportPaymentDocument,
    baseOptions,
  );
}
export type CreateReportPaymentMutationHookResult = ReturnType<typeof useCreateReportPaymentMutation>;
export type CreateReportPaymentMutationResult = ApolloReactCommon.MutationResult<CreateReportPaymentMutation>;
export type CreateReportPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportPaymentMutation,
  CreateReportPaymentMutationVariables
>;
