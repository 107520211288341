/* tslint:disable:max-classes-per-file */

type EType = 'EACCESS' | 'ENOTFOUND' | 'GENERIC';

interface IRRError {
  readonly message: string;
  readonly rrEType: EType;
  readonly error: Error;
}

export class GenericError implements IRRError {
  public readonly message: string;
  public readonly rrEType: EType;
  public readonly error: Error;

  constructor(message: string, etype: EType, error?: Error) {
    this.error = error || new Error(message);
    this.message = message;
    this.rrEType = etype;
  }
}

export class NotFoundError extends GenericError {
  constructor(message: string) {
    super(message, 'ENOTFOUND');
  }
}

export class AccessError extends GenericError {
  constructor(message: string) {
    super(message, 'EACCESS');
  }
}

export class UnknownError extends GenericError {
  constructor(message: string, error?: Error) {
    super(message, 'GENERIC', error);
  }
}

export const isRRError = (obj: object): obj is GenericError => {
  if (obj.hasOwnProperty('rrEType')) {
    return true;
  }
  return false;
};
