import React from 'react';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import { ACCOUNT_INFORMATION_QUERY, IAccountInformationResponse, AccountStatus } from './queries/me';
import { PackageStatus, ISubmitter } from '@app/common-declarations/dialog';
import { EntityInfo } from '@app/common-declarations/entities';
import { ApolloError } from 'apollo-client';
import { Authority } from '../authorities/Authority';

interface IUserDataProviderProps {
  data?: IAccountInformationResponse;
  loading: boolean;
  error?: ApolloError;
}

export interface IAccountDetails {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  position?: string;
  brandName?: string;
  organizations: IUserOrganizationInfo[];
  status: AccountStatus;
  isMaster: boolean;
  name?: string;
  creationDate: number;
}

export interface IUserOrganizationInfo {
  date: number;
  entity: EntityInfo;
}

export interface IVerification {
  id: string;
  status: PackageStatus;
  target: ISubmitter;
}

export type UserAccountDetails = Readonly<
  IAccountDetails & {
    colleagues: IAccountDetails[];
    verifications: IVerification[];
    isAuthenticated: boolean;
    /** @depredated */
    roles: string[];
    authorities: Authority[];
  }
>;

export interface IUserDataProviderRenderProps {
  pending: boolean;
  error?: boolean;
  account?: UserAccountDetails;
}

export const mapProps = (userData: IUserDataProviderProps): IUserDataProviderRenderProps => {
  const returningResult: IUserDataProviderRenderProps = {
    pending: userData.loading,
    error: !!userData.error,
  };

  const getName = (fname: string, lname: string) => [fname, lname].filter((part) => !!part).join(' ');
  const result = userData.data;

  if (result && result.account) {
    returningResult.account = {
      id: result.account.current.info.id,
      roles: result.account.current.roles.map((role) => role.name),
      authorities: result.account.current.authorities.map((authority) => authority.name),
      firstName: result.account.current.info.firstName,
      lastName: result.account.current.info.lastName,
      brandName: result.account.current.info.brandName,
      name: getName(result.account.current.info.firstName, result.account.current.info.lastName),
      email: result.account.current.info.email,
      position: result.account.current.info.position,
      organizations: result.account.current.info.organizationData.organizations,
      isAuthenticated: !!result.account.current.info.email,
      isMaster: result.account.current.info.isMaster,
      status: result.account.current.info.status,
      creationDate: result.account.current.info.creationDate,
      colleagues: result.account.current.colleagues.map((col) => ({
        id: col.id,
        email: col.email,
        firstName: col.firstName,
        lastName: col.lastName,
        name: getName(col.firstName, col.lastName),
        position: col.position,
        brandName: col.brandName,
        organizations: col.organizationData.organizations,
        status: col.status,
        isMaster: col.isMaster,
        creationDate: col.creationDate,
      })),
      verifications: result.account.current.confirmations.map((item) => ({
        id: item.id,
        target: item.submitter,
        status: item.status,
      })),
    };
  }

  return returningResult;
};

export const UserDataProvider = adopt<IUserDataProviderRenderProps, {}>(
  {
    userData: ({ render }) => (
      <Query query={ACCOUNT_INFORMATION_QUERY}>{(result: IUserDataProviderProps) => render!(result)}</Query>
    ),
  },
  ({ userData }) => mapProps(userData),
);

export { AccountStatus };
