import gql from 'graphql-tag';

export interface IPasswordResetResponse {
  password: {
    sent: boolean;
  };
}

export interface IPasswordResetVariables {
  email: string;
}

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!) {
    password: authentication {
      sent: resetPassword(email: $email)
    }
  }
`;
