import * as React from 'react';
import { Tooltip } from 'kontragentio-reusable-components';
import { FieldArea } from '@components/inputs/FieldArea';
import { DefaultInput } from '@components/inputs/DefaultInput';
import { HintMark } from 'kontragentio-reusable-components';
import styled from '@styles/styled-helpers';

interface IFormFragmentProps {
  label: string;
  validationError?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  hint?: string;
}

interface IFormFragmentState {
  pristine: boolean;
}

const RelativeContainer = styled.div`
  position: relative;
`;

const HintMarkPlaceholder = styled.div`
  position: absolute;
  top: 0.8em;
  right: 1em;
`;

export type FormFragmentPropType = React.HTMLProps<HTMLInputElement> & IFormFragmentProps;

export class FormFragment extends React.Component<FormFragmentPropType, IFormFragmentState> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: FormFragmentPropType) {
    super(props);
    this.inputRef = this.props.inputRef || React.createRef<HTMLInputElement>();
  }

  public state = {
    pristine: true,
  };

  public componentDidUpdate(newProps: FormFragmentPropType) {
    if (this.props.value !== newProps.value) {
      this.inputRef.current!.checkValidity();
      this.forceUpdate();
    }
  }

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ pristine: false });

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  public render() {
    const validationMessage =
      (!this.state.pristine && this.inputRef.current && this.inputRef.current.validationMessage) ||
      this.props.validationError;

    return (
      <FieldArea required={this.props.required} label={this.props.label}>
        <RelativeContainer>
          <Tooltip
            kind="validation"
            placement="right"
            visible={!!validationMessage && !this.state.pristine}
            text={validationMessage}
          >
            <DefaultInput
              {...this.props}
              as={undefined}
              hideNativeValidation={this.state.pristine}
              onChange={this.handleChange}
              ref={this.inputRef as any /* styled-components-bug */}
              invalid={!!validationMessage}
            />
          </Tooltip>
          {this.props.hint && (
            <HintMarkPlaceholder>
              <HintMark text={this.props.hint} enabled={!this.props.disabled} />
            </HintMarkPlaceholder>
          )}
        </RelativeContainer>
      </FieldArea>
    );
  }
}
