import React, { useContext } from 'react';
import styled from '@styles/styled-helpers';
import { RiskData, OccurrenceData } from '../queries/compliance';
import { SeverityType } from '../../compliance/helpers';

interface RiskContext {
  risks: ReadonlyArray<RiskData>;
}

const context = React.createContext<RiskContext>({ risks: [] });
const { Provider } = context;

interface RiskNotificationProps {
  risks?: ReadonlyArray<string>;
  risk?: string;
  mode: 'single' | 'every';
  filter: (occurrence: OccurrenceData, risk: RiskData, allRisks: ReadonlyArray<RiskData>) => boolean;
  description: (occurrence: OccurrenceData, risk: RiskData, allRisks: ReadonlyArray<RiskData>) => React.ReactNode;
  block?: boolean;
  fallback?: React.ReactNode;
}

const Container = styled.span<{ block?: boolean }>`
  display: ${(props) => (props.block ? 'block' : 'inline')};
`;

export const RiskNotification = (props: RiskNotificationProps) => {
  const { risks } = useContext(context);

  const result = (props.risks || (props.risk && [props.risk]) || []).map((testedRisk) => {
    const foundRisk = risks.find((risk) => risk.risk === testedRisk);
    const occurrence = foundRisk && foundRisk.occurrences.find((occ) => props.filter(occ, foundRisk, risks));

    if (!foundRisk || !occurrence) {
      return props.fallback ? <>{props.fallback}</> : undefined;
    }

    return (
      <Container key={testedRisk} block={props.block}>
        <RiskMarker severity={occurrence.severity} />
        {props.description(occurrence, foundRisk, risks)}
      </Container>
    );
  });

  if (props.mode === 'every') {
    return <>{result.filter((el) => !!el)}</>;
  }

  return result.find((el) => !!el) || <></>;
};

RiskNotification.defaultProps = {
  filter: () => true,
  description: (_: OccurrenceData, risk: RiskData) => risk.description || risk.title,
  mode: 'every',
};

interface RiskMarkerProps {
  severity: SeverityType;
  className?: string;
}

function colorCode(severity: SeverityType) {
  switch (severity) {
    case SeverityType.Low:
      return '#FFE69C';
    case SeverityType.Medium:
      return '#FFE69C';
    case SeverityType.Notice:
      return '#607D8B';
    case SeverityType.Severe:
      return '#F48FB1';
    default:
      return 'transparent';
  }
}

const MarkerSvg = styled.svg`
  width: 0.7em;
  height: 0.7em;
  margin-right: 0.5em;
`;

export const RiskMarker = (props: RiskMarkerProps) => {
  const color = colorCode(props.severity);

  return (
    <MarkerSvg viewBox="0 0 1 1" className={props.className}>
      <rect x1="0" y1="0" width="1" height="1" fill={color} />
    </MarkerSvg>
  );
};

export const RiskProvider = Provider;
