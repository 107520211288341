import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { DefaultInternalLink, DefaultExternalLink } from '@components/DefaultLink';
import styled from '@styles/styled-helpers';
import { Helmet } from 'react-helmet-async';

const Layout = styled.section`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const Icon = styled.div`
  display: block;
  width: 5em;
  height: 5em;
  margin-bottom: 1em;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${require('./assets/ic_download.svg')});
`;
const Image = styled.div`
  display: block;
  width: 25em;
  height: 21.6875em;

  background-image: url(${require('./assets/im_doc_large.svg')});
`;
const Header = styled.span`
  display: block;
  margin-bottom: 0.5em;

  font-weight: 500;
  line-height: 1.5;
`;
const Description = styled.span`
  display: block;
  margin-bottom: 2em;

  font-size: 0.875em;
  line-height: ${1.5 / 0.875};
`;
const Link = styled(DefaultInternalLink)`
  display: block;
  height: 2.5em;
  width: 10em;
  margin: 1em auto;
  line-height: 2.5em;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #4fcee0;
  color: #4fcee0;
  text-transform: uppercase;
  text-align: center;

  cursor: pointer;

  &:hover {
    background-color: #4fcee0;
    color: #fff;
  }
`;

type RouteComponentPropsWithState<S> = RouteComponentProps<S> & {
  location: {
    state?: {
      returnTo?: string;
    };
  };
};

interface DownloadPageProps {
  documentId: string;
}

export const DownloadPage = (props: RouteComponentPropsWithState<DownloadPageProps>) => {
  const serverUrl = window.rrenv.urls.restApi;
  const { documentId } = props.match.params;
  const returnTo: string | undefined = props.location.state?.returnTo;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${serverUrl}/documents/${documentId}`;
    }, 1000);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Скачать файл</title>
      </Helmet>
      <Icon />
      <Header>Ваш документ готов</Header>
      <Description>
        Если скачивание файла не началось автоматически,
        <br /> нажмите на&nbsp;
        <DefaultExternalLink href={`${serverUrl}/documents/${documentId}`}>эту ссылку</DefaultExternalLink>
        <Link to={returnTo || '/'}>{returnTo ? 'Перейти к API' : 'На главную'}</Link>
      </Description>
      <Image />
    </Layout>
  );
};
