import React from 'react';
import { Authority } from './Authority';

export interface AuthorityContextType {
  authorities: ReadonlySet<Authority>;
  pending: boolean;
}

export const AuthorityContext = React.createContext<AuthorityContextType>({
  authorities: new Set(),
  pending: false,
});
