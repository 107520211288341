import gql from 'graphql-tag';

export interface ConfirmationMutationResponse {
  created: {
    package: string;
  };
}

export interface ConfirmationMutationInput {
  grn: string;
}

export const confirmationMutation = gql`
  mutation confirm($grn: String!) {
    created: conversation {
      package: createConversation(from: { grn: $grn }, type: VERIFICATION)
    }
  }
`;
