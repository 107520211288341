import gql from 'graphql-tag';
import { ISubmitter, PackageStatus } from '@app/common-declarations/dialog';
import { EntityInfo } from '@app/common-declarations/entities';
import { Authority } from '../../authorities/Authority';

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

interface IAccountDetails {
  id: string;
  email: string;
  status: AccountStatus;
  isMaster: boolean;
  position: string;
  firstName: string;
  lastName: string;
  brandName: string;
  creationDate: number;
  organizationData: {
    organizations: Array<{
      entity: EntityInfo;
      date: number;
    }>;
  };
}

export interface IAccountInformationResponse {
  account: {
    current: {
      roles: Array<{
        name: string;
      }>;
      authorities: Array<{
        name: Authority;
      }>;
      info: IAccountDetails & {
        slaves: IAccountDetails[];
      };
      colleagues: IAccountDetails[];
      confirmations: Array<{
        id: string;
        submitter: ISubmitter;
        status: PackageStatus;
      }>;
    };
  };
}

export const ACCOUNT_INFORMATION_QUERY = gql`
  fragment person on Person {
    firstName
    lastName
    middleName
    inn
  }

  fragment userCompany on Company {
    ogrn
    inn
    kpp
    terminationDate
    shortName
    fullName
    correctedName: shortName(autoCorrection: true)
    management {
      position
      code
      head {
        ...person
      }
    }
    form {
      compact
      full
    }
  }

  fragment userIndividual on Individual {
    ogrnip
    inn
    firstName
    lastName
    middleName
    form {
      compact
      full
    }
  }

  fragment AccountInfo on AccountInformation {
    id
    email
    firstName
    lastName
    position
    status
    brandName
    isMaster
    creationDate
    organizationData {
      organizations {
        entity {
          __typename
          ...userIndividual
          ...userCompany
        }
        date
      }
    }
  }

  query getAccountDetails {
    account {
      current: me {
        roles {
          name
        }
        authorities {
          name
        }
        confirmations {
          id
          submitter {
            organization {
              ...userIndividual
              ...userCompany
            }
          }
          status
        }
        info {
          ...AccountInfo
          slaves {
            ...AccountInfo
          }
        }
        colleagues {
          ...AccountInfo
        }
      }
    }
  }
`;
