import { warn } from '@app/debug';

interface CompanyLike {
  __typename?: 'Company';
  ogrn?: string | null;
  correctedName?: string | null;
  shortName?: string | null;
  fullName?: string | null;
  form?: {
    compact?: string | null;
    full?: string | null;
  } | null;
}

interface IndividualLike {
  __typename?: 'Individual';
  ogrnip?: string | null;
  form?: {
    compact?: string | null;
    full?: string | null;
  } | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  inn?: string | null;
}

type EntityLike = IndividualLike | CompanyLike;

function isCompanyLike(entity: EntityLike): entity is CompanyLike {
  return ['ogrn', 'fullName', 'correctedName'].some((prop) => entity.hasOwnProperty(prop));
}

function isIndividualLike(entity: EntityLike): entity is IndividualLike {
  return ['ogrnip', 'lastName', 'firstName', 'middleName'].some((prop) => entity.hasOwnProperty(prop));
}

export function getEntityName(entity: EntityLike): string {
  if (isCompanyLike(entity)) {
    if (entity.correctedName) {
      return `${(entity.form && entity.form.compact) || ''} ${entity.correctedName}`;
    } else {
      return entity.shortName || entity.fullName || 'Наименование не указано';
    }
  }

  if (isIndividualLike(entity)) {
    return [(entity.form && entity.form.compact) || '', entity.lastName, entity.firstName, entity.middleName].join(' ');
  }

  warn('Неизвестная сущность', entity);

  return '';
}

interface IGetEntityGrnOptions {
  includeTitle?: boolean;
}

export function getEntityGrn(entity: EntityLike, options: IGetEntityGrnOptions = {}): string | undefined {
  const includeTitle = options.includeTitle;

  if (isCompanyLike(entity)) {
    return includeTitle ? `ОГРН ${entity.ogrn}` : entity.ogrn || undefined;
  }

  if (isIndividualLike(entity)) {
    return includeTitle ? `ОГРНИП ${entity.ogrnip}` : entity.ogrnip || undefined;
  }

  throw new Error('Тип сущности не определён.');
}

interface PersonLike {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  inn?: string | null;
}

interface EmployeeLike {
  position: string;
}

interface PersonWithFullName {
  fullName: string;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  inn?: string | null;
}

export type ManagementHead = CompanyLike | PersonLike;

export interface IManagementEntry<T extends ManagementHead = ManagementHead> {
  position: string;
  code: string;
  head: T;
}

type ManagedEntity =
  | (CompanyLike & {
      management: ReadonlyArray<IManagementEntry>;
    })
  | IndividualLike;

function isPerson(entity: ManagementHead): entity is PersonLike {
  return ['lastName', 'firstName', 'middleName'].some((prop) => entity.hasOwnProperty(prop));
}

export function getEntityExecutivePersons(entity: ManagedEntity): Array<PersonWithFullName & EmployeeLike> {
  if (isCompanyLike(entity)) {
    return entity.management
      .filter((man) => man.code === '02' || man.code === '08')
      .filter((man): man is IManagementEntry<PersonLike> => isPerson(man.head))
      .map((managementEntity) => ({
        ...(managementEntity.head as PersonLike),
        position: managementEntity.position,
        fullName: getPersonName(managementEntity.head),
      }));
  }

  if (isIndividualLike(entity)) {
    return [
      {
        firstName: entity.firstName,
        lastName: entity.lastName,
        middleName: entity.middleName,
        inn: entity.inn,
        position: (entity.form && (entity.form.full || entity.form.compact)) || '',
        fullName: getPersonName(entity),
      },
    ];
  }

  throw new Error('Тип сущности не определён.');
}

export function getEntityExecutivePerson(entity: ManagedEntity): (PersonWithFullName & EmployeeLike) | undefined {
  return getEntityExecutivePersons(entity)[0];
}

export function getPersonName(entity: PersonLike): string {
  return [entity.lastName, entity.firstName, entity.middleName].filter((part) => !!part).join(' ');
}

export function getPersonNameInitials(entity: PersonLike): string {
  return [
    entity.lastName,
    entity.firstName && `${entity.firstName[0].toUpperCase()}.`,
    entity.middleName && `${entity.middleName[0].toUpperCase()}`,
  ]
    .filter((part) => !!part)
    .join(' ');
}

export function getPersonShortInversedName(entity: PersonLike): string {
  return [entity.firstName, entity.lastName].filter((part) => !!part).join(' ');
}
