import React from 'react';
import styled from '@styles/styled-helpers';
import { PrimaryButton } from '@components/button/button';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 28.07em;
  margin-top: -4em;
  text-align: center;
`;

const PageTitle = styled.h1`
  display: block;
`;

const PageDescription = styled.p`
  display: block;
  margin-bottom: 2em;
  text-align: center;
  line-height: 1.714;
  font-size: 0.875em;
`;

interface IGenericErrorPageProps {
  onResolve?: () => void;
}

export class InternalGenericErrorPage extends React.Component<RouteComponentProps & IGenericErrorPageProps> {
  public render() {
    return (
      <PageContainer>
        <ContentWrapper>
          <PageTitle>Произошла ошибка</PageTitle>
          <PageDescription>
            Мы сожалеем, но во время выполнения произошла непредвиденная ошибка. Наши специалисты уже занимаются
            исправлением неполадок.
          </PageDescription>
          <PrimaryButton onClick={() => window.location.reload()}>Перезагрузить страницу</PrimaryButton>
        </ContentWrapper>
      </PageContainer>
    );
  }
}

export const GenericErrorPage = withRouter(InternalGenericErrorPage);
