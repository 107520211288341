import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PageLayout, Description, Header, PageContent, GridBox, ButtonArea, Paragraph, Footer, Loader } from './parts';
import { FormFragment } from '@components/inputs/FormFragment';
import { PrimaryButton, LinkButton } from '@components/button/button';
import { DefaultExternalLink } from '@components/DefaultLink';
import { AuthenticationEmailHelper } from './AuthenticationEmailHelper';
import { InPlaceProgressIndicator } from 'shared-modules';
import {
  useSignInAction,
  useSignUpAction,
  useSignOffAction,
  useResetPasswordAction,
} from '@app/components/providers/authentication/authenticationHooks';
import { carrotQuest } from '@components/carrotquest-wrapper/carrotquest-wrapper';
import { RRAmplitudeProvider, LogEventFunction } from '@components/amplitude-wrapper/amplitude-wrapper';
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude';
import { Helmet } from 'react-helmet-async';
import { AdsFloor } from '@components/ad/AdsFloor';

export interface IAuthenticationPageRouteState {
  returnTo?: string;
  target?: string;
}

type Mode = 'sign-in' | 'sign-up';

interface IAuthenticationPageRouteParams {
  mode: Mode;
}

interface IAuthenticationPageProps {
  login: (email: string, password: string) => Promise<any>;
  resetPassword: (email: string) => Promise<any>;
  logout: () => Promise<any>;
  register: (
    email: string,
    password: string,
    details: { firstName: string; lastName: string; notifyUser?: boolean },
  ) => Promise<any>;
  logEvent: LogEventFunction;
}

interface IAuthenticationPageState {
  mode: Mode;
  modificationCounter: number;

  fields: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };

  validation: {
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    overall?: string;
  };

  pending: boolean;
  passwordSent: boolean;
}

// Проверка полей имени на фронтенде
const isNameValid = (value: string) => {
  return /^[А-Яа-яЁё\\-\\s]+$/.test(value);
};

class AuthenticationPage extends React.Component<
  IAuthenticationPageProps & RouteComponentProps<IAuthenticationPageRouteParams, {}, IAuthenticationPageRouteState>,
  IAuthenticationPageState
> {
  private form: React.RefObject<HTMLFormElement> = React.createRef();

  public state = {
    mode: this.props.match.params.mode || 'sign-up',
    modificationCounter: 0,
    fields: {
      firstName: '',
      lastName: '',
      email: (this.props.location.state && this.props.location.state.target) || '',
      password: '',
    },
    validation: {
      email: undefined,
      password: undefined,
      firstName: undefined,
      lastName: undefined,
    },
    pending: false,
    passwordSent: false,
  };

  public componentDidUpdate(prevProps: RouteComponentProps<IAuthenticationPageRouteParams>) {
    if (prevProps.match.params.mode !== this.props.match.params.mode) {
      this.setState({
        mode: this.props.match.params.mode,
      });
    }
  }

  private validateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState((state) => ({
      ...state,
      validation: {
        ...state.validation,
        [name]: !isNameValid(value) && 'Некорректное значение поля',
      },
    }));
  };

  private handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    this.setState(
      (state) => ({
        fields: {
          ...state.fields,
          [name]: value,
        },
        validation: {
          ...state.validation,
          [name]: '',
        },
        modificationCounter: state.modificationCounter + 1,
      }),
      () => {
        setTimeout(() => {
          this.setState((state) => ({
            modificationCounter: state.modificationCounter - 1,
          }));
        }, 300);
      },
    );
  };

  private isEverythingValid = () => {
    const isFormValid = this.form.current && this.form.current.checkValidity && this.form.current.checkValidity();
    const credentialsAreFilled = !!this.state.fields.email && !!this.state.fields.password;
    const allFieldsAreFilled = Object.values(this.state.fields).every((v) => !!v);

    const isLoginFieldsValid = !this.state.validation.email && !this.state.validation.password;
    const allFieldsAreValid = Object.values(this.state.validation).every((v) => !v);

    return (
      isFormValid &&
      ((this.state.mode === 'sign-up' && allFieldsAreFilled && allFieldsAreValid) ||
        (this.state.mode === 'sign-in' && credentialsAreFilled && isLoginFieldsValid))
    );
  };

  private handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.pending || !this.isEverythingValid()) {
      return;
    }

    this.setState({ pending: true });

    try {
      this.state.mode === 'sign-in'
        ? await this.props.login(this.state.fields.email, this.state.fields.password)
        : await this.props.register(this.state.fields.email, this.state.fields.password, {
            firstName: this.state.fields.firstName,
            lastName: this.state.fields.lastName,
            notifyUser: true,
          });
      this.props.logEvent(
        `Аутентификация — успешная ${this.state.mode === 'sign-in' ? 'авторизация' : 'регистрация'}`,
        { email: this.state.fields.email },
      );
      
      if (!window.location.pathname.includes('auth/')) {
        window.location.reload();
      }
     
      this.props.history.replace(this.props.location.state?.returnTo ?? '/');


      // carrotquest track
      if (this.state.mode === 'sign-in') {
        carrotQuest.track('$authorized', { $email: this.state.fields.email });
      } else {
        carrotQuest.track('$registered', { $email: this.state.fields.email, $name: this.state.fields.firstName });
      }
    } catch (error) {
      this.props.logEvent(`Аутентификация — ошибка ${this.state.mode === 'sign-in' ? 'авторизации' : 'регистрации'}`, {
        error,
      });
      this.setState((state) => ({
        validation: {
          ...state.validation,
          password: 'Неверно указан пароль.',
        },
      }));
    } finally {
      this.setState({ pending: false });
    }
  };

  private resetPassword = async () => {
    if (this.state.pending) {
      return;
    }

    if (!this.state.fields.email) {
      this.setState((state) => ({
        validation: {
          ...state.validation,
          email: 'Необходимо указать электронную почту',
        },
      }));
      return;
    }

    this.setState({ pending: true, passwordSent: false });

    try {
      await this.props.resetPassword(this.state.fields.email);

      this.setState({
        passwordSent: true,
      });
    } catch (error) {
      this.setState((state) => ({
        validation: {
          ...state.validation,
          email: 'Указанная почта не зарегистрирована в системе',
        },
      }));
    } finally {
      this.setState({ pending: false });
    }
  };

  private toggleMode = () => {
    this.setState((state) => ({
      mode: state.mode === 'sign-in' ? 'sign-up' : 'sign-in',
    }));
  };

  public render() {
    return (
      <PageLayout>
        <Helmet>
          <title>Авторизация</title>
        </Helmet>
        <Amplitude
          eventProperties={{
            email: this.state.fields.email,
          }}
        >
          <LogOnMount eventType="Аутентификация — просмотр страницы" />
          <PageContent onSubmit={this.handleSubmit} ref={this.form}>
            <Header>{this.state.mode === 'sign-in' ? 'Вход' : 'Регистрация'}</Header>
            <RRAmplitudeProvider>
              {({ logEvent }) => (
                <Description>
                  {this.state.mode === 'sign-in' ? (
                    <>
                      Войдите в свою учётную запись, чтобы получить доступ ко всем возможностям сервиса. Ещё нет учётной
                      записи?{' '}
                      <DefaultExternalLink
                        data-cy="auth-toggle"
                        onClick={() => {
                          logEvent('Аутентификация — переключение типа попапа', { type: this.state.mode });
                          this.toggleMode();
                        }}
                      >
                        Зарегистрироваться
                      </DefaultExternalLink>
                      .
                    </>
                  ) : (
                    <>
                      Создайте учётную запись и получите доступ ко всем возможностям сервиса Контрагентио. Это
                      бесплатно. Уже есть учётная запись?{' '}
                      <DefaultExternalLink
                        data-cy="auth-toggle"
                        onClick={() => {
                          logEvent('Аутентификация — переключение типа попапа', { type: this.state.mode });
                          this.toggleMode();
                        }}
                      >
                        Войти
                      </DefaultExternalLink>
                      .
                    </>
                  )}
                </Description>
              )}
            </RRAmplitudeProvider>

            {this.state.mode === 'sign-up' && (
              <GridBox>
                <RRAmplitudeProvider>
                  {({ logEvent }) => (
                    <FormFragment
                      autoFocus={this.state.mode === 'sign-up'}
                      label="Имя"
                      required
                      name="firstName"
                      autoComplete="given-name"
                      onChange={this.handleFieldChange}
                      value={this.state.fields.firstName}
                      validationError={this.state.validation.firstName}
                      onBlur={(e) => {
                        this.validateField(e);
                        logEvent('Аутентификация — введено имя', { firstName: this.state.fields.firstName });
                      }}
                    />
                  )}
                </RRAmplitudeProvider>
                <RRAmplitudeProvider>
                  {({ logEvent }) => (
                    <FormFragment
                      label="Фамилия"
                      required
                      name="lastName"
                      autoComplete="family-name"
                      onChange={this.handleFieldChange}
                      value={this.state.fields.lastName}
                      validationError={this.state.validation.lastName}
                      onBlur={(e) => {
                        this.validateField(e);
                        logEvent('Аутентификация — введена фамилия', { lastName: this.state.fields.lastName });
                      }}
                    />
                  )}
                </RRAmplitudeProvider>
              </GridBox>
            )}

            <AuthenticationEmailHelper
              email={this.state.fields.email}
              mode={this.state.mode}
              check={!!this.state.fields.email && this.state.modificationCounter <= 0 && !this.state.pending}
            >
              {({ message }) => {
                return (
                  <RRAmplitudeProvider>
                    {({ logEvent }) => (
                      <FormFragment
                        autoFocus={this.state.mode === 'sign-in'}
                        label="Рабочий email-адрес"
                        required
                        autoComplete="email"
                        name="email"
                        type="email"
                        onChange={this.handleFieldChange}
                        value={this.state.fields.email}
                        validationError={this.state.validation.email || message}
                        onBlur={() => logEvent('Аутентификация — введен email', { email: this.state.fields.email })}
                      />
                    )}
                  </RRAmplitudeProvider>
                );
              }}
            </AuthenticationEmailHelper>

            <FormFragment
              label="Пароль"
              required
              type="password"
              name="password"
              onChange={this.handleFieldChange}
              value={this.state.fields.password}
              validationError={this.state.validation.password}
            />

            <ButtonArea>
              <PrimaryButton data-cy="auth-submit" type="submit" disabled={this.state.pending}>
                {this.state.mode === 'sign-in' ? 'Вход' : 'Создать учетную запись'}
              </PrimaryButton>
              {this.state.mode === 'sign-up' && (
                <Paragraph>
                  Нажимая на эту кнопку, вы принимаете условия
                  {/* tslint:disable-next-line */}
                  <DefaultExternalLink href="/legal/offer"> оферты</DefaultExternalLink>.
                </Paragraph>
              )}
              {this.state.mode === 'sign-in' && (
                <Paragraph>
                  Забыли пароль? Вы можете <LinkButton onClick={this.resetPassword}>сбросить его</LinkButton>, и мы
                  отправим новый пароль на вашу почту.
                </Paragraph>
              )}
            </ButtonArea>

            {!this.state.passwordSent && <Loader shown={this.state.pending} />}

            {this.state.passwordSent && (
              <>
                <br />
                <InPlaceProgressIndicator
                  status="success"
                  text={`Новый пароль отправлен на адрес ${this.state.fields.email}`}
                />
              </>
            )}

            <Footer>
              © {new Date().getFullYear()} ООО «ЕАЕ», 123308, г. Москва, ул. Демьяна Бедного, д. 24, к. 1<br />
              {/* tslint:disable-next-line */}
              <DefaultExternalLink href="/legal/policy">
                {' '}
                {/* tslint:disable-line */}
                Политика обработки персональных данных
              </DefaultExternalLink>
            </Footer>
          </PageContent>
          <AdsFloor blockId={'R-A-4796302-24'} renderTo={'yandex_rtb_R-A-4796302-24'}/>  
        </Amplitude>
      </PageLayout>
    );
  }
}

const InternalRoutedComponent = withRouter(AuthenticationPage);

export const SmartAuthenticationPage = () => {
  
  const login = useSignInAction();
  const register = useSignUpAction();
  const logout = useSignOffAction();
  const resetPassword = useResetPasswordAction();
  return (
    <RRAmplitudeProvider>
      {({ logEvent }) => (
        <InternalRoutedComponent
          login={login}
          logout={logout}
          register={register}
          resetPassword={resetPassword}
          logEvent={logEvent}
        />
      )}
    </RRAmplitudeProvider>
  );
};
