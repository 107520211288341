import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncDialogPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "dialog" */ './DialogPage').then((res) => res.DialogPage),
  loading() {
    return <SlimLoader pending />;
  },
  delay: 300,
});
