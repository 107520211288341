import gql from 'graphql-tag';
import { OrganizationStatus, FutureStatus } from '../../../../common/types';
import { basicCompanyInfoFragment, BasicCompanyInfo } from '../../../../common/queries/common';

export interface EgrulDataResponse {
  basic: {
    __typename: 'Company';
    shortName: string;
    fullName: string;
    correctedName: string;
    registration: {
      grnDate: number;
    };
    status: {
      grnDate: number;
      text: string;
      currentStatus: OrganizationStatus;
      futureStatus: FutureStatus;
    };
    ogrn: string;
    inn?: string;
    kpp?: string;
    address: {
      full?: string;
    };
    okveds: ReadonlyArray<{
      isPrimary: boolean;
      code: string;
      description: string;
      grnDate: number;
    }>;
    authorizedCapital: {
      grnDate: number;
      amount: number;
    };
    form: {
      code: string;
      compact: string;
      full: string;
    };
    predecessors: ReadonlyArray<BasicCompanyInfo>;
    successors: ReadonlyArray<BasicCompanyInfo>;
  };
}

export const egrulQuery = gql`
  ${basicCompanyInfoFragment}

  query egrul($grn: String!) {
    basic: company(ogrn: $grn) {
      shortName
      fullName
      correctedName: fullName(autoCorrection: true)
      registration {
        grnDate
      }
      status {
        grnDate
        text
        currentStatus
        futureStatus
      }
      ogrn
      inn
      kpp
      address {
        full
      }
      okveds {
        isPrimary
        code
        description
        grnDate
      }
      authorizedCapital {
        grnDate
        amount
      }
      form {
        code
        compact
        full
      }
      successors {
        ...basicCompanyInfo
      }
      predecessors {
        ...basicCompanyInfo
      }
    }
  }
`;
