import React, { useState, useEffect, PropsWithChildren, useCallback } from 'react';
import ApolloClient from 'apollo-client';
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/react-components';
import { errorLink } from '../client/error-handling';
import { rrNetworkLink, retrieveAnonymousToken } from '../client/network';
import { from } from 'apollo-link';
import { inMemoryCache } from '../client/cache';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTokenProvider } from './token-provider/useTokenProvider';
import { analyzeToken } from './token-analyzer';
import { log } from '@app/debug';
import { getAuthoritiesQuery, GetAuthoritiesResponse, RenewTokenResponse, renewTokenMutation } from './queries';
import { useAuthority } from '@app/components/providers/authorities/useAuthority';
import isEqual from 'lodash/isEqual';

const TOKEN_RENEW_PERIOD = 60 * 60; // В секундах
const MAX_TOKEN_RENEW_PERIOD = 24 * 60 * 60; // В секундах
const AUTHORITY_CHECK_INTERVAL_SECONDS = 5 * 60; // В секундах

interface RRApolloProviderProps {
  enableTokenPredictions: boolean;
}

export const RRApolloProvider = withRouter((props: PropsWithChildren<RouteComponentProps & RRApolloProviderProps>) => {
  const tokenProvider = useTokenProvider();
  const { authorities } = useAuthority();
  const [storeBeingReset, setStoreBeingReset] = useState(false);

  const apiUrl = `${window.rrenv.urls.api}/v3/graphql`;

  const onForbidden = async () => {
    tokenProvider.setToken(await retrieveAnonymousToken(apiUrl));
  };

  const [client] = useState(
    new ApolloClient({
      link: from([
        errorLink({ onForbidden }),
        rrNetworkLink({
          apiUrl,
          tokenProvider,
          enableTokenPredictions: props.enableTokenPredictions,
        }),
      ]),
      cache: inMemoryCache,
    }),
  );

  const renewToken = useCallback(() => {
    client
      .mutate<RenewTokenResponse>({ mutation: renewTokenMutation })
      .then((result) => {
        if (result.data) {
          tokenProvider.setToken(result.data.authentication.newToken);
        }
      });
  }, [client]);

  // Этот эффект чистит все стораджи при смене токена
  // TODO: Добавить логику, чтобы чистка происходила только при смене пользователя или его авторитисов
  useEffect(() => {
    const dispose = tokenProvider.addChangeEventListener(() => {
      log('Токен обновился, обновляем состояние');
      client.resetStore().then(() => setStoreBeingReset(false));
      setStoreBeingReset(true);
    });
    return () => {
      dispose();
    };
  }, [tokenProvider]);

  // Этот эффект устанавливает срок проверки длительности жизни токена, когда близится его протухание
  // и старается обновлять до наступления этого момента
  useEffect(() => {
    if (props.enableTokenPredictions) {
      let previousTimeoutId: NodeJS.Timeout;

      const onTokenChange = (newToken: string | undefined) => {
        const result = analyzeToken(newToken);
        const timeout = Math.min(MAX_TOKEN_RENEW_PERIOD, Math.max(10, result.ttl - TOKEN_RENEW_PERIOD));

        if (previousTimeoutId) {
          clearTimeout(previousTimeoutId);
        }

        if (result.ttl > 0 && result.type === 'AUTOMATIC') {
          previousTimeoutId = setTimeout(renewToken, timeout * 1e3);
          log(`Token monitoring is set up to ${timeout} sec.`);
        }
      };

      const dispose = tokenProvider.addChangeEventListener(onTokenChange);
      onTokenChange(tokenProvider.getToken());

      return () => {
        dispose();
        if (previousTimeoutId) {
          clearTimeout(previousTimeoutId);
        }
      };
    }
  }, [renewToken, tokenProvider, props.enableTokenPredictions]);

  // Этот эффект периодически проверяет, не изменились ли права пользователя, и обновляет токен, если они изменились
  useEffect(() => {
    if (props.enableTokenPredictions) {
      const intervalId = setInterval(async () => {
        log('Checking authorities');
        const currentToken = analyzeToken(tokenProvider.getToken());
        if (currentToken.type === 'AUTOMATIC' && !currentToken.anonymous) {
          const newAuthorityData = await client.query<GetAuthoritiesResponse>({
            query: getAuthoritiesQuery,
            fetchPolicy: 'no-cache',
          });
          const newAuthorities =
            newAuthorityData.data && new Set(newAuthorityData.data.account.me.authorities.map((auth) => auth.name));

          if (!isEqual(newAuthorities, authorities)) {
            renewToken();
            log('Authorities are different, renewing token %O %O', newAuthorities, authorities);
          } else {
            log('Authorities are the same, no need to renew token earlier %O %O', newAuthorities, authorities);
          }
        } else {
          log('Token is special, no need to update it');
        }
      }, AUTHORITY_CHECK_INTERVAL_SECONDS * 1e3);
      return () => clearInterval(intervalId);
    }
  }, [renewToken, tokenProvider, client, authorities, props.enableTokenPredictions]);

  return (
    <ApolloProvider client={client}>
      <ApolloHookProvider client={client}>{!storeBeingReset && props.children}</ApolloHookProvider>
    </ApolloProvider>
  );
});
