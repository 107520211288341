import styled from '@styles/styled-helpers';

const ONE_COLUMN_BREAKPOINT = '600px';

export const Header = styled.h1`
  color: ${(props) => props.theme.defaultTextColor};
  font-size: 1.25em;
  line-height: 1.2;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.defaultTextColor};
  font-size: 0.875em;
  line-height: 1.714;
`;

export const Description = styled(Paragraph)`
  margin-top: 1em;
`;

export const GridBox = styled.div`
  display: grid;

  & > * {
    width: 100%;
  }

  @media (min-width: ${ONE_COLUMN_BREAKPOINT}) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }
`;

export const PageLayout = styled.div`
  width: 100%;
  display: flex;
  padding: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageContent = styled.form`
  width: 100%;
  max-width: 500px;
`;

export const ButtonArea = styled.div`
  margin-top: 3em;
  display: grid;
  min-height: 3em;
  grid-row-gap: 1em;

  & > * {
    width: 100%;
  }

  @media (min-width: ${ONE_COLUMN_BREAKPOINT}) {
    grid-template-columns: 40% 60%;
    grid-column-gap: 1em;
  }
`;

interface ILoaderProps {
  shown?: boolean;
}

export const Loader = styled.img.attrs({
  src: require('./assets/loader.svg'),
})<ILoaderProps>`
  max-height: 2em;
  margin: 1em auto 1em auto;
  display: ${(props) => (props.shown ? 'block' : 'none')};

  @media (min-width: ${ONE_COLUMN_BREAKPOINT}) {
    display: block;
    margin-top: 1.35em;
    max-height: initial;
    opacity: ${(props) => (props.shown ? 1 : 0)};
  }
`;

export const Footer = styled.div`
  margin-top: 1em;
  color: ${(props) => props.theme.disabledTextColor};
  font-size: 0.875em;
  line-height: 1.714;

  @media (min-width: ${ONE_COLUMN_BREAKPOINT}) {
    margin-top: 7em;
  }
`;
