import React from 'react';
import { notifyDevelopers, error as logError } from '@app/debug';
import { RouteComponentProps, withRouter } from 'react-router';
import { isRRError } from '@app/common-declarations/errors';
import { GenericErrorPage } from '../sections/errors/GenericError';
import { PermissionDeniedPage } from '../sections/errors/PermissionDenied';
import { NotFoundPage } from '../sections/errors/NotFound';

const reportErrors = process.env.NODE_ENV === 'production';

const report = (error: Error) => {
  try {
    if (reportErrors) {
      notifyDevelopers(`На странице произошла ошибка: ${error.message}`, {
        type: 'ERROR',
        details: error.stack,
        metadata: [
          { key: 'message', value: error.message },
          { key: 'name', value: error.name },
        ],
      });
    }
  } catch (wtf) {
    logError('Ошибка при отправке ошибки %O', wtf);
  }
};

interface IErrorBoundaryState {
  error?: Error;
}

export class InternalErrorBoundary extends React.Component<RouteComponentProps> {
  public state: IErrorBoundaryState = {};

  private genericErrorHandler = (evt: ErrorEvent) => {
    try {
      if (reportErrors) {
        notifyDevelopers(`На странице произошла ошибка: ${evt.error.message} at ${evt.error.filename}`, {
          type: 'ERROR',
          details: evt.error.stack || (evt.error.error && evt.error.error.message),
          metadata: [
            { key: 'message', value: evt.error.message },
            { key: 'filename', value: evt.error.filename },
            { key: 'name', value: evt.error.name },
            { key: 'inner_error', value: evt.error.error || undefined },
          ],
        });
      }
    } catch (wtf) {
      logError('Ошибка при отправке ошибки %O', wtf);
    }
  };

  public componentDidMount() {
    window.addEventListener('error', this.genericErrorHandler);
  }

  public componentWillUnmount() {
    window.removeEventListener('error', this.genericErrorHandler);
  }

  public componentWillUpdate(prevProps: RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ error: undefined });
    }
  }

  public static getDerivedStateFromError(error: Error) {
    report(error);
    return {
      error,
    };
  }

  private resetError = () => {
    this.setState({ error: undefined });
  };

  public render() {
    if (!this.state.error) {
      return this.props.children;
    }

    if (isRRError(this.state.error)) {
      switch (this.state.error.rrEType) {
        case 'EACCESS':
          return <PermissionDeniedPage onResolve={this.resetError} />;
        case 'ENOTFOUND':
          return <NotFoundPage onResolve={this.resetError} />;
        default:
          return <GenericErrorPage onResolve={this.resetError} />;
      }
    } else {
      return <GenericErrorPage onResolve={this.resetError} />;
    }
  }
}

export const ErrorBoundary = withRouter(InternalErrorBoundary);
