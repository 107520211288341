import { createGlobalStyle } from '@styles/styled-helpers';

/* Базовые стили для страницы */
export const GlobalStyles = createGlobalStyle`
    *, *::before, *::after  {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    *::selection {
        background: ${(props) => props.theme.mainBrandColor};
        color: #fff;
    }

    body {
        position: relative;
        overflow: auto;
        font-size: 16px;
        font-family: MuseoSansCyrl, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        color: ${(props) => props.theme.defaultTextColor};
        background-color: #fff;
    }

    // Поскольку ссылки в проекте без нижнего подчеркивания
    a {
        text-decoration: none;
    }

    textarea:focus, input:focus, button:focus, select:focus {
        outline: none;
        box-shadow: none;
    }

    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
`;

/* Шрифты */
export const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 100;
        src: url(/assets/fonts/MuseoSansCyrl-100.otf) format('opentype');
    }

    @font-face {
        font-family: MuseoSansCyrl;
        font-style: normal;
        font-weight: 300;
        src: url(/assets/fonts/MuseoSansCyrl-300.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 500;
        src: url(/assets/fonts/MuseoSansCyrl-500.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 700;
        src: url(/assets/fonts/MuseoSansCyrl-700.otf) format('opentype');
    }

    @font-face {
        font-family: 'MuseoSansCyrl';
        font-style: normal;
        font-weight: 900;
        src: url(/assets/fonts/MuseoSansCyrl-900.otf) format('otf');
    }
`;
