import React from 'react';
import { Amplitude } from '@amplitude/react-amplitude';

export type LogEventFunction = (
  eventType: string,
  eventProperties?: Record<string, any>,
  callback?: () => void,
) => void;

type InstrumentFunction = (eventType: string, func: () => any) => void;

interface AmplitudeInterface {
  logEvent: LogEventFunction;
  instrument: InstrumentFunction;
}

interface AmplitudeProviderProps {
  children: (pr: AmplitudeInterface) => React.ReactNode;
}

export const RRAmplitudeProvider = (props: AmplitudeProviderProps) => {
  return (
    <Amplitude>{({ logEvent, instrument }: AmplitudeInterface) => props.children({ logEvent, instrument })}</Amplitude>
  );
};
