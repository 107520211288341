import { TokenChangeHandler } from './token-provider';
import { log } from '@app/debug';

const dispatchFakeEvent = () => window.dispatchEvent(new Event('storage'));

export class LocalStorageTokenProvider {
  private lsKey: string;
  private handlers: Set<TokenChangeHandler>;
  private storeHandler: () => void;

  constructor(lsKey: string) {
    this.lsKey = lsKey;
    this.handlers = new Set<TokenChangeHandler>();

    let previousToken = this.getToken();
    this.storeHandler = () => {
      const newToken = this.getToken();

      if (newToken === previousToken) {
        return;
      }

      log('Storage changed, updating authorities');

      previousToken = newToken;
      for (const handler of this.handlers) {
        handler(localStorage.getItem(this.lsKey) || undefined);
      }
    };

    log('Subscribing to storage change');
    window.addEventListener('storage', this.storeHandler);
  }

  public getToken() {
    return localStorage.getItem(this.lsKey) || undefined;
  }

  public setToken(value: string | undefined) {
    if (value) {
      localStorage.setItem(this.lsKey, value);
    } else {
      localStorage.removeItem(this.lsKey);
    }
    dispatchFakeEvent();
  }

  public clearToken() {
    localStorage.removeItem(this.lsKey);
    dispatchFakeEvent();
  }

  public addChangeEventListener(handler: TokenChangeHandler) {
    this.handlers.add(handler);
    return () => {
      this.handlers.delete(handler);
    };
  }

  public unsubscribe() {
    log('Unsubscribing from storage change');
    window.removeEventListener('storage', this.storeHandler);
  }
}
