import gql from 'graphql-tag';

export interface GetAuthoritiesResponse {
  account: {
    me: {
      authorities: ReadonlyArray<{
        name: string;
      }>;
    };
  };
}

export const getAuthoritiesQuery = gql`
  query getAuthorities {
    account {
      me {
        authorities {
          name
        }
      }
    }
  }
`;

export interface RenewTokenResponse {
  authentication: {
    newToken: string;
  };
}

export const renewTokenMutation = gql`
  mutation renewToken {
    authentication {
      newToken: renewToken
    }
  }
`;
