import { HttpLink } from 'apollo-link-http';
import { TokenProvider } from '../common/tokens/token-provider';
import jwt from 'jsonwebtoken';
import { trace } from '@app/debug';
// ponyfill, чтобы всегда юзался XHR
import fetch from 'unfetch';

type FetchOptions = RequestInit;
interface NetworkLinkOptions {
  tokenProvider: TokenProvider;
  apiUrl: string;
  enableTokenPredictions?: boolean;
}

export const analyzeToken = (token: string | undefined) => {
  try {
    const decoded = jwt.decode(token!) as Record<string, any>;
    return {
      ttl: decoded.exp - Date.now() / 1000,
      anonymous: !decoded.email,
    };
  } catch {
    return {
      ttl: -1,
      anonymous: true,
    };
  }
};

export const retrieveAnonymousToken = (apiUrl: string) =>
  fetch(`${apiUrl}?query=anonymousLogin`, {
    method: 'POST',
    body: JSON.stringify({
      query: `mutation anonymousLogin {
        authentication {
          anonymousLogin
        }
      }`,
    }),
  })
    .then((res) => res.json())
    .then((res) => res.data.authentication.anonymousLogin as string);

const customFetch = (linkOptions: NetworkLinkOptions) => {
  let retrievingAnonymousToken: Promise<string> | undefined;
  const debouncedRetrieveAnonymousToken = () => {
    if (retrievingAnonymousToken) {
      return retrievingAnonymousToken;
    } else {
      retrievingAnonymousToken = retrieveAnonymousToken(linkOptions.apiUrl);
      retrievingAnonymousToken.finally(() => (retrievingAnonymousToken = undefined));
      return retrievingAnonymousToken;
    }
  };

  return async (uri: string, options: FetchOptions) => {
    const { operationName } = JSON.parse(((options || {}).body || '').toString());

    let token = linkOptions.tokenProvider.getToken();

    if (linkOptions.enableTokenPredictions) {
      const { ttl, anonymous } = analyzeToken(token);

      if (ttl < 0 && !['login', 'register', 'anonymousLogin'].includes(operationName)) {
        if (!anonymous) {
          // Разлогиниваем пользователя и перезагружаем страницу как простейший способ сбросить кеш
          linkOptions.tokenProvider.clearToken();
          window.location.reload();
          return Promise.reject(new Error('Неанонимный токен доступа отсутствует или устарел: ' + token));
        } else {
          // Для анонимного пользователя нет большого смысла сбрасывать кеш
          // потому что у анонимуса не может быть ничего важного, что нужно скрыть
          // Поэтому мы не будем перезагружать страницу
          token = await debouncedRetrieveAnonymousToken();
          linkOptions.tokenProvider.setToken(token);
        }
      }
    }

    trace('Sending query', uri, options);

    return await fetch(`${uri}?query=${operationName}`, {
      ...options,
      headers: token
        ? {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          }
        : options.headers,
    }).finally(() => {
      trace('Query finished', uri, options);
    });
  };
};

export const rrNetworkLink = (linkOptions: NetworkLinkOptions) => {
  return new HttpLink({
    fetch: customFetch(linkOptions),
    uri: linkOptions.apiUrl,
  });
};
