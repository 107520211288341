import gql from 'graphql-tag';

export interface IRegistrationResponse {
  authentication: {
    token: string;
  };
}

export interface IRegistrationRequestVariables {
  email: string;
  password: string;
  details: {
    firstName: string;
    lastName: string;
  };
}

export const REGISTRATION_MUTATION = gql`
  mutation register($email: String!, $password: String!, $details: AccountRegistrationDetails!) {
    authentication {
      token: register(email: $email, password: $password, details: $details)
    }
  }
`;
