import React from 'react';
import { CHECK_EMAIL_QUERY, IEmailCheckingResponse } from './queries/check-email';
import { Query } from '@apollo/react-components';
import { isQueryResultRetrieved } from '@helpers/typeguards';
import { QueryResult } from '@apollo/react-common';

interface IAuthenticationEmailHelperProps {
  email: string;
  mode: 'sign-in' | 'sign-up';
  check: boolean;
  children: (validity: { valid: boolean; message: string }) => React.ReactElement;
}

export class AuthenticationEmailHelper extends React.Component<IAuthenticationEmailHelperProps> {
  public render() {
    return (
      <Query
        query={CHECK_EMAIL_QUERY}
        skip={!this.props.email || !this.props.check}
        fetchPolicy="network-only"
        variables={{
          email: this.props.email,
          mode: this.props.mode,
        }}
        displayName="EmailCheckQuery"
        notifyOnNetworkStatusChange
      >
        {(result: QueryResult<IEmailCheckingResponse>) => {
          let errorMessage = '';

          if (!result.loading) {
            if (isQueryResultRetrieved(result.data)) {
              const data = result.data;
              switch (data && data.email && data.email.status) {
                case 'AVAILABLE':
                  if (this.props.mode === 'sign-in') {
                    errorMessage = 'Данный e-mail не зарегистрирован в системе.';
                  }
                  break;
                case 'OCCUPIED':
                  if (this.props.mode === 'sign-up') {
                    errorMessage = 'Данный e-mail уже зарегистрирован в системе.';
                  }
                  break;
                case 'FORBIDDEN':
                  errorMessage = 'Данный e-mail запрещено использовать.';
                  break;
              }
            }
          }

          return this.props.children({
            valid: !!errorMessage,
            message: errorMessage,
          });
        }}
      </Query>
    );
  }
}
