import React from 'react';
import { formatLongDate, formatAsDistance } from '@helpers/formatters';
import { DefaultExternalLink } from '@components/DefaultLink';
import { TitledBlock } from './PromoTitledBlock';
import { VerificationMark } from '@components/marks/verification-mark/VerificationMark';

import styled from '@styles/styled-helpers';

const CompanyName = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.3666667;
  padding: 0.3em 0;
`;

const CustomVerificationMark = styled(VerificationMark)`
  vertical-align: sub;
`;

const BankAccount = styled.div`
  & + & {
    margin-top: 1em;
  }
`;
const Row = styled.div``;
const BankName = styled.span`
  margin-right: 1em;
`;
const Account = styled.span`
  font-weight: 700;
  margin-right: 0.5em;
`;

const ItemLoader = styled.div`
  height: 1.5em;
  width: 12em;
  background-color: #e6edf1;
`;

const NameLoader = styled(ItemLoader)`
  height: 2.5em;
  width: 15em;
  padding: 0.3em 0;
`;

export const HardcodeEgrulData = () => {
  return (
    <>
      <CompanyName>
        <CustomVerificationMark /> ООО "ВЕКТОР ПЛЮС ХОЛДИНГ"
      </CompanyName>
      <TitledBlock
        title="Полное наименование"
        content={'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ВЕКТОР ПЛЮС ХОЛДИНГ"'}
      />
      <TitledBlock title="Статус" content="Действующее предприятие по данным ЕГРЮЛ" />
      <TitledBlock
        title="Дата регистрации"
        content={`${formatLongDate(1084838400)} (${formatAsDistance(1084838400)})`}
      />
      <TitledBlock title="ОГРН" content="111245000000" />
      <TitledBlock title="ИНН / КПП" content="7440000000 / 402901001" />
      <TitledBlock
        title="Юридический адрес"
        content="357107, Ставропольский край, г. Невинномысск, ул. Низяева, д. 1"
      />
    </>
  );
};

export const HardcodeDialogData = () => {
  return (
    <>
      <TitledBlock
        title="Фактический адрес"
        content="357246, Ставропольский край, р-н Минераловодский, с. Марьины колодцы, ул. Ленина, д. 22"
      />
      <TitledBlock
        title="Почтовый адрес"
        content="357100, Ставропольский край, г. Невинномысск, ул. Гагарина, д. 62, оф. 88"
      />

      <TitledBlock title="Телефон" content="+7 (495) 118-24-35" />
      <TitledBlock title="Электронная почта">
        <DefaultExternalLink href="javascript:void(0)">info@example.com</DefaultExternalLink>
      </TitledBlock>
      <TitledBlock title="Сайт">
        <DefaultExternalLink href="javascript:void(0)">https://example.com</DefaultExternalLink>
      </TitledBlock>

      <TitledBlock
        title="Описание компании"
        content="Наша компания находится на рынке более 15 лет и по праву считается лидером в своей области. Вас приятно удивят европейское качество, гибкая система скидок и, конечно же, наши цены."
      />
      <TitledBlock title="Банковские счета">
        <BankAccount>
          <Account>40702810200000000000</Account>
          <BankName>в ПАО СБЕРБАНК</BankName>
          <Row>БИК 044525225, к/c 30101810400000000225</Row>
        </BankAccount>
      </TitledBlock>
    </>
  );
};

export const LoaderEgrulData = () => {
  return (
    <>
      <NameLoader />
      <TitledBlock title="Полное наименование">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
      <TitledBlock title="Статус">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
      <TitledBlock title="Дата регистрации">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
      <TitledBlock title="ОГРН">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
      <TitledBlock title="ИНН / КПП">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
      <TitledBlock title="Юридический адрес">
        {' '}
        <ItemLoader />{' '}
      </TitledBlock>
    </>
  );
};
