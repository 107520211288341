import { onError } from 'apollo-link-error';
import { error, notifyDevelopers, warn, LogType } from '@app/debug';
import { GraphQLError } from 'graphql';
import { ServerError } from 'apollo-link-http-common';

export interface ReasonedError extends GraphQLError {
  reason?: string;
}
interface ErrorLinkOptions {
  onForbidden?: (error: ReasonedError) => void;
}

const reportErrors = process.env.NODE_ENV === 'production';

const getSeverity = (err: ReasonedError): LogType => {
  if (err.reason === 'NOT_FOUND' || err.reason === 'FORBIDDEN') {
    return 'WARNING';
  }
  return 'ERROR';
};

export const errorLink = (options: ErrorLinkOptions) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      // Мы добавляем кастомное поле reason, поэтому пока сюда
      const forbiddenError = graphQLErrors.find((currentError: ReasonedError) => currentError.reason === 'FORBIDDEN');
      if (forbiddenError) {
        warn('Forbidden error occurred');
        if (options.onForbidden) {
          options.onForbidden(forbiddenError);
        }
      }

      graphQLErrors.map((err) => {
        const { message, locations, path, reason } = err as ReasonedError;
        error(`[GraphQL error]: Message: %s, Location: %O, Path: %s`, message, locations, path);

        if (reportErrors) {
          notifyDevelopers('Произошла ошибка при выполнении запроса GraphQL', {
            type: getSeverity(err as ReasonedError),
            details: err.message,
            metadata: [
              { key: 'message', value: message },
              { key: 'locations', value: locations },
              { key: 'path', value: path },
              { key: 'operation', value: operation },
              { key: 'reason', value: reason },
            ],
            index: [
              { key: 'graphql_error_message', value: message },
              { key: 'graphql_error_locations', value: locations },
              { key: 'graphql_error_path', value: path },
              { key: 'graphql_operation_name', value: operation.operationName },
              { key: 'graphql_error_reason', value: reason },
            ],
          });
        }
      });
    }

    if (networkError) {
      error(`[Network error]: %O`, networkError);
      if (reportErrors) {
        notifyDevelopers('Произошла сетевая ошибка при выполнении запроса GraphQL', {
          type: 'ERROR',
          details: networkError.toString(),
          metadata: [
            { key: 'error_message', value: networkError.message },
            { key: 'graphql_variables', value: operation.operationName },
            { key: 'graphql_variables', value: JSON.stringify(operation.variables) },
          ],
          index: [
            { key: 'network_error_message', value: networkError.message },
            { key: 'network_error_stack', value: networkError.stack },
            { key: 'network_error_code', value: (networkError as ServerError).statusCode },
            { key: 'graphql_operation_name', value: operation.operationName },
            { key: 'graphql_variables', value: operation.operationName },
            { key: 'graphql_variables', value: JSON.stringify(operation.variables) },
          ],
        });
      }
    }
  });
