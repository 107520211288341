import React from 'react';
import styled from '@styles/styled-helpers';
import { PrimaryButton } from '@components/button/button';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  max-width: 28.07em;
  margin-top: -4em;
  text-align: center;
`;

const PageTitle = styled.h1`
  display: block;
`;

const PageDescription = styled.p`
  display: block;
  margin-bottom: 2em;
  text-align: center;
  line-height: 1.714;
  font-size: 0.875em;
`;

interface IPermissionDeniedErrorPageProps {
  onResolve?: () => void;
}

export class PermissionDeniedPage extends React.Component<IPermissionDeniedErrorPageProps> {
  public render() {
    return (
      <PageContainer>
        <ContentWrapper>
          <PageTitle>Недостаточно прав</PageTitle>
          <PageDescription>
            Запрашиваемая вами страница недоступна. Возможно, вам дали неправильную ссылку. Попробуйте войти от имени
            другого пользователя, или свяжитесь с нами.
          </PageDescription>
          <Link to="/" onClick={this.props.onResolve}>
            <PrimaryButton>Перейти на главную</PrimaryButton>
          </Link>
        </ContentWrapper>
      </PageContainer>
    );
  }
}
