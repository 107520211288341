import styled from '@styles/styled-helpers';

export const Container = styled.form`
  flex: 1 1 auto;
  background-color: #f6f8f8;
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 2.5em 1em 2em 1em;
  background-color: #f6f8f8;
`;

const ActionButton = styled.button`
  height: ${3 / 0.875}em;
  width: ${12 / 0.875}em;
  border: none;
  line-height: ${3 / 0.875};
  border-radius: 4px;
  font-size: 0.875em;

  cursor: pointer;
`;

export const HeaderWrapper = styled(Wrapper)``;

export const HeaderBlock = styled.div`
  width: 100%;
  max-width: 50em;
  margin: auto;
`;

export const Header = styled.h2`
  padding: 0 1em;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
`;
export const HeaderDescription = styled.span`
  display: block;
  padding: 0 1em;
  margin: 1.25em auto;
  width: 100%;
  max-width: 48em;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
`;

export const InputBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
`;
export const EmailInput = styled.input`
  height: ${3 / 0.875}em;
  width: 100%;
  max-width: ${22.5 / 0.875}em;
  padding: 0.857em 0.714em;
  line-height: ${3 / 0.875};
  background-color: #ffffff;
  border: 1px solid #e8edee;
  border-radius: 4px;

  font-size: 0.875em;
`;
export const MainActionButton = styled(ActionButton)`
  margin-left: 0.5em;
  background-color: #8bc34a;
  color: #fff;
`;

export const ExampleWrapper = styled(Wrapper)``;

export const ExampleBlock = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  width: 100%;
  max-width: 75em;
  margin: auto;
`;
export const Example = styled.img`
  display: block;
  width: 16em;
  height: 22.85em;
  margin: 0 1em 1em 1em;
  border: 1px solid #eee;

  @media (min-width: 25em) {
    width: 21em;
    height: 30em;
  }
`;
export const DownloadExample = styled.a`
  display: block;
  margin: ${2 / 0.875}em auto 0 auto;
  padding: 0 1em 0 2.75em;
  line-height: ${3 / 0.875};
  height: ${3 / 0.875}em;
  max-width: ${20 / 0.875}em;
  background: 1em center url(${require('./assets/download.svg')}) no-repeat #fff;

  color: #47bccd;
  font-size: 0.875em;
  font-weight: 500;

  border: 1px solid #e8edee;
  border-radius: 4px;
`;

export const DescriptionWrapper = styled(Wrapper)`
  background-color: #fff;
`;
export const DescriptionBlock = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  width: 100%;
  max-width: 77em;
  margin: auto;
`;
export const DescriptionItem = styled.div`
  max-width: 22.5em;
  margin: 2em 1.5em 0 1.5em;
`;

export const DescriptionPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: auto;
  background-color: #4fcee0;
  border-radius: 50%;
`;
export const PointNumber = styled.span`
  color: #fff;
  font-size: 1.75em;
  font-weight: 500;
`;
export const DescriptionTitle = styled.div`
  margin: 1.5em 1em 1em 1em;
  font-size: 1.375em;
  line-height: ${1.5 / 1.375}em;
  text-align: center;
  font-weight: 500;
`;
export const DescriptionText = styled.div`
  line-height: 1.5em;
  text-align: center;
  font-weight: 300;
`;

export const ActionWrapper = styled(Wrapper)`
  background-color: #fff;
`;

export const ActionBlock = styled.div`
  width: 100%;
  max-width: 75em;
  padding: 1.5em;
  margin: auto;
  background: url(${require('./assets/background.svg')}) no-repeat right center;
  background-size: cover;
`;
export const ActionHeader = styled(Header)`
  color: #fff;
`;
export const InputActionBlock = styled(InputBlock)`
  margin: 1.2em auto;
`;
export const ActionBlockButton = styled(ActionButton)`
  margin-left: 0.5em;
  background-color: #ffed8a;
`;
export const InputText = styled.span`
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
`;
export const ActionText = styled(InputText)`
  color: #ffffff;
`;

export const FaqWrapper = styled(Wrapper)``;

export const FAQBlock = styled.div`
  width: 100%;
  max-width: 75em;
  margin: auto;
  padding-bottom: 1em;
`;

export const FAQLastBlock = styled(FAQBlock)`
  padding-bottom: 3em;
`;

export const Question = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1.75em;
  line-height: 1.43;
`;
export const Answer = styled.span`
  display: block;
  padding: 0.5em 0;
  line-height: 1.5;
`;
