import styled, { css } from '@styles/styled-helpers';
import * as React from 'react';

/* Экшены для FieldArea */

interface IFieldAccompanyingActionProps {
  children: React.ReactNode;
}

export const FieldAccompanyingAction = ({ children }: IFieldAccompanyingActionProps) => {
  return <>{children}</>;
};

/* Непосредственно FieldArea */

const FieldContainer = styled.div`
  width: 100%;
  margin-top: 1em;
`;

const FieldHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FieldHeaderContent = styled.span`
  display: block;
  flex: 0 1 auto;

  font-size: 0.875em;
  line-height: 1.4;
  font-weight: 300;
  padding: 0.3em 0;
`;

// tslint:disable-next-line
interface IFieldTitleProps {
  required?: boolean;
}

const FieldTitle = styled(FieldHeaderContent)<IFieldTitleProps>`
  color: ${({ theme }) => theme.disabledTextColor};

  ${({ required, theme }) =>
    required &&
    css`
      &::after {
        color: ${theme.alarmaColor};
        content: '*';
        padding-left: 0.5em;
        display: inline-block;
      }
    `};
`;

const FieldAction = styled(FieldHeaderContent)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
`;

const FieldContent = styled.div`
  font-size: 0.875em;
`;

interface IFieldAreaProps {
  label: string;
  children: React.ReactNode;
  required?: boolean;
}

const isAction = (child: React.ReactNode) =>
  !(child instanceof String) && (child as React.ReactElement<any>).type === FieldAccompanyingAction;

export const FieldArea = ({ children, label, required }: IFieldAreaProps) => {
  const allChildren = React.Children.toArray(children);
  const renderableChildren = allChildren.filter((child) => !isAction(child));
  const action = allChildren.find((child) => isAction(child));

  return (
    <FieldContainer>
      <FieldHeader>
        <FieldTitle required={required}>{label}</FieldTitle>
        <FieldAction>{action}</FieldAction>
      </FieldHeader>
      <FieldContent>{renderableChildren}</FieldContent>
    </FieldContainer>
  );
};
