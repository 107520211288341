import moment from 'moment';
import '@app/locales/moment-ru';

export function parseDate(dateString: string, format: string = 'DD.MM.YYYY') {
  return moment(dateString, format).toDate();
}

/* tslint:disable */

function checkDigit(inn: string, coefficients: number[]) {
  let n = 0;
  for (const i in coefficients) {
    n += coefficients[i] * +inn[i];
  }
  return (n % 11) % 10;
}

export function validateInn(inn: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!inn.length) {
    result = {
      ...result,
      error: 'Заполните это поле',
    };
  } else if (/[^0-9]/.test(inn)) {
    result = {
      ...result,
      error: 'ИНН может состоять только из цифр',
    };
  } else if (+inn === 0) {
    result = {
      ...result,
      error: 'Укажите верный ИНН',
    };
  } else if ([12].indexOf(inn.length) === -1) {
    result = {
      ...result,
      error: 'ИНН должен содержать 12 цифр',
    };
  } else {
    switch (inn.length) {
      case 10:
        const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = {
            ...result,
            valid: true,
          };
        }
        break;
      case 12:
        const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = {
            ...result,
            valid: true,
          };
        }
        break;
      default:
        break;
    }
    if (!result.valid) {
      result = {
        ...result,
        error: 'Проверьте правильность ввода',
      };
    }
  }
  return result;
}

export function validateCompanyInn(inn: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!inn.length) {
    result = {
      ...result,
      error: 'ИНН пуст',
    };
  } else if (/[^0-9]/.test(inn)) {
    result = {
      ...result,
      error: 'ИНН может состоять только из цифр',
    };
  } else if (+inn === 0) {
    result = {
      ...result,
      error: `${inn} - не валидный ИНН`,
    };
  } else if (inn.length !== 10) {
    result = {
      ...result,
      error: 'ИНН компании может состоять только из 10 цифр',
    };
  } else {
    const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
    if (n10 === parseInt(inn[9])) {
      result = {
        ...result,
        valid: true,
      };
    } else {
      result = {
        ...result,
        error: 'Проверьте правильность ввода',
      };
    }
  }
  return result;
}

export function validateOgrn(ogrn: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!ogrn.length) {
    result = {
      ...result,
      error: 'Заполните это поле',
    };
  } else if (/[^0-9]/.test(ogrn)) {
    result = {
      ...result,
      error: 'ОГРН может состоять только из цифр',
    };
  } else if (+ogrn === 0) {
    result = {
      ...result,
      error: 'Укажите верный ОГРН',
    };
  } else if (ogrn.length !== 13) {
    result = {
      ...result,
      error: 'ОГРН может состоять только из 13 цифр',
    };
  } else {
    const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
    if (n13 === parseInt(ogrn[12])) {
      result = {
        ...result,
        valid: true,
      };
    } else {
      result = {
        ...result,
        error: 'Проверьте правильность ввода',
      };
    }
  }
  return result;
}

export function validateOgrnWeak(ogrn: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!ogrn.length) {
    result = {
      ...result,
      error: 'Заполните это поле',
    };
  } else if (/[^0-9]/.test(ogrn)) {
    result = {
      ...result,
      error: 'ОГРН может состоять только из цифр',
    };
  } else if (+ogrn === 0) {
    result = {
      ...result,
      error: 'Укажите верный ОГРН',
    };
  } else if (ogrn.length !== 13) {
    result = {
      ...result,
      error: 'ОГРН может состоять только из 13 цифр',
    };
  } else {
    result = {
      ...result,
      valid: true,
    };
  }
  return result;
}

export function validateOgrnip(ogrnip: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!ogrnip.length) {
    result = {
      ...result,
      error: 'ОГРНИП пуст',
    };
  } else if (/[^0-9]/.test(ogrnip)) {
    result = {
      ...result,
      error: 'ОГРНИП может состоять только из цифр',
    };
  } else if (+ogrnip === 0) {
    result = {
      ...result,
      error: `${ogrnip} - не валидный ОГРНИП`,
    };
  } else if (ogrnip.length !== 15) {
    result = {
      ...result,
      error: 'ОГРНИП может состоять только из 15 цифр',
    };
  } else {
    const n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));
    if (n15 === parseInt(ogrnip[14])) {
      result = {
        ...result,
        valid: true,
      };
    } else {
      result = {
        ...result,
        error: 'Проверьте правильность ввода',
      };
    }
  }
  return result;
}

export function validateOgrnipWeak(ogrnip: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!ogrnip.length) {
    result = {
      ...result,
      error: 'ОГРНИП пуст',
    };
  } else if (/[^0-9]/.test(ogrnip)) {
    result = {
      ...result,
      error: 'ОГРНИП может состоять только из цифр',
    };
  } else if (+ogrnip === 0) {
    result = {
      ...result,
      error: `${ogrnip} - не валидный ОГРНИП`,
    };
  } else if (ogrnip.length !== 15) {
    result = {
      ...result,
      error: 'ОГРНИП может состоять только из 15 цифр',
    };
  } else {
    result = {
      ...result,
      valid: true,
    };
  }
  return result;
}

export function validateBik(bik: string) {
  let result = {
    valid: false,
    error: '',
  };

  if (!bik.length) {
    result = {
      valid: false,
      error: 'Заполните это поле',
    };
  } else if (/[^0-9]/.test(bik)) {
    result = {
      valid: false,
      error: 'БИК может состоять только из цифр',
    };
  } else if (bik.length !== 9) {
    result = {
      valid: false,
      error: 'БИК должен содержать 9 цифр',
    };
  } else {
    result = {
      valid: true,
      error: '',
    };
  }

  return result;
}

export function validateKs(ks: string, bik: string) {
  let result = {
    valid: false,
    error: 'БИК неверный',
  };

  if (validateBik(bik).valid) {
    if (!ks.length) {
      result = {
        valid: false,
        error: 'К/С пуст',
      };
    } else if (/[^0-9]/.test(ks)) {
      result = {
        valid: false,
        error: 'К/С может состоять только из цифр',
      };
    } else if (ks.length !== 20) {
      result = {
        valid: false,
        error: 'К/С может состоять только из 20 цифр',
      };
    } else {
      const bikKs = '0' + bik.toString().slice(4, 6) + ks;
      let checksum = 0;
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      for (const i in coefficients) {
        checksum += coefficients[i] * (+bikKs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = {
          valid: true,
          error: '',
        };
      } else {
        result = {
          valid: false,
          error: 'Проверьте правильность ввода',
        };
      }
    }
  }
  return result;
}

export function validateRs(rs: string, bik: string) {
  let result = {
    valid: false,
    error: 'Укажите БИК, чтобы мы могли проверить верность ввода расчетного счета',
  };

  if (validateBik(bik).valid) {
    if (!rs.length) {
      result = {
        valid: false,
        error: 'Заполните это поле',
      };
    } else if (/[^0-9]/.test(rs)) {
      result = {
        valid: false,
        error: 'Расчетный счёт может состоять только из цифр',
      };
    } else if (rs.length !== 20) {
      result = {
        valid: false,
        error: 'Расчетный счёт должен содержать 20 цифр',
      };
    } else {
      const bikRs = bik.toString().slice(-3) + rs;
      let checksum = 0;
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      for (const i in coefficients) {
        checksum += coefficients[i] * (+bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = {
          valid: true,
          error: '',
        };
      } else {
        result = {
          valid: false,
          error: 'Проверьте правильность ввода',
        };
      }
    }
  }
  return result;
}

/* tslint:enable */
