import React, { useState, useEffect } from 'react';
import { useTokenProvider } from '@app/connectivity/components/token-provider/useTokenProvider';
import jwt from 'jsonwebtoken';
import { Authority } from './Authority';
import { AuthorityContext } from './Context';

const extractAuthorities = (token?: string) => {
  if (!token) {
    return [];
  }

  const current = jwt.decode(token) as Record<string, string>;
  return ((current.authorities || '').split(',') as Authority[]) || [];
};

export const AuthorizationTokenAuthorityProvider = (props: { children?: React.ReactNode }) => {
  const tokenProvider = useTokenProvider();
  const [data, setData] = useState({
    authorities: new Set(extractAuthorities(tokenProvider.getToken())),
    pending: false,
  });

  useEffect(() => {
    const unsubscribe = tokenProvider.addChangeEventListener((token) => {
      setData({
        authorities: new Set(extractAuthorities(token)),
        pending: false,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <AuthorityContext.Provider value={data}>{props.children}</AuthorityContext.Provider>;
};
