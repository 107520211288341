import styled, { css } from '@styles/styled-helpers';

interface IDefaultInputProps {
  invalid?: boolean;
  hideNativeValidation?: boolean;
}

export const DefaultInput = styled.input<IDefaultInputProps>`
  display: block;
  width: 100%;
  border: 1px solid ${(props) => (props.invalid ? '#D0021B' : '#E8EDEE')};
  border-radius: 4px;
  padding: 0.5em;
  height: 3em;
  padding-left: 1em;

  background-color: ${(props) => (props.disabled ? props.theme.disabledBackgroundColor : '#FFFFFF')};

  color: ${(props) => (props.disabled ? props.theme.disabledTextColor : props.theme.defaultTextColor)};

  font-weight: 500;
  align-items: stretch;

  &::placeholder {
    color: #bbb;
    font-weight: 500;
  }

  ${(props) =>
    !props.hideNativeValidation &&
    css`
      &:invalid {
        border: 1px solid #d0021b;
      }
    `}
`;
