import React from 'react';
import { AdsBlock } from '@components/ad/AdsBlock';
import styled from 'styled-components';

const Container = styled.div`

`;

interface IAdsFloorProps {
  renderTo: string;
  blockId: string;
}

  export const AdsFloor = (props: IAdsFloorProps) => {
    return (
        <Container>
          <AdsBlock platform ={"desktop"} type= {"floorAd"} blockId={props.blockId} renderTo={props.renderTo}/>
        </Container>
    );
  };













