import gql from 'graphql-tag';

export interface IEmailCheckingResponse {
  email: {
    status: 'AVAILABLE' | 'OCCUPIED' | 'FORBIDDEN';
  };
}

export interface IEmailCheckingVariables {
  email: string;
}

export const CHECK_EMAIL_QUERY = gql`
  query checkEmail($email: String!) {
    email: authentication {
      status: emailAvailability(email: $email)
    }
  }
`;
