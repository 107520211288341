import React, { useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { HeaderSearchBox } from 'shared-modules';
import styled from '@styles/styled-helpers';

interface SearchRouteParams {
  query: string;
}

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #e8edee;
  background: #fff;
`;

export const BoundSearchBar = withRouter((props: RouteComponentProps<SearchRouteParams>) => {
  const [query, setQuery] = useState('');

  const onChangeCallback = useCallback(
    (newQuery: string) => {
      props.history.push({
        ...props.location,
        pathname: `/search/${encodeURIComponent(newQuery)}`,
      });
    },
    [props.location],
  );

  const re = /\/search\/(.+)\??/i;
  const matches = props.location.pathname.match(re);
  const urlQuery = (matches && matches[1]) || '';

  if (urlQuery !== query) {
    if (urlQuery) {
      setQuery(urlQuery!);
    } else {
      setQuery('');
    }
  }

  return (
    <Container>
      <HeaderSearchBox mobileViewBreakpoint={600} key={query} defaultQuery={query} onChange={onChangeCallback} />
    </Container>
  );
});
