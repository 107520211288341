import React from 'react';
import { DefaultExternalLink } from '@components/DefaultLink';
import { InlineBilling } from '../../billing/plans/InlineBilling';
import { useAccountData } from '@app/components/providers/authentication/useAccountData';
import { LogOnMount } from '@amplitude/react-amplitude';

import styled from '@styles/styled-helpers';
import { saleDate } from '@app/constants/constant';

const featureList = [
  {
    title: 'СКОРО',
    points: [
      {
        title: 'Мониторинг изменений ЕГРЮЛ',
        description: 'Отслеживайте изменения в деятельности своих контрагентов и всегда будьте начеку',
      },
      {
        title: 'Блокировка расчётных счетов',
        description: 'Получайте уведомления о блокировках расчётных счетов российских ЮЛ и ИП',
      },
      {
        title: 'Участие в государственных закупках',
        description: 'Изучайте сводки об участии ваших контрагентов в государственных закупках',
      },
      {
        title: 'Финансовый анализ',
        description: 'Анализируйте финансовое положение ваших контрагентов до того, как начнёте с ними работу',
      },
    ],
  },
];

export const PresalePromoPage = () => {
  const userData = useAccountData();
  return (
    <Container>
      <LogOnMount
        eventType="Просмотр — presale promo"
        eventProperties={{
          email: (userData.account && userData.account.email) || undefined,
        }}
      />
      <HeaderBlock>
        <Header>Подписка на Контрагентио со скидкой до {saleDate}</Header>
        <HeaderDescription>
          Выберите свой тариф и продолжайте пользоваться преимуществами усовершенствованного сервиса по проверке
          контрагентов
        </HeaderDescription>
        <Label>До {saleDate} со скидкой 12 000 ₽</Label>
      </HeaderBlock>
      <FeaturesBlock>
        <FeaturesBlockHeader>Что нового?</FeaturesBlockHeader>
        <FeaturesBlockDescriprtion>
          Благодаря расширенным функциям системы вы сможете собрать подробное досье о своих бизнес-партнерах, быть в
          курсе текущих событий компаний, с которыми ведете дела
        </FeaturesBlockDescriprtion>
        <FeatureBlock>
          <FeatureHeader>
            <FeatureIcon />
            УЖЕ ДОСТУПНО
          </FeatureHeader>
          <FeatureList>
            <Point>
              <PointTitle>Обмен учредительными документами с контрагентами за 10 сек.</PointTitle>
              <PointDescription>
                Экономьте время на обмене учредительными документами с новым сервисом Контрагентио.Диалог.
                <DefaultExternalLink href={window.rrenv.urls.dialogLanding} newTab>
                  {' '}
                  Подробнее о сервисе →
                </DefaultExternalLink>
              </PointDescription>
            </Point>
            <Point>
              <PointTitle>Новый отчёт о проявленной должной осмотрительности</PointTitle>
              <PointDescription>
                Мы подготовили для вас новую форму отчёта о проверке контрагентов. Больше информации, больше проверок,
                меньше головной боли. Отчёт доступен всем пользователям
                <DefaultExternalLink href="/billing/plans"> подписки на сервис</DefaultExternalLink>.
                <DefaultExternalLink href="https://drive.google.com/open?id=1ot6olyyD8ktbCK7xkrtSBnmlfcZ5vEQw" newTab>
                  {' '}
                  Пример отчёта →
                </DefaultExternalLink>
              </PointDescription>
            </Point>
            <Point>
              <PointTitle>Автоматизируйте проверку контрагентов</PointTitle>
              <PointDescription>
                Интегрируйте любые сведения Контрагентио в вашу корпоративную информационную систему — будь то CRM, ERP
                или интернет-магазин.
                <DefaultExternalLink href="https://github.com/kontragentio/api" newTab>
                  {' '}
                  Подробнее об API →
                </DefaultExternalLink>
              </PointDescription>
            </Point>
            <Point>
              <PointTitle>Картотека арбитражных дел</PointTitle>
              <PointDescription>Изучайте арбитражные процессы с участием ваших контрагентов</PointDescription>
            </Point>
          </FeatureList>
        </FeatureBlock>
        {featureList.map((item) => (
          <FeatureBlock key={item.title}>
            <FeatureHeader>
              <FeatureIcon />
              {item.title}
            </FeatureHeader>
            <FeatureList>
              {item.points.map((point) => (
                <Point key={point.title}>
                  <PointTitle>{point.title}</PointTitle>
                  <PointDescription>{point.description}</PointDescription>
                </Point>
              ))}
            </FeatureList>
          </FeatureBlock>
        ))}
      </FeaturesBlock>
      <PlanBlock>
        <PlanHeader>Выберите свой тариф</PlanHeader>
        <PlanDescription>Оформите подписку на сервис до {saleDate} и получите скидку 15%</PlanDescription>
        <InlineBilling />
      </PlanBlock>
    </Container>
  );
};

const Container = styled.div`
  flex: 1 1 auto;
`;
const Block = styled.div`
  width: 100%;
  padding-top: 1.5em;

  @media (min-width: ${1000 / 16}em) {
    padding-top: 3.125em;
  }
`;
const Title = styled.h2`
  padding: 0 1em;
  font-size: 2em;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
`;
const Description = styled.span`
  display: block;
  padding: 0 1em;
  margin: 0.625em auto;
  width: 100%;
  max-width: 48em;
  line-height: 1.5;
  text-align: center;
`;

const HeaderBlock = styled(Block)`
  height: 14.75em;
  background: url(${require('./assets/background.svg')}) no-repeat right center;
  background-size: cover;
`;

const Header = styled(Title)`
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  color: #fff;

  @media (min-width: 670px) {
    font-size: 2em;
  }
`;
const HeaderDescription = styled(Description)`
  display: none;

  font-weight: 500;
  color: #fff;

  @media (min-width: ${530 / 16}em) {
    display: block;
  }
`;
const Label = styled.span`
  display: block;
  max-width: 18em;
  margin: 1.5em auto;
  line-height: 2;
  text-align: center;
  border-radius: 4px;
  color: #37393a;
  background-color: #ffed8a;

  @media (min-width: ${530 / 16}em) {
    margin: auto;
  }
`;

const FeaturesBlock = styled(Block)`
  margin: auto;
  padding: 1.5em 1em 0 1em;
  max-width: 50em;

  @media (min-width: ${1000 / 16}em) {
    padding-top: 3.125em 1em 0 1em;
  }
`;
const FeaturesBlockHeader = styled(Title)``;
const FeaturesBlockDescriprtion = styled(Description)`
  margin: 1em auto;
`;
const FeatureBlock = styled.div`
  margin-top: 0.75em;
`;
const FeatureIcon = styled.div`
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  border-radius: 50%;
  background-color: #4fcee0;
`;
const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
  line-height: 1;
  font-weight: 700;
  color: #4fcee0;
`;
const FeatureList = styled.div`
  margin: 0.75em 0 0 0.625em;
  padding-left: 1.375em;
  border-left: 4px solid #4fcee0;
`;
const Point = styled.div`
  padding: 1em 0;
`;
const PointTitle = styled.div`
  font-size: 1.375em;
  line-height: ${1.5 / 1.375};
  font-weight: 700;
`;
const PointDescription = styled.div`
  padding-top: 0.625em;
  line-height: 1.5;
`;

const PlanBlock = styled(Block)``;
const PlanHeader = styled(Title)``;
const PlanDescription = styled(Description)`
  margin: 1em auto;
`;
