import { IdGetterObj, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragment-types/fragmentTypes';

const dataIdFromObject = (object: IdGetterObj & { [key: string]: any }) => {
  switch (object.__typename) {
    case 'Company':
      // tslint:disable-next-line: no-null-keyword
      return object.ogrn ? `${object.__typename}:${object.ogrn}` : null;
    case 'Individual':
      // tslint:disable-next-line: no-null-keyword
      return object.ogrnip ? `${object.__typename}:${object.ogrnip}` : null;
    default:
      return defaultDataIdFromObject(object);
  }
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const inMemoryCache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject,
});
