import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { PlanWrapper } from 'kontragentio-reusable-components';
import styled from '@styles/styled-helpers';
import { useAccountData } from '@app/components/providers/authentication/useAccountData';

const Container = styled.div`
  width: 100%;
`;
interface InlineBillingProps {
  styleBreakpoint?: number;
  isTall?: boolean;
}

export const InlineBilling = withRouter((props: RouteComponentProps & InlineBillingProps) => {
  const links = window.rrenv.urls;
  const userData = useAccountData();

  return (
    <Container>
      <PlanWrapper
        apiUrl={links.api}
        baseUrl={links.dialogWizard}
        source="SMTH_ELSE"
        styleBreakpoint={props.styleBreakpoint}
        isTall={props.isTall}
        userEmail={userData.account && userData.account.email}
      />
    </Container>
  );
});
