import gql from 'graphql-tag';
import { OrganizationStatus, FutureStatus } from '../../../../common/types';

export interface EgripDataResponse {
  basic: {
    __typename: 'Individual';
    ogrnip: string;
    inn?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    gender?: string;
    type?: string;
    citizenship?: string;
    personalData: {
      snils?: string;
      birthDate?: number;
      birthPlace?: number;
      address?: string;
    };

    registration: {
      grnDate: number;
    };
    status: {
      grnDate: number;
      text: string;
      currentStatus: OrganizationStatus;
      futureStatus: FutureStatus;
    };
    address: {
      full?: string;
    };
    okveds: ReadonlyArray<{
      isPrimary: boolean;
      code: string;
      description: string;
      grnDate: number;
    }>;
    form: {
      code: string;
      compact: string;
      full: string;
    };
  };
}

export const egripQuery = gql`
  query egrip($grn: String!) {
    basic: individual(ogrnip: $grn) {
      ogrnip
      inn
      lastName
      firstName
      middleName
      gender
      type
      citizenship
      personalData {
        snils
        birthDate
        birthPlace
        address
      }

      registration {
        grnDate
      }
      status {
        grnDate
        text
        currentStatus
        futureStatus
      }
      address {
        full
      }
      okveds {
        isPrimary
        code
        description
        grnDate
      }
      form {
        code
        compact
        full
      }
    }
  }
`;
