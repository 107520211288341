import jwt from 'jsonwebtoken';

export const analyzeToken = (token: string | undefined) => {
  try {
    const decoded = jwt.decode(token!) as Record<string, any>;
    return {
      ttl: decoded.exp - Date.now() / 1000,
      anonymous: !decoded.email,
      type: decoded.token_type as 'MANUAL' | 'AUTOMATIC',
      authorities: decoded.authorities.split(','),
    };
  } catch {
    return {
      ttl: -1,
      anonymous: true,
      type: 'MANUAL' as 'MANUAL' | 'AUTOMATIC',
      authorities: [],
    };
  }
};
