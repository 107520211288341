import React from 'react';
import { Switch, Redirect, RouteComponentProps, Route } from 'react-router';
import { AsyncPlanPage } from './plans/async';
import { AsyncPaymentPage } from './payment/payment-redirect/async';
import { AsyncPaymentStatusPage } from './payment/payment-status/async';
import { Helmet } from 'react-helmet-async';

export const BillingRoot = (props: RouteComponentProps) => {
  return (
    <>
      <Helmet>
        <title>Тарифы и цены</title>
      </Helmet>
      <Switch>
        <Route path={`${props.match.url}/plans`} component={AsyncPlanPage} />
        <Route path={`${props.match.url}/payment`} component={AsyncPaymentPage} />
        <Route path={`${props.match.url}/:status`} component={AsyncPaymentStatusPage} />
        <Redirect to={`${props.match.url}/plans`} />
      </Switch>
    </>
  );
};
