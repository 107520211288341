import { useContext } from 'react';
import { AuthorityContext } from './Context';
import { useAccountData } from '@app/components/providers/authentication/useAccountData';
import { Authority } from './Authority';
import isEmpty from 'lodash/isEmpty';
import { getEntityGrn } from '@helpers/entity-helpers';

export function useAuthority() {
  const context = useContext(AuthorityContext);
  return context;
}

const getUnionAuthoritySet = (setA: ReadonlySet<Authority>, setB: Set<Authority>) => {
  const newSet = new Set(setA);
  for (const elem of setB) {
    newSet.add(elem);
  }
  return newSet;
};

export function useOwnAuthority(grn: string) {
  const accountData = useAccountData({ fetchPolicy: 'network-only' });

  if (isEmpty(accountData)) {
    return {
      authorities: new Set<Authority>([]),
      pending: true,
    };
  }

  const ownAuthorities = new Set<Authority>([
    'contacts',
    'beneficialOwner',
    'analytics',
    'kad',
    'confirmed.address',
    'confirmed.bankAccounts',
    'compliance.score',
    'compliance.risks',
    'structure.subsidiaries',
    'structure.graph',
    'activity.certificates',
    'activity.licenses',
    'activity.bailiffs',
    'activity.inspections',
    'activity.fedresurs',
    'print.companyCard',
    'print.complianceReport',
    'print.extract',
  ]);

  const confirmations = (accountData.account && accountData.account.verifications) || [];
  /* Определяем есть ли среди подвержденных организаций текущая */
  const isOrgConfirmed = confirmations.find((item) => {
    return getEntityGrn(item.target.organization) === grn && item.status === 'COMPLETE';
  });

  const context = useContext(AuthorityContext);
  const ownAuthoritiesContext = {
    authorities: getUnionAuthoritySet(context.authorities, ownAuthorities),
    pending: false,
  };

  return isOrgConfirmed ? ownAuthoritiesContext : context;
}
