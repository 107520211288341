import React from 'react';
import styled from '@styles/styled-helpers';

export const Container = styled.div`
  font-size: 0.875em;
  display: block;
  line-height: 1.714;

  &:not(:first-child) {
    margin-top: 1em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  @media (min-width: ${800 / 16}em) {
    display: flex;
    &:not(:first-child) {
      margin-top: 0.5em;
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
`;

export const Title = styled.div`
  display: block;
  padding-right: 0.75em;
  color: #8097a2;
  overflow: hidden;

  @media (min-width: ${800 / 16}em) {
    flex: 0 0 14em;
    width: 14em;
    display: inline-block;
  }
`;

export const Content = styled.div`
  @media (min-width: ${800 / 16}em) {
    flex: 1 1 auto;
  }

  display: block;
  overflow: hidden;
  padding: 0;
  font-weight: 500;
`;

interface TitledBlockProps {
  title: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
}

export const TitledBlock = (props: TitledBlockProps) => {
  if (!React.Children.count(props.children) && !React.Children.count(props.content)) {
    return <></>;
  }

  return (
    <Container>
      <Title>{props.title}</Title>
      <Content>{props.children || props.content}</Content>
    </Container>
  );
};
