import moment from 'moment/';
import 'moment-timezone';
import '@app/locales/moment-ru';
import { EntityType } from '@app/components/sections/report/content/common/types';

export function formatLongDate(timestamp: number): string {
  return moment
    .unix(timestamp)
    .utc()
    .format('LL');
}

export function formatLongDateTime(timestamp: number): string {
  return moment
    .unix(timestamp)
    .utc()
    .format('LLL');
}

export function formatEasyToReadDate(timestamp: number, capitalizeOutput: boolean = true): string {
  const result = moment.unix(timestamp).calendar();
  if (!capitalizeOutput) {
    return result.toLowerCase();
  } else {
    return result;
  }
}

export function formatEasyToReadAgeAsDate(age: number, capitalizeOutput: boolean = true): string {
  const result = moment()
    .subtract(age, 'seconds')
    .calendar();
  if (!capitalizeOutput) {
    return result.toLowerCase();
  } else {
    return result;
  }
}

export function compareDate(timestamp: number) {
  return moment().diff(moment.unix(timestamp), 'days');
}

export function dateDiff(date1: number, date2: number) {
  return moment.unix(date1).diff(moment.unix(date2), 'days');
}

export function formatDate(timestamp: number) {
  return moment
    .unix(timestamp)
    .utc()
    .format('L')
    .toString();
}

export function parseDate(input: string, format = 'YYYY-MM-DD') {
  return moment(input, format).unix();
}

export function formatDateAs(timestamp: number, format: string) {
  return moment
    .unix(timestamp)
    .utc()
    .format(format)
    .toString();
}

export function formatLongDateWithTime(timestamp: number) {
  return moment
    .unix(timestamp)
    .local()
    .format('DD MMMM, H:mm')
    .toString();
}

export function formatFullDateWithTime(timestamp: number) {
  return moment
    .unix(timestamp)
    .local()
    .format('DD.MM.YYYY, H:mm z')
    .toString();
}

export function formatLongDateWithMskTime(timestamp: number) {
  return moment
    .unix(timestamp)
    .tz('Europe/Moscow')
    .format('DD MMMM, H:mm z')
    .toString();
}

export function formatFullDateWithMskTime(timestamp: number) {
  return moment
    .unix(timestamp)
    .tz('Europe/Moscow')
    .format('DD.MM.YYYY, H:mm z')
    .toString();
}

export function formatAge(age: number, { withoutSuffix = false } = {}) {
  return moment
    .unix(Date.now() / 1000 - age)
    .utc()
    .fromNow(withoutSuffix);
}

export function formatAsDistance(timestamp: number, { withoutSuffix = true } = {}) {
  return moment
    .unix(timestamp)
    .utc()
    .fromNow(withoutSuffix);
}

export function formatTimespan(timestamp: number) {
  return `${formatLongDate(timestamp)} (${formatAsDistance(timestamp)})`;
}

export function getYear(timestamp: number) {
  return moment
    .unix(timestamp)
    .utc()
    .format('YYYY');
}

export function getReportTypeFromGrn(grn: string) {
  switch (grn.length) {
    case 13:
      return EntityType.Company;
    case 15:
      return EntityType.Individual;
    default:
      throw new Error('Invalid Report Type');
  }
}

export function formatNumber(value: number | string) {
  return (value || 0).toLocaleString('ru', {
    maximumFractionDigits: 2,
    useGrouping: true,
  });
}

export function formatNumberWithDigit(value: number | string) {
  return (value || 0).toLocaleString('ru', {
    minimumFractionDigits: 2,
    useGrouping: true,
  });
}

// Возможно слегка упрощенный метод форматирования телефона, но пока решили остановиться на этом
export function formatPhone(phone: string) {
  // Пока задан только такой формат телефонов
  if (/^\+7([0-9]{10})$/g.test(phone)) {
    return `${phone.substring(0, 2)} (${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(
      8,
      10,
    )}-${phone.substring(10, 12)}`;
  }
  if (/^(8|7)([0-9]{10})$/g.test(phone)) {
    return `+7 (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`;
  }
  if (/^[0-9]{10}$/g.test(phone)) {
    return `+7 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 8)}-${phone.substring(8, 10)}`;
  }
  return phone;
}

// Склонение слова в зависимости от количества
// **NOTE**: Код не до конца понял, он мадовый. Типизировал и оставил как есть.
export function declensionForm(numerator: number, forms: [string, string, string]) {
  const rounded = Math.abs(numerator) % 100;
  const remainder = rounded % 10;
  if (rounded > 10 && rounded < 20) {
    return forms[2];
  }
  if (remainder > 1 && remainder < 5) {
    return forms[1];
  }
  if (remainder === 1) {
    return forms[0];
  }
  return forms[2];
}

export function removeUnnecessaryCommas(address: string = '') {
  return address
    .trim()
    .replace(/\w*?,\w*/g, ',')
    .split(',')
    .filter((str) => str.length > 0)
    .join(', ');
}

export function fixAddress(address: string) {
  let withoutComma = address;
  // Циклическое условие по удалению лишних запятых
  do {
    withoutComma = withoutComma.replace(/,\s*,/g, ',');
  } while (withoutComma.search(/,\s*,/g) >= 0);
  // Убираем лишние запятые с начала и конца строки
  withoutComma = withoutComma.replace(/^,/, '').replace(/,$/, '');
  // Расставляем првильно пробелы у запятых
  return withoutComma
    .split(',')
    .join(', ')
    .split(' ,')
    .join(',');
}
