import debug from 'debug';
import fetch from 'unfetch';

export const log = debug('kio:log');
export const warn = debug('kio:warn');
export const error = debug('kio:error');
export const trace = debug('kio:trace');

if (process.env.NODE_ENV !== 'production' && !localStorage.getItem('debug')) {
  localStorage.setItem('debug', 'kio:*');
}
export type LogType = 'ERROR' | 'WARNING' | 'INFO' | 'EVENT';

interface INotifyDevelopersOptions {
  type: LogType;
  metadata?: Array<{ key: string; value: any }>;
  index?: Array<{ key: string; value: any }>;
  details?: string;
}

const LOG_MUTATION = `
  mutation log(
    $message: String!
    $type: LogType!
    $details: String
    $location: String
    $reporter: String
    $metadata: [LogMetadata!]
    $index: [LogMetadata!]
  ) {
    system {
      log(
        message: $message
        type: $type
        details: $details
        location: $location
        reporter: $reporter
        metadata: $metadata
        index: $index
      ) {
        acknowledged
      }
    }
  }
`;

const loggingFunctions = {
  EVENT: log,
  INFO: log,
  WARNING: warn,
  ERROR: error,
};

export function notifyDevelopers(
  message: string,
  { type, metadata, details }: INotifyDevelopersOptions = { type: 'EVENT' },
) {
  loggingFunctions[type]('Notifying team: %s %O', message, metadata);

  return fetch(`${window.rrenv.urls.api}/v3/graphql?query=log`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify({
      query: LOG_MUTATION,
      variables: {
        reporter: 'FRONTEND',
        type,
        message,
        location: window.location.href,
        details,
        metadata: [
          { key: 'mentions', value: ['ERROR', 'EVENT'].includes(type) ? '@BlackCatTBs @dplotnikov' : '' },
          { key: 'user-agent', value: navigator.userAgent },
          { key: 'token', value: localStorage.getItem('authentication_token') },
          ...((metadata || []).map(({ key, value }) => {
            if (typeof value === 'string') {
              return { key, value };
            } else {
              // tslint:disable-next-line: no-null-keyword
              return { key, value: JSON.stringify(value, null, 2) };
            }
          }) || []),
        ],
      },
    }),
  });
}
