import styled from '@styles/styled-helpers';

const Button = styled.button.attrs((props) => ({ type: props.type || 'button' }))`
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.57142857em 1.14285714em;
  background-color: #fff;
  border-radius: 4px;
  line-height: 1.714em;
  font-size: 0.875em;
  color: ${(props) => props.theme.mainBrandColor};
  cursor: pointer;

  /* &:not(:first-child) {
        margin-left: 1em;
    } */

  &:disabled {
    background-color: #fff !important;
    cursor: default !important;
    color: ${(props) => props.theme.disabledTextColor} !important;
    border: 1px solid #e8edee !important;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.mainBrandColor};
  color: #fff;

  &:hover {
    background-color: #47bccd;
  }
`;

export const SecondaryButton = styled(Button)`
  border-color: #e8edee;
  background-color: white;

  &:hover {
    border-color: ${(props) => props.theme.mainBrandColor};
  }
`;

export const TransparentButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.defaultTextColor};
`;

export const GraySecondaryButton = styled(Button)`
  color: ${(props) => props.theme.defaultTextColor};
  border-color: #e8edee;
  background-color: white;

  &:hover {
    border-color: ${(props) => props.theme.defaultTextColor};
  }
`;

export const LinkButton = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  font-weight: 300;
  border-bottom: 1px solid transparent;
  color: ${(props) => props.theme.defaultLinkColor};
  text-decoration: none;
  cursor: pointer;

  &:not(:disabled) {
    &,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.defaultLinkColor};
    }

    &:hover {
      color: ${({ theme }) => theme.hoveredLinkColor};
    }
  }
`;
