import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncPlanPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "plan-page" */ './PlanPage').then((res) => res.PlanPage),
  loading() {
    return <SlimLoader pending />;
  },
});
