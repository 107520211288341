/* tslint:disable */

import moment from 'moment';
import 'moment/locale/ru';

function plural(word: any, num: any) {
  var forms = word.split('_');
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
    ? forms[1]
    : forms[2];
}

function relativeTimeWithPlural(number: any, withoutSuffix: any, key: any) {
  var format: any = {
    ss: withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
    mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
    hh: 'час_часа_часов',
    dd: 'день_дня_дней',
    MM: 'месяц_месяца_месяцев',
    yy: 'год_года_лет',
  };
  if (key === 'm') {
    return withoutSuffix ? 'минута' : 'минуту';
  } else {
    return number + ' ' + plural(format[key], +number);
  }
}

moment.updateLocale('ru', {
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    ss: relativeTimeWithPlural,
    m: relativeTimeWithPlural,
    mm: relativeTimeWithPlural,
    h: '1 час',
    hh: relativeTimeWithPlural,
    d: '1 день',
    dd: relativeTimeWithPlural,
    M: '1 месяц',
    MM: relativeTimeWithPlural,
    /* Всё ради вот этой строчки, которая писала "год" вместо "1 год" */
    y: '1 год',
    yy: relativeTimeWithPlural,
  },
});
