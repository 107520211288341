export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
  OwnershipGraphLayout: any;
  /** Long type */
  Long: any;
  FinanceReportLayout: any;
  FinanceReportData: any;
  ZDateTime: any;
  FlexiblePackageObject: any;
  FlexibleSectionObject: any;
  FormData: any;
};

export type Account = {
  __typename?: 'Account';
  readonly me: MyInfo;
};

/** Информация о балансе пользователя */
export type AccountBalanceResult = {
  __typename?: 'AccountBalanceResult';
  /** Остаток средств на балансе */
  readonly amount: Scalars['Float'];
};

/** Информация об аккаунте */
export type AccountInformation = {
  __typename?: 'AccountInformation';
  /** Идентификатор аккаунта */
  readonly id: Scalars['String'];
  /** Адрес электронной почты */
  readonly email: Scalars['String'];
  /** Имя */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Фамилия */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
  /** Общее название группы компаний */
  readonly brandName?: Maybe<Scalars['String']>;
  /** Статус аккаунта */
  readonly status: AccountStatus;
  /** Дополнительные рабочие места */
  readonly slaves?: Maybe<ReadonlyArray<Maybe<AccountInformation>>>;
  /** Является ли данный аккаунт мастером */
  readonly isMaster: Scalars['Boolean'];
  /** Данные о подтвержденной на этом аккаунте организации */
  readonly organizationData: OrganizationData;
  /** Дата создания аккаунта в формате Unix Timestamp */
  readonly creationDate: Scalars['Timestamp'];
};

export type AccountOperation = {
  __typename?: 'AccountOperation';
  /** Управление правами на организацию */
  readonly savePermissions: Scalars['Boolean'];
  /** Сохранение данных профиля пользователя */
  readonly saveProfile: Scalars['Boolean'];
};

export type AccountOperationSavePermissionsArgs = {
  notifyUser?: Maybe<Scalars['Boolean']>;
  organizations: ReadonlyArray<OrganizationPermission>;
  userId: Scalars['String'];
};

export type AccountOperationSaveProfileArgs = {
  details: ProfileDetails;
  userId?: Maybe<Scalars['String']>;
};

/** Дополнительные поля для регистрации */
export type AccountRegistrationDetails = {
  /** Имя */
  readonly firstName: Scalars['String'];
  /** Фамилия */
  readonly lastName: Scalars['String'];
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
  /** Указатель наличия дополнительных рабочих мест */
  readonly slaveUser?: Maybe<Scalars['Boolean']>;
  /** Указатель отправки уведомления */
  readonly notifyUser?: Maybe<Scalars['Boolean']>;
};

export enum AccountStatus {
  /** Активный */
  Active = 'ACTIVE',
  /** Заблокированный */
  Blocked = 'BLOCKED',
}

export enum ActionType {
  /** Запросить пакет */
  RequestDocs = 'REQUEST_DOCS',
  /** Отправить пакет */
  SendDocs = 'SEND_DOCS',
  /** Запросить изменения */
  RequestChanges = 'REQUEST_CHANGES',
  /** Переслать своему сотруднику */
  Forward = 'FORWARD',
  /** Утвердить */
  Approve = 'APPROVE',
  /** Отказаться */
  Reject = 'REJECT',
  /** Возможность прикрепления пакета */
  Seize = 'SEIZE',
  /** Напомнить */
  Reminder = 'REMINDER',
}

export type Address = {
  __typename?: 'Address';
  /** Полный адрес */
  readonly full?: Maybe<Scalars['String']>;
  /** Почтовый индекс */
  readonly postalCode?: Maybe<Scalars['String']>;
  /** Страна */
  readonly country?: Maybe<Scalars['String']>;
  /** Федеральный округ */
  readonly federalDistrict?: Maybe<Scalars['String']>;
  /** Регион */
  readonly region?: Maybe<Region>;
  /** Район */
  readonly area?: Maybe<Scalars['String']>;
  /** Город (населенный пункт) */
  readonly city?: Maybe<City>;
  /** Улица (проспект, переулок и т.п.) */
  readonly street?: Maybe<Scalars['String']>;
  /** Дом (владение и т.п.) */
  readonly house?: Maybe<Scalars['String']>;
  /** Корпус (строение и т.п.) */
  readonly housing?: Maybe<Scalars['String']>;
  /** Квартира (офис и т.п.) */
  readonly room?: Maybe<Scalars['String']>;
  /** Дата регистрации по данному адресу в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
};

export enum ApiType {
  DefaultApi = 'DEFAULT_API',
  MaxApi = 'MAX_API',
  ExtendedApi = 'EXTENDED_API',
  BasicApi = 'BASIC_API',
}

/** Доля владения */
export type ArbitraryGraphRelationShare = {
  __typename?: 'ArbitraryGraphRelationShare';
  /** Процент владения */
  readonly percentage: Scalars['Float'];
  /** Стоимость доли */
  readonly amount: Scalars['Float'];
  /** Дата внесения записи в егрюл */
  readonly grnDate: Scalars['Timestamp'];
};

/** Связь в графе структуры владения */
export type ArbitratyGraphRelation = {
  __typename?: 'ArbitratyGraphRelation';
  /** Информация о доле владения */
  readonly share: ArbitraryGraphRelationShare;
  /** Идентификатор владеющей вершины */
  readonly ownerId: Scalars['String'];
  /** Идентификатор вершины, которой владеют */
  readonly subsidiaryId: Scalars['String'];
};

export type Authentication = {
  __typename?: 'Authentication';
  readonly emailAvailability: AvailabilityType;
  /** Получение спика постоянных токенов пользователя */
  readonly userTokens: ReadonlyArray<UserTokenInfo>;
};

export type AuthenticationEmailAvailabilityArgs = {
  email: Scalars['String'];
};

export type AuthenticationOperation = {
  __typename?: 'AuthenticationOperation';
  /** Сброс пароля */
  readonly resetPassword: Scalars['Boolean'];
  /** Авторизация */
  readonly authenticate?: Maybe<Scalars['String']>;
  /** Регистрация */
  readonly register?: Maybe<Scalars['String']>;
  /** Получение временного анонимного токена */
  readonly anonymousLogin: Scalars['String'];
  /** Смена пароля */
  readonly changePassword: Scalars['Boolean'];
  /** Получение постоянного токена пользователем на себя */
  readonly issueUserToken: UserTokenInfo;
  /** Заготовка */
  readonly revokeUserToken: Scalars['Boolean'];
  /** Выдача токена */
  readonly issueArbitraryToken: Scalars['String'];
  /** Обновление токена */
  readonly renewToken?: Maybe<Scalars['String']>;
};

export type AuthenticationOperationResetPasswordArgs = {
  email: Scalars['String'];
};

export type AuthenticationOperationAuthenticateArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type AuthenticationOperationRegisterArgs = {
  password: Scalars['String'];
  details: AccountRegistrationDetails;
  email: Scalars['String'];
};

export type AuthenticationOperationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type AuthenticationOperationIssueUserTokenArgs = {
  aliasName: Scalars['String'];
  apiType: ApiType;
};

export type AuthenticationOperationRevokeUserTokenArgs = {
  id: Scalars['String'];
};

export type AuthenticationOperationIssueArbitraryTokenArgs = {
  roles?: Maybe<ReadonlyArray<Scalars['String']>>;
  enablePerRequestBilling?: Maybe<Scalars['Boolean']>;
  heritage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  ttl: Scalars['Long'];
  authorities?: Maybe<ReadonlyArray<Scalars['String']>>;
  email: Scalars['String'];
};

export type Authority = {
  __typename?: 'Authority';
  /** Код */
  readonly code?: Maybe<Scalars['String']>;
  /** Наименование организации */
  readonly name?: Maybe<Scalars['String']>;
};

export type AuthorityInformation = {
  __typename?: 'AuthorityInformation';
  /** Наименование раздела, на который выдан доступ */
  readonly name: Scalars['String'];
};

export type AuthorizedCapital = {
  __typename?: 'AuthorizedCapital';
  /** Дата внесения в ЕГРЮЛ записи об уставном капитале в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Размер уставного капитала в рублях */
  readonly amount?: Maybe<Scalars['Float']>;
};

export enum AvailabilityType {
  Available = 'AVAILABLE',
  Occupied = 'OCCUPIED',
  Forbidden = 'FORBIDDEN',
}

export enum BackupCacheType {
  Balance = 'BALANCE',
  ApiPoints = 'API_POINTS',
}

export type BailiffDepartment = {
  __typename?: 'BailiffDepartment';
  /** Наименование отдела */
  readonly name?: Maybe<Scalars['String']>;
  /** Адрес отдела судебных приставов */
  readonly address?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  /** Полное наименование банка */
  readonly bankName?: Maybe<Scalars['String']>;
  /** Краткое наименование банка */
  readonly bankNameShort?: Maybe<Scalars['String']>;
  /** Расчетный счёт */
  readonly current?: Maybe<Scalars['String']>;
  /** Корреспондентский счёт */
  readonly correspondentAccount?: Maybe<Scalars['String']>;
  /** БИК */
  readonly bic?: Maybe<Scalars['String']>;
};

/** Информация о БИК */
export type BankInfo = {
  __typename?: 'BankInfo';
  readonly name?: Maybe<BankName>;
  readonly correspondentAccount?: Maybe<Scalars['String']>;
};

/** Информация о имени банка */
export type BankName = {
  __typename?: 'BankName';
  /** Полное наименование банка */
  readonly full?: Maybe<Scalars['String']>;
  /** Сокращённое наименование банка */
  readonly short?: Maybe<Scalars['String']>;
};

export type BeneficialOwner = {
  __typename?: 'BeneficialOwner';
  /** Информация о доле владения */
  readonly share: Scalars['Float'];
  /** Тип бенефициарного владельца */
  readonly type: BeneficialOwnerType;
  /** Юридическое лицо */
  readonly entity: StructureMember;
};

export enum BeneficialOwnerType {
  /** Физическое лицо */
  Person = 'PERSON',
  /** Зонтичная организация */
  UmbrellaOrganization = 'UMBRELLA_ORGANIZATION',
  /** Руководитель организации */
  Manager = 'MANAGER',
}

export type Beneficiaries = {
  __typename?: 'Beneficiaries';
  /** Сведения о возможности установить бенефициарного владельца */
  readonly type: BeneficiariesType;
  /** Сведения о бенефициарных владельцах */
  readonly entities: ReadonlyArray<BeneficialOwner>;
};

export enum BeneficiariesType {
  /** Бенефициарный владелец установлен */
  Established = 'ESTABLISHED',
  /** Бенефициарный владелец не установлен */
  CantEstablish = 'CANT_ESTABLISH',
}

export type BillingCart = {
  readonly tariffId: Scalars['String'];
  readonly payServiceType: PaymentServiceType;
  readonly items: ReadonlyArray<BillingCartItem>;
};

/** Расчет стоимости покупки */
export type BillingCartCostResult = {
  __typename?: 'BillingCartCostResult';
  /** Полная стоимость в рублях */
  readonly fullValue: Scalars['Long'];
  /** Размер скидки в рублях */
  readonly discountValue: Scalars['Long'];
  /** Детализированный список позиций */
  readonly items: ReadonlyArray<BillingCartCostResultItem>;
};

/** Позиция с ценой */
export type BillingCartCostResultItem = {
  __typename?: 'BillingCartCostResultItem';
  /** Тип позиции */
  readonly type: Scalars['String'];
  /** Оплачиваемое количество позиций */
  readonly amount: Scalars['Int'];
  /** Цена за единицу */
  readonly price: Scalars['Long'];
  /** Стоимость позиции */
  readonly sum: Scalars['Long'];
  /** Описание */
  readonly description: Scalars['String'];
};

export type BillingCartItem = {
  readonly type: Scalars['String'];
  readonly count: Scalars['Int'];
  readonly entity?: Maybe<BillingEntity>;
};

export type BillingCorpApiPackage = {
  __typename?: 'BillingCorpApiPackage';
  readonly id: Scalars['String'];
  readonly tariff: CorpApiType;
  readonly count: Scalars['Int'];
  readonly cost: Scalars['Int'];
};

export type BillingCorpApiPackages = {
  __typename?: 'BillingCorpApiPackages';
  readonly list: ReadonlyArray<BillingCorpApiPackage>;
};

export type BillingEntity = {
  /** сущность (ГРН например) */
  readonly entityId: Scalars['String'];
  /** тип сущности */
  readonly entityType: BillingEntityType;
};

export enum BillingEntityType {
  Grn = 'GRN',
  Extract = 'EXTRACT',
}

export type BillingInvoiceResult = {
  __typename?: 'BillingInvoiceResult';
  /** Ссылка на скачивание счета на оплату */
  readonly invoiceLink?: Maybe<Scalars['String']>;
  /** Id файла */
  readonly fileId: Scalars['String'];
};

export type BillingOperation = {
  __typename?: 'BillingOperation';
  /** Генерация счет на оплату ЮЛ */
  readonly createInvoice: BillingInvoiceResult;
  /** Временный метод для создания платежа на пользователя API по сумме оплаты */
  readonly createInvoiceApi: BillingInvoiceResult;
  /** Создание счета на оплату */
  readonly createPayment: BillingPaymentCreateResult;
  /** Генерация счет на оплату ЮЛ */
  readonly createInvoiceNew: BillingInvoiceResult;
  /** Создание счета на оплату */
  readonly createPaymentNew: BillingPaymentCreateResult;
  /** Оплата разового отчета */
  readonly createReportPayment: BillingPaymentCreateResult;
  /** Результат платежа с фронтенда */
  readonly updatePayment?: Maybe<BillingPaymentResult>;
  readonly backupSnapshot: Scalars['Boolean'];
  readonly backupRecovery: Scalars['Boolean'];
  /** Ручное пополнение баланса пользователя */
  readonly topUpAccount: AccountBalanceResult;
  /** Завершение платежа в ручном режиме */
  readonly paymentResult?: Maybe<BillingPaymentResult>;
  /** Установка триального периода пользователю */
  readonly setTrialPeriod?: Maybe<Scalars['Boolean']>;
  /** Генерация счет на оплату ЮЛ админом */
  readonly createInvoiceAdm: BillingInvoiceResult;
  /** операции за оценки/баллы */
  readonly points: BillingPointsOperation;
};

export type BillingOperationCreateInvoiceArgs = {
  grn: Scalars['String'];
  notifyUser?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tariff: BillingTariffParams;
  email: Scalars['String'];
};

export type BillingOperationCreateInvoiceApiArgs = {
  grn: Scalars['String'];
  notifyUser?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sum: Scalars['Int'];
  email: Scalars['String'];
};

export type BillingOperationCreatePaymentArgs = {
  tariff: BillingTariffParams;
  email?: Maybe<Scalars['String']>;
};

export type BillingOperationCreateInvoiceNewArgs = {
  grn: Scalars['String'];
  notifyUser?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  cart: BillingCart;
  email: Scalars['String'];
};

export type BillingOperationCreatePaymentNewArgs = {
  cart: BillingCart;
  email?: Maybe<Scalars['String']>;
};

export type BillingOperationCreateReportPaymentArgs = {
  cart: BillingCart;
  email?: Maybe<Scalars['String']>;
  reportGrn: Scalars['String'];
};

export type BillingOperationUpdatePaymentArgs = {
  paymentId: Scalars['String'];
  success: Scalars['Boolean'];
  outSum: Scalars['String'];
  signatureValue?: Maybe<Scalars['String']>;
};

export type BillingOperationBackupSnapshotArgs = {
  reason: Scalars['String'];
};

export type BillingOperationBackupRecoveryArgs = {
  reason: Scalars['String'];
  type: BackupCacheType;
};

export type BillingOperationTopUpAccountArgs = {
  reason: Scalars['String'];
  amount: Scalars['Float'];
  userId: Scalars['String'];
};

export type BillingOperationPaymentResultArgs = {
  payment: Scalars['String'];
};

export type BillingOperationSetTrialPeriodArgs = {
  duration: Scalars['Int'];
  cart: BillingCart;
  startDate: Scalars['String'];
  email: Scalars['String'];
};

export type BillingOperationCreateInvoiceAdmArgs = {
  grn: Scalars['String'];
  notifyUser?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userEmail: Scalars['String'];
  cart: BillingCart;
};

export type BillingPaymentCreateResult = {
  __typename?: 'BillingPaymentCreateResult';
  /** Пока только ссылка на оплату */
  readonly url?: Maybe<Scalars['String']>;
  readonly formUrl?: Maybe<Scalars['String']>;
  readonly formData?: Maybe<Scalars['FormData']>;
};

export enum BillingPaymentMethod {
  /** оплата по безналу на Р/С */
  BankClearing = 'BANK_CLEARING',
  /** робокасса */
  Robokassa = 'ROBOKASSA',
}

/** Информация о платеже */
export type BillingPaymentResult = {
  __typename?: 'BillingPaymentResult';
  readonly paymentId: Scalars['String'];
  readonly createTime: Scalars['Timestamp'];
  readonly stateTime: Scalars['Timestamp'];
  readonly state: BillingPaymentState;
  readonly paySum: Scalars['Long'];
  readonly payMethod: BillingPaymentMethod;
  readonly payServiceType: Scalars['String'];
  readonly tariffCode: Scalars['String'];
  readonly reportGrn?: Maybe<Scalars['String']>;
};

export enum BillingPaymentState {
  /** создан */
  Created = 'CREATED',
  /** отказ (отменен) */
  Refused = 'REFUSED',
  /** обрабатыватся */
  Processing = 'PROCESSING',
  /** проведен успешно */
  Success = 'SUCCESS',
  /** Статус неопределен */
  Undefined = 'UNDEFINED',
}

/** баланс баллов подписки */
export type BillingPointsBalance = {
  __typename?: 'BillingPointsBalance';
  /** количество */
  readonly amount: Scalars['Int'];
  /** действуют до */
  readonly endDate?: Maybe<Scalars['ZDateTime']>;
};

export type BillingPointsGrnSubs = {
  __typename?: 'BillingPointsGrnSubs';
  /** ГРН например */
  readonly grn: Scalars['String'];
  /** Начало действия */
  readonly startDate?: Maybe<Scalars['ZDateTime']>;
  /** Конец действия */
  readonly endDate?: Maybe<Scalars['ZDateTime']>;
};

export type BillingPointsInput = {
  /** сущность (ГРН например) */
  readonly entityId: Scalars['String'];
  /** тип сущности */
  readonly entityType: BillingEntityType;
};

export type BillingPointsOperation = {
  __typename?: 'BillingPointsOperation';
  /** Обмен баллов оценки на доступ к карточке компании (не покупка а регистрация скорее) */
  readonly purchase: BillingPointsSubs;
};

export type BillingPointsOperationPurchaseArgs = {
  entity?: Maybe<BillingPointsInput>;
};

/**
 * type BillingUserProductResult {
 *     # список продуктов
 *     list: [BillingUserProduct!]!
 * }
 *  купленный пользователем продукт
 * type BillingUserProduct {
 *     # id
 *     id: String!
 *     # активен ли продукт сейчас
 *     active: Boolean!
 *     # код продукта
 *     type: String!
 *     # дата начала действия
 *     startDate: Timestamp
 *     # дата окончания действия
 *     endDate: Timestamp
 *     # количество приобретенных единиц
 *     count: Int!
 * }
 **/
export type BillingPointsQuery = {
  __typename?: 'BillingPointsQuery';
  /** Текущий баланс юзера */
  readonly balance: BillingPointsBalance;
  /** Список активных подписок за баллы */
  readonly activeSubs: BillingPointsSubsResult;
};

export type BillingPointsSubs = BillingPointsGrnSubs;

export type BillingPointsSubsResult = {
  __typename?: 'BillingPointsSubsResult';
  /** активные подписки */
  readonly list: ReadonlyArray<BillingPointsSubs>;
};

export type BillingRootQueryResult = {
  __typename?: 'BillingRootQueryResult';
  /** Данные о балансе текущего пользователя */
  readonly balance: AccountBalanceResult;
  /** Инфорация о платеже */
  readonly payment?: Maybe<BillingPaymentResult>;
  /** Список доступных тарифов */
  readonly tariffs: BillingTariffResult;
  /** оплачен ли определенный сервис пользователем */
  readonly isPaid: Scalars['Boolean'];
  /** Предварительный расчет стоимости покупки */
  readonly paymentCalculation: BillingCartCostResult;
  /** данные по оценкам/баллам */
  readonly points: BillingPointsQuery;
  /** стоимость тарифов АПИ */
  readonly corpApiPackages: BillingCorpApiPackages;
};

export type BillingRootQueryResultPaymentArgs = {
  paymentId: Scalars['String'];
};

export type BillingRootQueryResultTariffsArgs = {
  payServiceType?: Maybe<PaymentServiceType>;
};

export type BillingRootQueryResultIsPaidArgs = {
  productCode: Scalars['String'];
};

export type BillingRootQueryResultPaymentCalculationArgs = {
  cart: BillingCart;
};

export type BillingRootQueryResultCorpApiPackagesArgs = {
  tariff: CorpApiType;
};

export type BillingTariff = {
  __typename?: 'BillingTariff';
  /** id записи из БД */
  readonly id: Scalars['String'];
  /** id ТП */
  readonly code: Scalars['String'];
  /** Группа тарифов, если задано */
  readonly group?: Maybe<Scalars['String']>;
  /** текущая скидка на ТП */
  readonly discount: Scalars['Int'];
  /** длительность дейтсивия тарифа в днях */
  readonly durationDays: Scalars['Int'];
  /** архивный ТП (при наличии флага его не подключаем) */
  readonly archive: Scalars['Boolean'];
  /** общий текст описания для оплаты */
  readonly description: Scalars['String'];
  /** опции тарифа */
  readonly products: ReadonlyArray<BillingTariffProduct>;
  /** статус активности тарифа */
  readonly status?: Maybe<ServiceStatus>;
};

export type BillingTariffParams = {
  readonly plan?: Maybe<BillingTariffPlan>;
  readonly documentRequestCount?: Maybe<Scalars['Int']>;
  readonly organizationCount?: Maybe<Scalars['Int']>;
  readonly workspaceCount?: Maybe<Scalars['Int']>;
};

export enum BillingTariffPlan {
  Corporate = 'CORPORATE',
  Business = 'BUSINESS',
  CorporateX2 = 'CORPORATE_X2',
  BusinessX2 = 'BUSINESS_X2',
}

export type BillingTariffProduct = {
  __typename?: 'BillingTariffProduct';
  /** id продукта */
  readonly type: Scalars['String'];
  /** название */
  readonly productName: Scalars['String'];
  /** название типа единиц */
  readonly productUnitName: Scalars['String'];
  /** количество по умолчанию включенное в ТП */
  readonly defaultValue: Scalars['Int'];
  /** шаг увеличения количества для подсчета единиц оплаты */
  readonly step: Scalars['Int'];
  /** цена за единицу (сверх умолчания) */
  readonly cost: Scalars['Long'];
};

export type BillingTariffResult = {
  __typename?: 'BillingTariffResult';
  readonly list: ReadonlyArray<BillingTariff>;
};

export type Branch = {
  __typename?: 'Branch';
  /** Полное наименование подразделения */
  readonly fullName?: Maybe<Scalars['String']>;
  /** Дата ГРН в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Адрес */
  readonly address?: Maybe<Address>;
  /** Тип подразделения */
  readonly officeType?: Maybe<OfficeType>;
};

export type CertificateData = {
  __typename?: 'CertificateData';
  /** Сведения о сертификатах и декларациях */
  readonly list: ReadonlyArray<Maybe<CertificateInfo>>;
  /** Статистика по сертификатам и декларациям */
  readonly statistics?: Maybe<Statistic>;
};

export type CertificateDataListArgs = {
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type CertificateInfo = {
  __typename?: 'CertificateInfo';
  /** Идентификатор */
  readonly id: Scalars['String'];
  /** Регистрационный номер */
  readonly regNumber?: Maybe<Scalars['String']>;
  /** Статус */
  readonly status?: Maybe<CertificateStatus>;
  /** Сокращенное наименование типа документа */
  readonly type?: Maybe<CertificateType>;
  /** Тип документа */
  readonly documentType?: Maybe<Scalars['String']>;
  /** Дата начала действия документа в формате Unix Timestamp */
  readonly startDate?: Maybe<Scalars['Timestamp']>;
  /** Дата окончания действия документа в формате Unix Timestamp */
  readonly endDate?: Maybe<Scalars['Timestamp']>;
  /** Заявитель */
  readonly applicant?: Maybe<Entity>;
  /** Изготовитель */
  readonly manufacturer?: Maybe<Entity>;
  /** Наименование документа */
  readonly product?: Maybe<ProductInfo>;
};

export enum CertificateStatus {
  /** Действующий */
  Active = 'ACTIVE',
  /** Аннулирован */
  Annulled = 'ANNULLED',
  /** Архивный */
  Archival = 'ARCHIVAL',
  /** Приостановлен */
  Suspended = 'SUSPENDED',
  /** Прекращён */
  Discontinued = 'DISCONTINUED',
  /** Продлен */
  Prolonged = 'PROLONGED',
  /** Неизвестный */
  Unknown = 'UNKNOWN',
}

export enum CertificateType {
  /** Сертификат */
  Certificate = 'CERTIFICATE',
  /** Декларация */
  Declaration = 'DECLARATION',
}

export type City = {
  __typename?: 'City';
  /** Полное наименование */
  readonly full?: Maybe<Scalars['String']>;
  /** Наименование населенного пункта */
  readonly name?: Maybe<Scalars['String']>;
  /** Тип населенного пункта */
  readonly type?: Maybe<Scalars['String']>;
};

/** Информация об организации */
export type Company = {
  __typename?: 'Company';
  /** Полное наименование */
  readonly fullName?: Maybe<Scalars['String']>;
  /** Краткое наименование */
  readonly shortName?: Maybe<Scalars['String']>;
  /** ОГРН */
  readonly ogrn?: Maybe<Scalars['String']>;
  /** ИНН */
  readonly inn?: Maybe<Scalars['String']>;
  /** КПП */
  readonly kpp?: Maybe<Scalars['String']>;
  /** ОКАТО */
  readonly okato?: Maybe<Scalars['String']>;
  /** ОКПО */
  readonly okpo?: Maybe<Scalars['String']>;
  /** ОКОГУ */
  readonly okogu?: Maybe<Scalars['String']>;
  /** ОКТМО */
  readonly oktmo?: Maybe<Scalars['String']>;
  /** ОКФС */
  readonly okfs?: Maybe<Scalars['String']>;
  /** Дата регистрации в формате Unix Timestamp */
  readonly registrationDate?: Maybe<Scalars['Timestamp']>;
  /** Дата закрытия в формате Unix Timestamp */
  readonly terminationDate?: Maybe<Scalars['Timestamp']>;
  /** Сведения о налоговом органе, в котором юридическое лицо состоит на учете */
  readonly taxInspection?: Maybe<TaxInspection>;
  /** Сведения о регистрирующем органе по месту нахождения юридического лица */
  readonly registration?: Maybe<Registration>;
  /** Руководитель организации */
  readonly head?: Maybe<HeadRelation>;
  /** Руководители */
  readonly management?: Maybe<ReadonlyArray<Maybe<HeadRelation>>>;
  /** БИК */
  readonly bik?: Maybe<Scalars['String']>;
  /** Является ли организация лицензированным банком */
  readonly isBank?: Maybe<Scalars['Boolean']>;
  /** Список ОКВЭД */
  readonly okveds?: Maybe<ReadonlyArray<Okved>>;
  /** Юридический адрес */
  readonly address?: Maybe<Address>;
  /** Организационно-правовая форма */
  readonly form?: Maybe<OrganizationForm>;
  /** Уставный капитал */
  readonly authorizedCapital?: Maybe<AuthorizedCapital>;
  /** Статус компании */
  readonly status?: Maybe<Status>;
  /** Держатель реестра */
  readonly registryHolder?: Maybe<RegistryHolder>;
  /** Подразделения */
  readonly branches?: Maybe<ReadonlyArray<Maybe<Branch>>>;
  /** Сведения о регистрации в ПФР */
  readonly pfr?: Maybe<Pfr>;
  /** Сведения о регистрации в ФСС */
  readonly fss?: Maybe<Fss>;
  /** Сведения о выданных лицензиях */
  readonly licenses?: Maybe<ReadonlyArray<Maybe<License>>>;
  /** Сведения об изменениях в ЕГРЮЛ */
  readonly records?: Maybe<ReadonlyArray<Maybe<Record>>>;
  /** Сведения об учредителях */
  readonly founders: ReadonlyArray<FounderRelation>;
  /** Организации, в которых данная организация является учредителем */
  readonly property: ReadonlyArray<PropertyRelation>;
  /** Сведения о структуре организации */
  readonly structure: CompanyStructure;
  /** Сведения об исполнительных производствах ФССП */
  readonly enforcementProceedings: EnforcementProceedingInfo;
  /** Сведения об исполнительных производствах ФССП v2 */
  readonly enforcementProceedingsV2: EnforcementProceedingInfoV2;
  /** Сведения ФНС */
  readonly taxation: TaxationData;
  /** Сведения о сертификатах и декларациях */
  readonly certificates: CertificateData;
  /** Бухгалтерская (финансовая) отчетность организации */
  readonly finances?: Maybe<Finance>;
  /** Результаты проверки */
  readonly compliance?: Maybe<Compliance>;
  /** Информация о правопредшественниках */
  readonly predecessors: ReadonlyArray<Company>;
  /** Информация о правопреемниках */
  readonly successors: ReadonlyArray<Company>;
  /** Является ли организация подтвержденной в сервисе Контрагентио */
  readonly isVerified?: Maybe<Scalars['Boolean']>;
  /** Предоставленные организацией сведения */
  readonly verification: Verification;
  /** Данные для заполнения диалогов, для внутреннего пользования */
  readonly verifiedData: VerifiedOrganizationData;
  /** Контактные данные организации из публичных источников */
  readonly contacts: Contacts;
  /** Юридически значимые сообщения */
  readonly publications: LegalFactPublications;
  /** Сведения о проверках государственными органами */
  readonly inspections: InspectionsData;
  /** Сведения о бенефициарных владельцах */
  readonly beneficiaries: Beneficiaries;
  /** Сведения из картотеки арбитражных дел */
  readonly kad: KadData;
  /** Отчеты по компании */
  readonly report: FileInfo;
  /** Выписки */
  readonly extracts: ExtractData;
  /** Данные сторонних ресурсов */
  readonly thirdParty: ThirdPartyData;
};

/** Информация об организации */
export type CompanyFullNameArgs = {
  autoCorrection?: Maybe<Scalars['Boolean']>;
};

/** Информация об организации */
export type CompanyShortNameArgs = {
  autoCorrection?: Maybe<Scalars['Boolean']>;
};

/** Информация об организации */
export type CompanyAddressArgs = {
  autoCorrection?: Maybe<Scalars['Boolean']>;
};

/** Информация об организации */
export type CompanyFoundersArgs = {
  throughStructure?: Maybe<Scalars['Boolean']>;
};

/** Информация об организации */
export type CompanyComplianceArgs = {
  type?: Maybe<ComplianceType>;
};

/** Информация об организации */
export type CompanyPublicationsArgs = {
  allowExpired?: Maybe<Scalars['Boolean']>;
};

/** Информация об организации */
export type CompanyKadArgs = {
  filter: KadDataFilter;
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** Информация об организации */
export type CompanyReportArgs = {
  documentType: ReportDocType;
};

export type CompanyHead = Person | Company;

/** Структура владения организации */
export type CompanyStructure = {
  __typename?: 'CompanyStructure';
  /** Граф структуры владения организации */
  readonly graph?: Maybe<OwnershipGraph>;
  /** Предлагаемое расположение вершин и рёбер графа на плоскости (для отображения) */
  readonly layout?: Maybe<Scalars['OwnershipGraphLayout']>;
};

export type Completion = {
  __typename?: 'Completion';
  /** Дата закрытия */
  readonly date?: Maybe<Scalars['Timestamp']>;
  /** Основание закрытия */
  readonly reason?: Maybe<CompletionReason>;
};

export type CompletionReason = {
  __typename?: 'CompletionReason';
  /** Номер статьи */
  readonly article: Scalars['String'];
  /** Описание статьи */
  readonly quote: Scalars['String'];
};

export type Compliance = {
  __typename?: 'Compliance';
  /** Оценка надежности компании по стобальной шкале */
  readonly score?: Maybe<Scalars['Float']>;
  /** Полностью ли подсчитана оценка надежности */
  readonly certainty: Scalars['Boolean'];
  /** Риски */
  readonly risks: ReadonlyArray<Risk>;
  /** Маркеры */
  readonly markers?: Maybe<ReadonlyArray<Maybe<ComplianceMarker>>>;
};

export type ComplianceMarker = {
  __typename?: 'ComplianceMarker';
  /** Код риска */
  readonly code: Scalars['String'];
  /** Название риска */
  readonly name: Scalars['String'];
  /** Источник риска */
  readonly source: RiskSource;
  /** Дополнительная информация по риску */
  readonly occurrences: ReadonlyArray<RiskOccurrence>;
};

export enum ComplianceType {
  /** Показать результат проверки структуры организации только указанной компании */
  Self = 'SELF',
  /** Показать результат проверки структуры организации всех компаний в структуре капитала */
  Complete = 'COMPLETE',
}

/** Контакты организации */
export type Contact = {
  __typename?: 'Contact';
  /** Сайт */
  readonly website?: Maybe<Scalars['String']>;
  /** Контактный телефон */
  readonly phone?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  readonly email?: Maybe<Scalars['String']>;
};

/** Сведения о контактах ЮЛ */
export type Contacts = {
  __typename?: 'Contacts';
  /** Адреса электронной почты */
  readonly emails?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Контактные телефоны */
  readonly phones?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Сайт */
  readonly website?: Maybe<Scalars['String']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  readonly id: Scalars['String'];
  readonly status: ConversationStatus;
  readonly packageInfo: PackageInfo;
  readonly messages: ReadonlyArray<MessageInfo>;
  readonly latestMessage?: Maybe<MessageInfo>;
};

export type ConversationFilter = {
  readonly id?: Maybe<Scalars['String']>;
  readonly grn?: Maybe<Scalars['String']>;
  readonly type?: Maybe<PackageType>;
  readonly direction?: Maybe<PackageDirectionType>;
  readonly status?: Maybe<ConversationStatusFilter>;
  readonly dialogStatus?: Maybe<PackageStatus>;
};

export type ConversationList = {
  __typename?: 'ConversationList';
  readonly totalCount: Scalars['Int'];
  readonly edges: ReadonlyArray<ConversationNode>;
};

export type ConversationNode = {
  __typename?: 'ConversationNode';
  readonly conversation?: Maybe<Conversation>;
  readonly cursor: Scalars['String'];
};

export type ConversationOperation = {
  __typename?: 'ConversationOperation';
  /** Смена статуса диалога */
  readonly toggleConversationStatus: Scalars['Boolean'];
  /** Создать заявку на диалог */
  readonly createConversation: Scalars['String'];
  /** Изменение состояние диалога */
  readonly action: Scalars['Boolean'];
};

export type ConversationOperationToggleConversationStatusArgs = {
  conversationId: Scalars['String'];
  status: ConversationStatus;
};

export type ConversationOperationCreateConversationArgs = {
  attachments?: Maybe<ReadonlyArray<Maybe<File>>>;
  singlehandedly?: Maybe<Scalars['Boolean']>;
  regulationId?: Maybe<Scalars['String']>;
  from: CreatePackageFrom;
  to?: Maybe<CreatePackageTo>;
  type: PackageType;
};

export type ConversationOperationActionArgs = {
  packageId: Scalars['String'];
  to?: Maybe<MessageTo>;
  message?: Maybe<Scalars['String']>;
  type: ActionType;
};

export enum ConversationStatus {
  Read = 'READ',
  Unread = 'UNREAD',
}

export enum ConversationStatusFilter {
  All = 'ALL',
  Read = 'READ',
  Unread = 'UNREAD',
}

export enum CorpApiType {
  BasicApi = 'BASIC_API',
  ExtendedApi = 'EXTENDED_API',
  MaxApi = 'MAX_API',
}

export type Court = {
  __typename?: 'Court';
  /** Код суда */
  readonly tag: Scalars['String'];
  /** Наименование суда */
  readonly name: Scalars['String'];
};

export type CreatePackageFrom = {
  readonly grn: Scalars['String'];
};

export type CreatePackageTo = {
  /** ГРН */
  readonly grn: Scalars['String'];
  /** Адрес электронной почты */
  readonly email?: Maybe<Scalars['String']>;
  /** Имя */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Фамилия */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Сообщение */
  readonly message?: Maybe<Scalars['String']>;
};

export enum CurrentStatus {
  /** Действующая */
  Active = 'ACTIVE',
  /** Ликвидирована */
  Liquidated = 'LIQUIDATED',
  /** В стадии реорганизации */
  Reorganization = 'REORGANIZATION',
  /** В стадии ликвидации */
  Liquidation = 'LIQUIDATION',
  /** изменение места нахождения */
  ChangeOfLocation = 'CHANGE_OF_LOCATION',
  /** уменьшение уставного капитала */
  AuthorizedCapitalDecrease = 'AUTHORIZED_CAPITAL_DECREASE',
}

export type DebtAmount = {
  __typename?: 'DebtAmount';
  /** Сумма долга */
  readonly due?: Maybe<Scalars['Float']>;
  /** Остаток непогашенной задолженности */
  readonly remaining?: Maybe<Scalars['Float']>;
};

export type Debtor = {
  __typename?: 'Debtor';
  /** Наименование юридического лица */
  readonly name: Scalars['String'];
  /** Адрес организации должника */
  readonly address: Scalars['String'];
};

export type Description = DescriptionPerson | DescriptionCompany;

export type DescriptionCompany = {
  __typename?: 'DescriptionCompany';
  /** Сведения об организации */
  readonly company: Company;
};

export type DescriptionPerson = {
  __typename?: 'DescriptionPerson';
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
  /** Персональные данные */
  readonly person: Person;
};

export enum Destination {
  Counterparty = 'COUNTERPARTY',
  Me = 'ME',
  Unknown = 'UNKNOWN',
}

/** Регламент диалога для определенного ЮЛ */
export type DialogRegulation = {
  __typename?: 'DialogRegulation';
  readonly id: Scalars['String'];
  readonly title: Scalars['String'];
  readonly sections: ReadonlyArray<Scalars['FlexibleSectionObject']>;
};

/** Ссылка на документ */
export type DocLink = {
  __typename?: 'DocLink';
  readonly name: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
  readonly status: PackageDocStatus;
  readonly ext: Scalars['String'];
};

/** Запись данных ФССП */
export type EnforcementProceeding = {
  __typename?: 'EnforcementProceeding';
  /** Номер исполнительного производства */
  readonly number: Scalars['String'];
  /** Дата возбуждения */
  readonly date: Scalars['Timestamp'];
  /** Номер сводного исполнительного производства */
  readonly totalNumber?: Maybe<Scalars['String']>;
  /** Должник */
  readonly debtor: Debtor;
  /** Исполнительный документ */
  readonly executiveDocument?: Maybe<ExecutiveDocument>;
  /** Задолженность */
  readonly debtAmount?: Maybe<DebtAmount>;
  /** Отдел судебных приставов */
  readonly bailiffDepartment?: Maybe<BailiffDepartment>;
  /** Статус судопроизводства */
  readonly proceedingStatus: ProceedingStatus;
  /** Подробности закрытия производства */
  readonly completion?: Maybe<Completion>;
};

/** ФССП */
export type EnforcementProceedingInfo = {
  __typename?: 'EnforcementProceedingInfo';
  /** Список записей ФССП */
  readonly list: ReadonlyArray<EnforcementProceeding>;
  /** Статистика ФССП */
  readonly statistics: EnforcementProceedingStatistic;
};

/** ФССП */
export type EnforcementProceedingInfoV2 = {
  __typename?: 'EnforcementProceedingInfoV2';
  /** Список записей ФССП */
  readonly list: ReadonlyArray<EnforcementProceedingV2>;
  /** Статистика ФССП */
  readonly statistics: EnforcementProceedingStatistic;
};

export type EnforcementProceedingStatistic = {
  __typename?: 'EnforcementProceedingStatistic';
  readonly totalCount: Scalars['Int'];
  readonly openCount: Scalars['Int'];
};

/** Запись данных ФССП */
export type EnforcementProceedingV2 = {
  __typename?: 'EnforcementProceedingV2';
  /** Номер исполнительного производства */
  readonly number: Scalars['String'];
  /** Дата возбуждения */
  readonly date: Scalars['Timestamp'];
  /** Номер сводного исполнительного производства */
  readonly totalNumber?: Maybe<Scalars['String']>;
  /** Должник */
  readonly debtor: Debtor;
  /** Исполнительный документ */
  readonly executiveDocument?: Maybe<ExecutiveDocument>;
  /** Задолженность */
  readonly debtAmount?: Maybe<DebtAmount>;
  /** Отдел судебных приставов */
  readonly bailiffDepartment?: Maybe<BailiffDepartment>;
  /** Статус судопроизводства */
  readonly proceedingStatus: ProceedingStatus;
  /** Подробности закрытия производства */
  readonly completion?: Maybe<Completion>;
  /**
   * Подробности закрытия производства
   * взыскатель-организация
   **/
  readonly collector?: Maybe<Company>;
};

export type Entity = Individual | Company | ForeignOrganization | UnverifiedEntity | Person;

export type ExecutiveDocument = {
  __typename?: 'ExecutiveDocument';
  /** Номер исполнительного документа */
  readonly number?: Maybe<Scalars['String']>;
  /** Дата исполнительного документа */
  readonly date?: Maybe<Scalars['Timestamp']>;
  /** Тип исполнительного документа */
  readonly type?: Maybe<Scalars['String']>;
  /** Требования исполнительного документа */
  readonly object?: Maybe<Scalars['String']>;
  /** Предмет исполнения */
  readonly executionObject?: Maybe<Scalars['String']>;
};

/** Информация о выписке */
export type Extract = {
  __typename?: 'Extract';
  /** Идентификатор выписки */
  readonly id: Scalars['String'];
  /** Текст статуса */
  readonly statusText: Scalars['String'];
  /** Идентификатор статуса */
  readonly statusId: Scalars['String'];
  /** Дата выписки */
  readonly date: Scalars['Timestamp'];
  /** Ссылка на скачивание выписки */
  readonly linkForDownload?: Maybe<Scalars['String']>;
};

export type ExtractData = {
  __typename?: 'ExtractData';
  /** Список выписок */
  readonly list: ReadonlyArray<Extract>;
};

export type ExtractOperation = {
  __typename?: 'ExtractOperation';
  /** Заказ выписки на почту */
  readonly orderByEmail: Scalars['String'];
  /** Заказ выписки */
  readonly order: Scalars['String'];
};

export type ExtractOperationOrderByEmailArgs = {
  grn: Scalars['String'];
  email: Scalars['String'];
};

export type ExtractOperationOrderArgs = {
  grn: Scalars['String'];
};

export type File = {
  readonly data: Scalars['String'];
  readonly name: Scalars['String'];
  readonly extension: Scalars['String'];
  readonly mimeType: Scalars['String'];
};

export type FileInfo = {
  __typename?: 'FileInfo';
  readonly data: Scalars['String'];
  readonly name: Scalars['String'];
  readonly extension: Scalars['String'];
  readonly mimeType: Scalars['String'];
};

export type FilterValue = {
  __typename?: 'FilterValue';
  readonly type?: Maybe<GroupFilterValue>;
  readonly count: Scalars['Long'];
  readonly sum: Scalars['Float'];
};

export type Finance = {
  __typename?: 'Finance';
  readonly pnl?: Maybe<FinanceReport>;
  readonly balanceSheet?: Maybe<FinanceReport>;
  readonly cashflow?: Maybe<FinanceReport>;
  /** Сведения о суммах доходов и расходов по данным бухгалтерской (финансовой) отчётности за календарный год */
  readonly reportCompressed?: Maybe<FinanceReportCompressed>;
};

/** Финансовая отчетность организации */
export type FinanceReport = {
  __typename?: 'FinanceReport';
  /** Табличная структура отчета */
  readonly layout?: Maybe<Scalars['FinanceReportLayout']>;
  /** Значения финансовых показателей */
  readonly values?: Maybe<Scalars['FinanceReportData']>;
};

export type FinanceReportCompressed = {
  __typename?: 'FinanceReportCompressed';
  /** Сумма доходов */
  readonly income: Scalars['Long'];
  /** Сумма расходов */
  readonly expense: Scalars['Long'];
};

/** Иностранная организация */
export type ForeignOrganization = {
  __typename?: 'ForeignOrganization';
  /** Наименование */
  readonly name?: Maybe<Scalars['String']>;
  /** ИНН */
  readonly inn?: Maybe<Scalars['String']>;
  /** Регистрационный номер */
  readonly registrationNumber?: Maybe<Scalars['String']>;
  /** Адрес */
  readonly address?: Maybe<Address>;
};

/** Связь, описывающая владельца организации */
export type FounderRelation = {
  __typename?: 'FounderRelation';
  readonly share?: Maybe<Scalars['Float']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Владелец доли */
  readonly founder: StructureMember;
};

export type Fss = {
  __typename?: 'Fss';
  /** Регистрационный номер ФСС */
  readonly regNumber?: Maybe<Scalars['String']>;
  /** Дата постановки на учет в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Сведения о фонде социального страхования */
  readonly authority?: Maybe<Authority>;
};

export enum FutureStatus {
  /** Действующая */
  Active = 'ACTIVE',
  /** Ликвидирована */
  Liquidated = 'LIQUIDATED',
}

export enum GroupFilterType {
  Side = 'SIDE',
  Category = 'CATEGORY',
  Year = 'YEAR',
  Result = 'RESULT',
}

export type GroupFilterValue = KadFilterCategory | KadFilterResult | KadFilterYear | KadFilterSide;

export type GroupStatistic = {
  __typename?: 'GroupStatistic';
  readonly key?: Maybe<GroupFilterType>;
  readonly value: ReadonlyArray<FilterValue>;
};

export type HeadRelation = {
  __typename?: 'HeadRelation';
  /** Дата регистрации в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
  /** Код */
  readonly code?: Maybe<Scalars['String']>;
  /** Контактный телефон */
  readonly phone?: Maybe<Scalars['String']>;
  /** Дополнительная информация о руководителе */
  readonly head: CompanyHead;
};

/** hh.ru */
export type HhData = {
  __typename?: 'HhData';
  readonly openVacancies: Scalars['Int'];
  readonly updated: Scalars['ZDateTime'];
  readonly companyUrl: Scalars['String'];
  readonly certainty: Scalars['Boolean'];
};

/** Информация об ИП */
export type Individual = {
  __typename?: 'Individual';
  /** ИНН */
  readonly inn?: Maybe<Scalars['String']>;
  /** ОГРНИП */
  readonly ogrnip: Scalars['String'];
  /** ОКПО */
  readonly okpo?: Maybe<Scalars['String']>;
  /** Фамилия */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Имя */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Отчество */
  readonly middleName?: Maybe<Scalars['String']>;
  /** Пол */
  readonly gender?: Maybe<Scalars['String']>;
  /** Вид предпринимательства */
  readonly type?: Maybe<Scalars['String']>;
  /** Гражданство */
  readonly citizenship?: Maybe<Scalars['String']>;
  /** Дата регистрации */
  readonly registrationDate?: Maybe<Scalars['Timestamp']>;
  /** Дата прекращения деятельности */
  readonly terminationDate?: Maybe<Scalars['Timestamp']>;
  /** Статус */
  readonly status?: Maybe<Status>;
  /** Сведения о налоговом органе, в котором ИП состоит на учете */
  readonly taxInspection?: Maybe<TaxInspection>;
  /** Сведения о регистрирующем органе по месту нахождения ИП */
  readonly registration?: Maybe<Registration>;
  /** Организационно-правовая форма */
  readonly form?: Maybe<OrganizationForm>;
  /** Адрес */
  readonly address?: Maybe<Address>;
  /** Список ОКВЭД */
  readonly okveds?: Maybe<ReadonlyArray<Okved>>;
  /** Сведения о регистрации в ПФР */
  readonly pfr?: Maybe<Pfr>;
  /** Сведения о регистрации в ФСС */
  readonly fss?: Maybe<Fss>;
  /** Сведения о выданных лицензиях */
  readonly licenses?: Maybe<ReadonlyArray<Maybe<License>>>;
  /** Сведения об изменениях в ЕГРИП */
  readonly records?: Maybe<ReadonlyArray<Maybe<Record>>>;
  /** Сведения ФНС */
  readonly taxation: TaxationData;
  /** Сведения о сертификатах и декларациях */
  readonly certificates: CertificateData;
  /** Является ли организация подтвержденной в сервисе Контрагентио */
  readonly isVerified?: Maybe<Scalars['Boolean']>;
  /** Предоставленные организацией сведения */
  readonly verification: Verification;
  /** Данные для заполнения диалогов, для внутреннего пользования */
  readonly verifiedData: VerifiedOrganizationData;
  /** Контактные данные организации из публичных источников */
  readonly contacts: Contacts;
  /** Юридически значимые сообщения */
  readonly publications: LegalFactPublications;
  /** Персональные данные ИП */
  readonly personalData: IndividualPerson;
  /** Сведения о проверках государственными органами */
  readonly inspections: InspectionsData;
  /** Сведения из картотеки арбитражных дел */
  readonly kad: KadData;
  /** Выписки */
  readonly extracts: ExtractData;
  /** Результаты проверки */
  readonly compliance?: Maybe<Compliance>;
  /** Данные сторонних ресурсов */
  readonly thirdParty: ThirdPartyData;
};

/** Информация об ИП */
export type IndividualAddressArgs = {
  autoCorrection?: Maybe<Scalars['Boolean']>;
};

/** Информация об ИП */
export type IndividualPublicationsArgs = {
  allowExpired?: Maybe<Scalars['Boolean']>;
};

/** Информация об ИП */
export type IndividualKadArgs = {
  filter: KadDataFilter;
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** Информация об ИП */
export type IndividualComplianceArgs = {
  type?: Maybe<ComplianceType>;
};

/** Персональные данные ИП */
export type IndividualPerson = {
  __typename?: 'IndividualPerson';
  /** СНИЛС */
  readonly snils?: Maybe<Scalars['String']>;
  /** Дата рождения */
  readonly birthDate?: Maybe<Scalars['Timestamp']>;
  /** Место рождения */
  readonly birthPlace?: Maybe<Scalars['String']>;
  /** Адрес физического лица */
  readonly address?: Maybe<Scalars['String']>;
};

/** Информация об инициаторе */
export type InitiatorInfo = {
  __typename?: 'InitiatorInfo';
  /** Организация */
  readonly organization: LegalEntity;
  /** Имя */
  readonly name: Scalars['String'];
  /** Получатель */
  readonly destination: Destination;
};

export type Inspection = {
  __typename?: 'Inspection';
  /** Идентификатор дела */
  readonly id: Scalars['String'];
  /**
   * Учетный номер КНМ в соответствии с постановлением Правительства РФ от 28
   * апреля 2015 г. N 415 "О Правилах формирования и ведения единого реестра проверок"
   **/
  readonly erpId: Scalars['String'];
  /** Результат КНМ */
  readonly status: InspectionState;
  /** Форма проведения КНМ */
  readonly type?: Maybe<InspectionType>;
  /** Тип КНМ */
  readonly schedule: InspectionSchedule;
  /** Дата начала проведения КНМ в формате Unix Timestamp */
  readonly startDate?: Maybe<Scalars['Timestamp']>;
  /** Дата окончания проведения КНМ в формате Unix Timestamp */
  readonly endDate?: Maybe<Scalars['Timestamp']>;
  /** Наименование органов контроля (надзора) из Федерального реестра государственных и муниципальных услуг проводящего КНМ */
  readonly authorities?: Maybe<ReadonlyArray<Maybe<Company>>>;
  /** Цель КНМ */
  readonly purpose?: Maybe<Scalars['String']>;
  /** Причины проведения КНМ */
  readonly reasons?: Maybe<ReadonlyArray<Maybe<InspectionReason>>>;
  /** Объекты проведения КНМ */
  readonly objects?: Maybe<ReadonlyArray<Maybe<InspectionObject>>>;
};

/** Предписания */
export type InspectionInjunctions = {
  __typename?: 'InspectionInjunctions';
  /** Реквизиты предписания об устранении выявленных нарушений в результате проведения КНМ */
  readonly number?: Maybe<Scalars['String']>;
  /** Отметка о невыполнении предписания об устранении выявленных нарушений в результате проведения КНМ */
  readonly isViolated?: Maybe<Scalars['Boolean']>;
  /** Дата вынесения предписания об устранении выявленных нарушений в результате проведения КНМ в формате Unix Timestamp */
  readonly creationDate?: Maybe<Scalars['Timestamp']>;
  /** Дата окончания срока для выполения вынесенного предписания в формате Unix Timestamp */
  readonly deadlineDate?: Maybe<Scalars['Timestamp']>;
};

export type InspectionObject = {
  __typename?: 'InspectionObject';
  /** Тип адреса места составления акта о проведении КНМ */
  readonly type?: Maybe<Scalars['String']>;
  /** Адрес объекта проведения КНМ */
  readonly address?: Maybe<Scalars['String']>;
  /** Нарушения */
  readonly violations?: Maybe<ReadonlyArray<Maybe<InspectionViolation>>>;
};

export enum InspectionOrgType {
  /** МЧС */
  Mchs = 'MCHS',
  /** МВД - Министерство внутренних дел Российской Федерации */
  Mvd = 'MVD',
  /** Роспотребнадзор - Федеральная служба по надзору в сфере защиты прав потребителей и благополучия человека */
  Rospotrebnadzor = 'ROSPOTREBNADZOR',
  /** Ростехнадзор - Федеральная служба по экологическому технологическому и атомному надзору */
  Gosnadzor = 'GOSNADZOR',
  /** Роструд - Федеральная служба по труду и занятости */
  Rostrud = 'ROSTRUD',
  /** Росреестр - Федеральная служба государственной регистрации кадастра и картографии */
  Rosreestr = 'ROSREESTR',
  /** Росприроднадзор - Федеральная служба по надзору в сфере природопользования */
  Rpn = 'RPN',
  /** Рослесхоз - Федеральное агентство лесного хозяйства */
  Rosleshoz = 'ROSLESHOZ',
  /** Ространснадзор - Федеральная служба по надзору в сфере транспорта */
  Rostransnadzor = 'ROSTRANSNADZOR',
  /** Россельхознадзор - Федеральная служба по ветеринарному и фитосанитарному надзору */
  Fsvps = 'FSVPS',
  /** Росздравнадзор - Федеральная служба по надзору в сфере здравоохранения */
  Roszdravnadzor = 'ROSZDRAVNADZOR',
  /** Министерство юстиции Российской Федерации */
  Minjust = 'MINJUST',
  /** Роскомнадзор - Федеральная служба по надзору в сфере связи информационных технологий и массовых коммуникаций */
  Roskomnadzor = 'ROSKOMNADZOR',
  /** ГИБДД Госавтоинспекция */
  Gibdd = 'GIBDD',
  /** Федеральное медико-биологическое агентство */
  Fmbaros = 'FMBAROS',
  /** Другие органы контроля */
  Other = 'OTHER',
}

export type InspectionOrgTypeStats = {
  __typename?: 'InspectionOrgTypeStats';
  /** Проверяющая организация */
  readonly name: Scalars['String'];
  /** Количество КНМ */
  readonly count: Scalars['Int'];
};

/** Причины проведения КНМ */
export type InspectionReason = {
  __typename?: 'InspectionReason';
  /** Наименование основания проведения КНМ */
  readonly title?: Maybe<Scalars['String']>;
  /** Основания проведения КНМ */
  readonly text?: Maybe<Scalars['String']>;
};

export enum InspectionSchedule {
  /** Плановая проверка */
  Scheduled = 'SCHEDULED',
  /** Внеплановая проверка */
  Unscheduled = 'UNSCHEDULED',
  /** Контрольная закупка */
  ControlledPurchase = 'CONTROLLED_PURCHASE',
}

export type InspectionScheduleStats = {
  __typename?: 'InspectionScheduleStats';
  /** Тип КНМ */
  readonly name: InspectionSchedule;
  /** Количество КНМ */
  readonly count: Scalars['Int'];
};

export type InspectionsData = {
  __typename?: 'InspectionsData';
  /** Данные по контрольно-надзорным мероприятиям (КНМ) организации */
  readonly list: ReadonlyArray<Inspection>;
  /** Статистика по контрольно-надзорным мероприятиям (КНМ) организации */
  readonly statistics: InspectionStatistics;
};

export type InspectionsDataListArgs = {
  filter?: Maybe<InspectionsFilter>;
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type InspectionsDataStatisticsArgs = {
  filter?: Maybe<InspectionsFilter>;
};

export type InspectionsFilter = {
  /** Год проведения КНМ */
  readonly years?: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>;
  /** Результаты КНМ */
  readonly statuses?: Maybe<ReadonlyArray<Maybe<InspectionState>>>;
  /** Формы проведения КНМ */
  readonly types?: Maybe<ReadonlyArray<Maybe<InspectionType>>>;
  /** Типы КНМ */
  readonly schedules?: Maybe<ReadonlyArray<Maybe<InspectionSchedule>>>;
  /** Организации, проводящие проверку */
  readonly orgTypes?: Maybe<ReadonlyArray<Maybe<InspectionOrgType>>>;
};

export enum InspectionState {
  /** Предстоящая проверка */
  Planned = 'PLANNED',
  /** Нарушения не выявлены */
  NoViolations = 'NO_VIOLATIONS',
  /** Нарушения выявлены */
  ViolationsIdentified = 'VIOLATIONS_IDENTIFIED',
  /** Результат неизвестен */
  Unknown = 'UNKNOWN',
}

export type InspectionStateStats = {
  __typename?: 'InspectionStateStats';
  /** Результат КНМ */
  readonly name: InspectionState;
  /** Количество КНМ */
  readonly count: Scalars['Int'];
};

export type InspectionStatistics = {
  __typename?: 'InspectionStatistics';
  /** Общее количество КНМ */
  readonly totalCount: Scalars['Long'];
  /** Количество КНМ, отсортированное по годам */
  readonly years: ReadonlyArray<Maybe<InspectionYearStats>>;
  /** Количество КНМ, отсортированное по результатам проверки */
  readonly statuses: ReadonlyArray<Maybe<InspectionStateStats>>;
  /** Количество КНМ, отсортированное по форме проведения */
  readonly types: ReadonlyArray<Maybe<InspectionTypeStats>>;
  /** Количество КНМ, отсортированное по типу проверки */
  readonly schedules: ReadonlyArray<Maybe<InspectionScheduleStats>>;
  /** Количество КНМ, отсортированное по проверяющим организациям */
  readonly orgTypes: ReadonlyArray<Maybe<InspectionOrgTypeStats>>;
};

export enum InspectionType {
  /** Выездная */
  Field = 'FIELD',
  /** Документарная */
  Documentary = 'DOCUMENTARY',
  /** Выездная и документарная */
  FieldDocumentary = 'FIELD_DOCUMENTARY',
  /** Неизвестно */
  Unknown = 'UNKNOWN',
}

export type InspectionTypeStats = {
  __typename?: 'InspectionTypeStats';
  /** Форма проведения КНМ */
  readonly name: InspectionType;
  /** Количество КНМ */
  readonly count: Scalars['Int'];
};

/** Нарушения */
export type InspectionViolation = {
  __typename?: 'InspectionViolation';
  /** Характер выявленного нарушения */
  readonly type?: Maybe<Scalars['String']>;
  /** Описание нарушения */
  readonly text?: Maybe<Scalars['String']>;
  /** Предписания */
  readonly injunctions: ReadonlyArray<InspectionInjunctions>;
};

export type InspectionYearStats = {
  __typename?: 'InspectionYearStats';
  /** Год проверок */
  readonly year: Scalars['Int'];
  /** Количество КНМ */
  readonly count: Scalars['Int'];
};

export enum InstanceType {
  /** Первая инстанция */
  First = 'FIRST',
  /** Апелляционная инстанция */
  Appellate = 'APPELLATE',
  /** Кассационная инстанция */
  Cassation = 'CASSATION',
  /** Надзорная инстанция */
  Supervisory = 'SUPERVISORY',
}

export type Kad = {
  __typename?: 'Kad';
  /** Идентификатор карточки дела в картотеке арбитражных дел */
  readonly kadId: Scalars['String'];
  /** Исход дела */
  readonly result: KadResult;
  /** Исход дела */
  readonly outcome: KadResultType;
  /** Стороны дела */
  readonly sides: ReadonlyArray<KadSide>;
  /** Категория дела */
  readonly category: KadCategory;
  readonly subcategory: KadSubcategory;
  /** Дело найдено по ИНН организации */
  readonly isCertain: Scalars['Boolean'];
  /** Инстанции */
  readonly instances: ReadonlyArray<KadInstance>;
};

export type KadCase = {
  __typename?: 'KadCase';
  /** Номер дела в картотеке арбитражных дел */
  readonly number: Scalars['String'];
  /** Время подачи заявления в формате Unix Timestamp */
  readonly date: Scalars['Timestamp'];
  /** Первоначальная сумма претензии в рублях */
  readonly initialClaimSum?: Maybe<Scalars['Float']>;
};

export type KadCategory = {
  __typename?: 'KadCategory';
  /** Код категории дела */
  readonly code: KadCategoryType;
  /** Название категории дела */
  readonly name: Scalars['String'];
};

export enum KadCategoryType {
  /** Административное */
  Administrative = 'ADMINISTRATIVE',
  /** Гражданское */
  Civil = 'CIVIL',
  /** Банкротство, рассматриваемое в упрощенном порядке */
  BankruptcySimple = 'BANKRUPTCY_SIMPLE',
  /** Банкротство */
  Bankruptcy = 'BANKRUPTCY',
  /** Гражданское, рассматриваемое в упрощенном порядке */
  CivilSimple = 'CIVIL_SIMPLE',
  /** Административное, рассматриваемое в упрощенном порядке */
  AdministrativeSimple = 'ADMINISTRATIVE_SIMPLE',
  /** Другие дела */
  Other = 'OTHER',
}

export type KadData = {
  __typename?: 'KadData';
  /** Время последнего обновления в формате Unix Timestamp */
  readonly updatedAt: Scalars['Timestamp'];
  readonly edges: ReadonlyArray<KadNode>;
  /** Статистика по арбитражным делам в организации */
  readonly statistics: KadStatistics;
};

export type KadDataFilter = {
  readonly id?: Maybe<Scalars['String']>;
  readonly caseNumber?: Maybe<Scalars['String']>;
  readonly sideType: KadSideType;
  readonly categories?: Maybe<ReadonlyArray<KadCategoryType>>;
  readonly results?: Maybe<ReadonlyArray<KadResultType>>;
  readonly years?: Maybe<ReadonlyArray<Scalars['Int']>>;
};

export type KadDocument = {
  __typename?: 'KadDocument';
  /** Дата публикации документа в формате Unix Timestamp */
  readonly date: Scalars['Timestamp'];
  /** Флаг, показывающий является ли документ решением по инстанции */
  readonly isCourtDecision: Scalars['Boolean'];
  /** Тип документа */
  readonly type: Scalars['String'];
  readonly contentTypes: ReadonlyArray<Scalars['String']>;
  /** Адрес файла для скачивания */
  readonly fileUrl?: Maybe<Scalars['String']>;
  /** Имя файла */
  readonly fileName?: Maybe<Scalars['String']>;
  /** Дата и время судебного заседания в формате Unix Timestamp */
  readonly hearingDate: Scalars['Timestamp'];
};

export type KadFilterCategory = {
  __typename?: 'KadFilterCategory';
  readonly value: KadCategoryType;
};

export type KadFilterResult = {
  __typename?: 'KadFilterResult';
  readonly value: KadResultType;
};

export type KadFilterSide = {
  __typename?: 'KadFilterSide';
  readonly value: KadSideType;
};

export type KadFilterYear = {
  __typename?: 'KadFilterYear';
  readonly value: Scalars['Int'];
};

export type KadInstance = {
  __typename?: 'KadInstance';
  /** Идентификатор дела в инстанции */
  readonly id: Scalars['String'];
  /** Указатель того, в какой инстанции рассматривается дело */
  readonly isCurrent: Scalars['Boolean'];
  /** Результат дела */
  readonly result: KadResult;
  /** Результат дела */
  readonly outcome: KadResultType;
  /** Вид судебной инстанции */
  readonly type: InstanceType;
  /** Суд, рассматривающий дело */
  readonly court: Court;
  /** Сведения об арбитражном деле */
  readonly suit: KadCase;
  /** Документы */
  readonly documents: ReadonlyArray<KadDocument>;
};

export type KadNode = {
  __typename?: 'KadNode';
  readonly kad: Kad;
  /** Идентификатор карточки дела в картотеке арбитражных дел */
  readonly kadId: Scalars['String'];
  /** Исход дела */
  readonly outcome: KadResultType;
  /** Ход рассмотрения дела */
  readonly caseProgress: Scalars['String'];
  /** Стороны дела */
  readonly sides: ReadonlyArray<KadSide>;
  /** Категория дела */
  readonly category: KadCategory;
  readonly subcategory: KadSubcategory;
  /** Дело найдено по ИНН организации */
  readonly isCertain: Scalars['Boolean'];
  /** Инстанции */
  readonly instances: ReadonlyArray<KadInstance>;
  /** Курсор пагинации */
  readonly cursor: Scalars['String'];
};

export type KadResult = {
  __typename?: 'KadResult';
  /** Код исхода дела */
  readonly code: KadResultType;
  /** Флаг частичности исхода дела */
  readonly partially: Scalars['Boolean'];
};

export enum KadResultType {
  /** Удовлетворено */
  Satisfied = 'SATISFIED',
  /** Удовлетворено частично */
  SatisfiedPartially = 'SATISFIED_PARTIALLY',
  /** Проиграно */
  Dismissed = 'DISMISSED',
  /** Прекращено */
  Terminated = 'TERMINATED',
  /** Оставлено без рассмотрения */
  WithoutConsidering = 'WITHOUT_CONSIDERING',
  /** Рассматривается */
  Pending = 'PENDING',
  /** Неизвестно */
  Unknown = 'UNKNOWN',
}

export type KadSide = {
  __typename?: 'KadSide';
  /** Идентификатор карточки участника дела в картотеке арбитражных дел */
  readonly kadId: Scalars['String'];
  /** Тип стороны */
  readonly type: KadSideType;
  /** Юридическое лицо */
  readonly entity: Entity;
};

export enum KadSideType {
  /** Истец */
  Plaintiff = 'PLAINTIFF',
  /** Ответчик */
  Respondent = 'RESPONDENT',
  /** Третье лицо */
  Third = 'THIRD',
  /** Другой участник */
  Other = 'OTHER',
}

export type KadStatistics = {
  __typename?: 'KadStatistics';
  /** Общее количество арбитражных дел */
  readonly totalCount: Scalars['Long'];
  readonly groups: ReadonlyArray<GroupStatistic>;
};

export type KadSubcategory = {
  __typename?: 'KadSubcategory';
  readonly code: Scalars['String'];
  readonly name: Scalars['String'];
};

export type LastModification = {
  __typename?: 'LastModification';
  /** Дата последней модификации в формате Unix Timestamp */
  readonly age?: Maybe<Scalars['Timestamp']>;
  /** Информация о совершившем операцию пользователе */
  readonly user: UserInfo;
};

export type LeadOperation = {
  __typename?: 'LeadOperation';
  readonly create: Scalars['Boolean'];
};

export type LeadOperationCreateArgs = {
  additionalInfo?: Maybe<Scalars['String']>;
  from: LeadSource;
  email: Scalars['String'];
};

export enum LeadSource {
  BankLanding = 'BANK_LANDING',
  SmthElse = 'SMTH_ELSE',
  CustomRegulationPromo = 'CUSTOM_REGULATION_PROMO',
  DialogLanding = 'DIALOG_LANDING',
  KdmLanding = 'KDM_LANDING',
}

export type LegalEntity = Individual | Company;

/** сведения о банкротстве */
export type LegalFactBankruptcy = {
  __typename?: 'LegalFactBankruptcy';
  /** номер судебного дела */
  readonly caseNumber?: Maybe<Scalars['String']>;
};

export type LegalFactCount = {
  __typename?: 'LegalFactCount';
  /** возможно загрузить из источника */
  readonly total?: Maybe<Scalars['Int']>;
  /** загружено из источника */
  readonly local?: Maybe<Scalars['Int']>;
};

export type LegalFactFilter = {
  readonly type?: Maybe<LegalFactType>;
};

export type LegalFactInfo = LegalFactPledge;

export type LegalFactMessageDoc = {
  __typename?: 'LegalFactMessageDoc';
  readonly name: Scalars['String'];
  readonly hash: Scalars['String'];
};

export type LegalFactNotary = {
  __typename?: 'LegalFactNotary';
  readonly name: Scalars['String'];
  readonly title: Scalars['String'];
};

export type LegalFactPledge = {
  __typename?: 'LegalFactPledge';
  readonly subjects: ReadonlyArray<LegalFactPledgeSubject>;
  readonly mortgagors: ReadonlyArray<Entity>;
  readonly pledgees: ReadonlyArray<Entity>;
  readonly messageDocList: ReadonlyArray<LegalFactMessageDoc>;
  readonly pledgeBond?: Maybe<LegalFactPledgeBond>;
};

export type LegalFactPledgeBond = {
  __typename?: 'LegalFactPledgeBond';
  readonly regNum: Scalars['String'];
  readonly regDate: Scalars['Timestamp'];
};

/** субьект */
export type LegalFactPledgeSubject = {
  __typename?: 'LegalFactPledgeSubject';
  readonly classifierCode?: Maybe<Scalars['String']>;
  readonly classifierName?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly itemId?: Maybe<Scalars['String']>;
};

/** набор сообщений и сопутсвуюшая информация */
export type LegalFactPublications = {
  __typename?: 'LegalFactPublications';
  readonly updatedAt: Scalars['Timestamp'];
  readonly isLoading: Scalars['Boolean'];
  readonly isExpired: Scalars['Boolean'];
  readonly messages: LegalFactPublicationsList;
};

/** набор сообщений и сопутсвуюшая информация */
export type LegalFactPublicationsMessagesArgs = {
  filter?: Maybe<LegalFactFilter>;
  after?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
};

/** сообщение (факт) */
export type LegalFactPublicationsEntity = {
  __typename?: 'LegalFactPublicationsEntity';
  /** внутренний ID */
  readonly id: Scalars['String'];
  /** Юридический номер */
  readonly number: Scalars['String'];
  /** внешний ID */
  readonly externalId: Scalars['String'];
  /** ссылка на fedresurs */
  readonly url: Scalars['String'];
  /** дата публикации */
  readonly publicationDate: Scalars['Timestamp'];
  /** аннулированл */
  readonly isAnnuled: Scalars['Boolean'];
  /** оспаривается */
  readonly isRefuted: Scalars['Boolean'];
  readonly isLocked: Scalars['Boolean'];
  /** текст */
  readonly title: Scalars['String'];
  /** подробный текст */
  readonly text?: Maybe<Scalars['String']>;
  /** тип сообщения */
  readonly factType: LegalFactType;
  /** информация о публикаторе */
  readonly publisher: LegalFactPublisher;
  /** данные по участвующему нотариусу */
  readonly notary?: Maybe<LegalFactNotary>;
  /** данные по банкротству */
  readonly bankruptcy?: Maybe<LegalFactBankruptcy>;
  /** id записи */
  readonly objectId: Scalars['String'];
  /** подробная информация о факте */
  readonly info?: Maybe<LegalFactInfo>;
};

/** врапер набора данных по публикациям */
export type LegalFactPublicationsList = {
  __typename?: 'LegalFactPublicationsList';
  readonly count: LegalFactCount;
  readonly data?: Maybe<ReadonlyArray<LegalFactPublicationsEntity>>;
};

/** сведения о публикаторе сообщения */
export type LegalFactPublisher = {
  __typename?: 'LegalFactPublisher';
  readonly name: Scalars['String'];
  readonly entity?: Maybe<LegalPublisherEntity>;
  readonly publisherType: LegalFactPublisherType;
};

/** тип публикатора */
export enum LegalFactPublisherType {
  /** не удалось опеределить */
  Unknown = 'UNKNOWN',
  /** скрыто по постановлениею правительства */
  LegallyHidden = 'LEGALLY_HIDDEN',
  /** ЮЛ */
  Company = 'COMPANY',
  /** ИП */
  Individual = 'INDIVIDUAL',
  /** ЕГРЮЛ */
  LegalRegistry = 'LEGAL_REGISTRY',
  /** ФЛ */
  Person = 'PERSON',
  /** Иностранная компания */
  ForeignCompany = 'FOREIGN_COMPANY',
  /** Организатор торгов компания */
  FirmTradeOrganizerAgent = 'FIRM_TRADE_ORGANIZER_AGENT',
  /** арбитражный управляющий */
  ArbitrManager = 'ARBITR_MANAGER',
  /** ЦБ РФ */
  CentralBank = 'CENTRAL_BANK',
  /** Организатор торгов ФЛ */
  PersonTradeOrganizer = 'PERSON_TRADE_ORGANIZER',
  /** Саморегулируемая организация арбитражных управляющих */
  SroArbitrManager = 'SRO_ARBITR_MANAGER',
  /** Инспекция Федеральной налоговой службы */
  TaxOffice = 'TAX_OFFICE',
  /** АСВ */
  DepositInsuranceAgency = 'DEPOSIT_INSURANCE_AGENCY',
  /** Оператор ЕФРСБ */
  EfrsbOpeartor = 'EFRSB_OPEARTOR',
}

export enum LegalFactType {
  /** существенный факт (id=1) */
  SignificantFact = 'SIGNIFICANT_FACT',
  /** сообщение о банкротстве (id=2,3) */
  Bankruptcy = 'BANKRUPTCY',
}

export type LegalPublisherEntity = Individual | Company | Person | ForeignOrganization;

export type License = {
  __typename?: 'License';
  /** Номер лицензии */
  readonly number?: Maybe<Scalars['String']>;
  /** Дата выдачи лицензии в формате Unix Timestamp */
  readonly licenseDate?: Maybe<Scalars['Timestamp']>;
  /** Дата начала действия лицензии в формате Unix Timestamp */
  readonly startDate?: Maybe<Scalars['Timestamp']>;
  /** Дата окончания действия лицензии в формате Unix Timestamp */
  readonly endDate?: Maybe<Scalars['Timestamp']>;
  /** Лицензированные виды деятельности */
  readonly activities?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Регион действия лицензии */
  readonly locations?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Организация, выдавшая лицензию */
  readonly licenser?: Maybe<Scalars['String']>;
};

export type LogMetadata = {
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type LogResponse = {
  __typename?: 'LogResponse';
  readonly acknowledged: Scalars['Boolean'];
};

export enum LogType {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
  Event = 'EVENT',
}

export type MessageAddress = {
  __typename?: 'MessageAddress';
  readonly userInfo?: Maybe<UserInfo>;
  readonly organization: LegalEntity;
  readonly destination: Destination;
};

/** Сообщения */
export type MessageInfo = {
  __typename?: 'MessageInfo';
  /** Идентификатор */
  readonly id: Scalars['String'];
  /** Текст сообщения */
  readonly text: Scalars['String'];
  /** Заголовок сообщения */
  readonly type: MessageType;
  /** Кому адресовано сообщение */
  readonly to: MessageAddress;
  /** От кого */
  readonly from: MessageAddress;
  /** Время сообщения в формете Unix Timestamp */
  readonly timestamp: Scalars['Timestamp'];
  /** Ссылки на превью отправляемых документов */
  readonly attachments?: Maybe<ReadonlyArray<Maybe<DocLink>>>;
};

export type MessageTo = {
  /** Идентификатор пользователя */
  readonly userId: Scalars['String'];
  /** ГРН */
  readonly grn: Scalars['String'];
};

export enum MessageType {
  /** Запросить пакет */
  RequestDocs = 'REQUEST_DOCS',
  /** Отправить пакет */
  SendDocs = 'SEND_DOCS',
  /** Запросить изменения */
  RequestChanges = 'REQUEST_CHANGES',
  /** Переслать своему сотруднику */
  Forward = 'FORWARD',
  /** Утвердить */
  Approve = 'APPROVE',
  /** Отклонить запрос */
  RejectDocsRequest = 'REJECT_DOCS_REQUEST',
  /** Напомнить */
  Reminder = 'REMINDER',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Выписки */
  readonly extract?: Maybe<ExtractOperation>;
  /** Управление аккаунтом */
  readonly account?: Maybe<AccountOperation>;
  /** Аутентификация */
  readonly authentication?: Maybe<AuthenticationOperation>;
  /** Подписки */
  readonly subscription?: Maybe<SubscriptionOperation>;
  /** Пакет документов */
  readonly package?: Maybe<PackageOperation>;
  /** Пакет документов */
  readonly conversation?: Maybe<ConversationOperation>;
  /** ФЗ-115 */
  readonly report: ReportOperation;
  /** Биллинг */
  readonly billing: BillingOperation;
  readonly lead?: Maybe<LeadOperation>;
  readonly system: SystemOperation;
};

export type MyInfo = {
  __typename?: 'MyInfo';
  /** Список выданных прав аккаунта */
  readonly authorities: ReadonlyArray<AuthorityInformation>;
  /** Список присвоенных ролей аккаунта */
  readonly roles: ReadonlyArray<RoleInformation>;
  /** Информация об аккаунте */
  readonly info: AccountInformation;
  /** Подчинённые */
  readonly colleagues: ReadonlyArray<Maybe<AccountInformation>>;
  /** Пакет документов отправленный на подтверждение */
  readonly confirmations: ReadonlyArray<Maybe<PackageInfo>>;
};

/** Вершина в структуре владения организации */
export type Node = {
  __typename?: 'Node';
  /** Идентификатор вершины в графе */
  readonly structureId?: Maybe<Scalars['String']>;
  /** Информация о вершине */
  readonly member?: Maybe<StructureMember>;
};

export type Occurrence = {
  __typename?: 'Occurrence';
  /** Степень риска */
  readonly severity?: Maybe<SeverityType>;
  /** Местонахождение конторы в цепочке по отношению к той, к которой запрошен риск */
  readonly relation: Scalars['String'];
  /** Информация о юридическом лице */
  readonly entity: Company;
  /** Данные по риску */
  readonly description?: Maybe<Description>;
  /** Дата возникновения риска в формате Unix Timestamp */
  readonly date: Scalars['Timestamp'];
  /** Информация из источника риска */
  readonly entry?: Maybe<Scalars['String']>;
  /** Количество месяцев с момента регистрации компании, если ей меньше одного года */
  readonly age?: Maybe<Scalars['Int']>;
  /** Количество */
  readonly count?: Maybe<Scalars['Int']>;
  /** Сумма долга */
  readonly amount?: Maybe<Scalars['Float']>;
  /** Доля в уставном капитале принадлежит организации */
  readonly share?: Maybe<Scalars['Float']>;
};

export enum OfficeType {
  /** Представительство */
  Representative = 'REPRESENTATIVE',
  /** Подразделение */
  Branch = 'BRANCH',
}

export type Okved = {
  __typename?: 'Okved';
  /** Код ОКВЭД */
  readonly code?: Maybe<Scalars['String']>;
  /** Описание */
  readonly description?: Maybe<Scalars['String']>;
  /** Дата регистрации ОКВЭД */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Флаг, указывающий является ли данный ОКВЭД основным */
  readonly isPrimary?: Maybe<Scalars['Boolean']>;
};

export type OrganizationData = {
  __typename?: 'OrganizationData';
  readonly organizations?: Maybe<ReadonlyArray<Maybe<OrganizationInformation>>>;
};

export type OrganizationForm = {
  __typename?: 'OrganizationForm';
  /** Сокращённое наименование ОПФ */
  readonly compact?: Maybe<Scalars['String']>;
  /** Полное наименование ОПФ */
  readonly full?: Maybe<Scalars['String']>;
  /** Код ОПФ */
  readonly code?: Maybe<Scalars['String']>;
};

export type OrganizationInformation = {
  __typename?: 'OrganizationInformation';
  /** Юридическое лицо */
  readonly entity?: Maybe<LegalEntity>;
  /** Дата подтверждения организации в формате Unix Timestamp */
  readonly date: Scalars['Timestamp'];
};

export type OrganizationPermission = {
  /** ГРН */
  readonly grn: Scalars['String'];
  /** Права на организацию */
  readonly permission: OrganizationPermissionType;
};

export enum OrganizationPermissionType {
  /** Нет прав */
  None = 'NONE',
  /** Все права */
  All = 'ALL',
}

/** Граф структуры владения организации */
export type OwnershipGraph = {
  __typename?: 'OwnershipGraph';
  /** Вершины */
  readonly nodes: ReadonlyArray<Node>;
  /** Связи */
  readonly edges: ReadonlyArray<ArbitratyGraphRelation>;
};

export enum PackageDirectionType {
  /** Входящий */
  Incoming = 'INCOMING',
  /** Исходящий */
  Outgoing = 'OUTGOING',
}

export enum PackageDocStatus {
  Ready = 'READY',
  Progress = 'PROGRESS',
}

/** Пакет документов */
export type PackageInfo = {
  __typename?: 'PackageInfo';
  /** Идентификатор */
  readonly id: Scalars['String'];
  /** Типа пакета */
  readonly type: PackageType;
  /** Последнее изменение */
  readonly lastModification: LastModification;
  /** Данные */
  readonly data?: Maybe<Scalars['FlexiblePackageObject']>;
  /** Данные об инициаторе */
  readonly initiator?: Maybe<InitiatorInfo>;
  /** Данные об отправителе */
  readonly submitter?: Maybe<SubmitterInfo>;
  /** Данные о получателе */
  readonly receiver?: Maybe<ReceiverInfo>;
  /** Флаг, показыающий является ли пакет документов присвоенным пользователю */
  readonly isSeized: Scalars['Boolean'];
  /** Права на пакет документов */
  readonly permissions: ReadonlyArray<Maybe<PackagePermission>>;
  /** Ссылки превью отправляемых документов */
  readonly docPreviewLinks?: Maybe<ReadonlyArray<Maybe<DocLink>>>;
  /** Статус */
  readonly status: PackageStatus;
  /** Тип пакета */
  readonly direction?: Maybe<PackageDirectionType>;
  /** Дата создания пакета */
  readonly creationDate: Scalars['Timestamp'];
  /** Регламент диалога */
  readonly regulation?: Maybe<DialogRegulation>;
};

export type PackageOperation = {
  __typename?: 'PackageOperation';
  /** Создать заявку на пакет документов */
  readonly createPackage: Scalars['String'];
  /** Изменение состояние пакета */
  readonly action: Scalars['Boolean'];
  /** Сохранение заполненных данных для пакета документов */
  readonly savePackageData: Scalars['FlexiblePackageObject'];
  /**
   * Проверка данных и отправка пакета документов
   * checkAndSend(packageId:String!, email: String!): Boolean! @protected(roles: ["api","demo-api","anonymous"])
   * Добавление элемента в список подстановки организации
   **/
  readonly addToCompletionList: SelectionListElement;
  /** Удаление элемента из списка подстановки организации */
  readonly removeFromCompletionList?: Maybe<Scalars['Boolean']>;
  /** Сохранение данных об организации в хранилище */
  readonly saveOrganizationData?: Maybe<Scalars['FlexiblePackageObject']>;
  /** Смена статуса диалога */
  readonly toggleConversationStatus: Scalars['Boolean'];
};

export type PackageOperationCreatePackageArgs = {
  attachments?: Maybe<ReadonlyArray<Maybe<File>>>;
  singlehandedly?: Maybe<Scalars['Boolean']>;
  regulationId?: Maybe<Scalars['String']>;
  from: CreatePackageFrom;
  to?: Maybe<CreatePackageTo>;
  type: PackageType;
};

export type PackageOperationActionArgs = {
  packageId: Scalars['String'];
  to?: Maybe<MessageTo>;
  message?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type PackageOperationSavePackageDataArgs = {
  data: Scalars['FlexiblePackageObject'];
  packageId: Scalars['String'];
};

export type PackageOperationAddToCompletionListArgs = {
  grn: Scalars['String'];
  list: Scalars['String'];
  value?: Maybe<Scalars['FlexiblePackageObject']>;
};

export type PackageOperationRemoveFromCompletionListArgs = {
  itemId?: Maybe<Scalars['String']>;
};

export type PackageOperationSaveOrganizationDataArgs = {
  grn: Scalars['String'];
  data: Scalars['FlexiblePackageObject'];
};

export type PackageOperationToggleConversationStatusArgs = {
  conversationId: Scalars['String'];
  status: ConversationStatus;
};

export enum PackagePermission {
  /** Редактирование */
  Edit = 'EDIT',
  /** Чтение */
  Read = 'READ',
  /** Возможность прикрепления пакета */
  Seize = 'SEIZE',
}

export enum PackageStatus {
  Unread = 'UNREAD',
  Touched = 'TOUCHED',
  Correction = 'CORRECTION',
  Refused = 'REFUSED',
  Ready = 'READY',
  Complete = 'COMPLETE',
}

export enum PackageType {
  Request = 'REQUEST',
  /** запрос пакета документов (прямой флоу) */
  Offer = 'OFFER',
  /** отправка своего пакета документов (обратный флоу) */
  Verification = 'VERIFICATION',
}

export enum PaymentServiceType {
  DialogTariff = 'DIALOG_TARIFF',
  Fz115 = 'FZ115',
  Api = 'API',
  ContractorReportSingle = 'CONTRACTOR_REPORT_SINGLE',
  EconomyBilledPoints = 'ECONOMY_BILLED_POINTS',
  OneDayAccess = 'ONE_DAY_ACCESS',
}

/** Информация о физическом лице */
export type Person = {
  __typename?: 'Person';
  /** Имя */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Фамилия */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Отчество */
  readonly middleName?: Maybe<Scalars['String']>;
  /** ИНН */
  readonly inn?: Maybe<Scalars['String']>;
  /** СНИЛС */
  readonly snils?: Maybe<Scalars['String']>;
  /** Дата рождения */
  readonly birthDate?: Maybe<Scalars['String']>;
  /** Место рождения */
  readonly birthPlace?: Maybe<Scalars['String']>;
  /** Адрес */
  readonly address?: Maybe<Scalars['String']>;
};

export type Pfr = {
  __typename?: 'Pfr';
  /** Регистрационный номер ПФР */
  readonly regNumber?: Maybe<Scalars['String']>;
  /** Дата постановки на учет в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Сведения о пенсионном фонде */
  readonly authority?: Maybe<Authority>;
};

export enum ProceedingStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export type ProductInfo = {
  __typename?: 'ProductInfo';
  /** Наименование документа */
  readonly name: Scalars['String'];
};

/** Дополнительная информация профиля */
export type ProfileDetails = {
  /** Статус аккаунта */
  readonly status: AccountStatus;
  /** Имя */
  readonly firstName: Scalars['String'];
  /** Фамилия */
  readonly lastName: Scalars['String'];
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
  /** Почтовый адрес */
  readonly email: Scalars['String'];
  /** Общее название группы компаний */
  readonly brandName?: Maybe<Scalars['String']>;
};

/** Связь, описывающая имущество */
export type PropertyRelation = {
  __typename?: 'PropertyRelation';
  readonly share?: Maybe<Scalars['Float']>;
  readonly amount?: Maybe<Scalars['Float']>;
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Компания, доля владения которой отражена в объекте */
  readonly property: Company;
};

export type PublicDataAddress = {
  __typename?: 'PublicDataAddress';
  /** Фактический адрес организации */
  readonly actual?: Maybe<Scalars['String']>;
  /** Почтовый адрес организации */
  readonly postal?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Получение данных об ИП */
  readonly individual: Individual;
  /** Получение данных аккаунта */
  readonly account?: Maybe<Account>;
  /** Получение данных об организации */
  readonly company: Company;
  readonly companies?: Maybe<ReadonlyArray<Company>>;
  /** Поиск организаций */
  readonly search: SearchResult;
  /** Получение данных о выписке */
  readonly extract: Extract;
  /** Получение данных о подписках */
  readonly subscription?: Maybe<ReadonlyArray<SubscriptionInfo>>;
  readonly authentication?: Maybe<Authentication>;
  /** Список диалогов */
  readonly conversations: ConversationList;
  /** диалог */
  readonly conversation?: Maybe<Conversation>;
  /** Получение справочника биков */
  readonly bank?: Maybe<BankInfo>;
  /** Биллинг */
  readonly billing: BillingRootQueryResult;
  /** Картотека арбитражных дел */
  readonly kad?: Maybe<Kad>;
  /** Системные параметры */
  readonly system?: Maybe<SystemRoot>;
};

export type QueryIndividualArgs = {
  ogrnip?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
};

export type QueryCompanyArgs = {
  ogrn?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
};

export type QueryCompaniesArgs = {
  ogrns?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type QuerySearchArgs = {
  filter?: Maybe<SearchFilter>;
  cityName?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryExtractArgs = {
  extractId: Scalars['String'];
};

export type QuerySubscriptionArgs = {
  subscriptionId?: Maybe<Scalars['String']>;
};

export type QueryConversationsArgs = {
  filter?: Maybe<ConversationFilter>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type QueryConversationArgs = {
  id: Scalars['String'];
};

export type QueryBankArgs = {
  bic: Scalars['String'];
};

export type QueryKadArgs = {
  number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** Информация о получателе */
export type ReceiverInfo = {
  __typename?: 'ReceiverInfo';
  /** Адрес электронной почты */
  readonly email: Scalars['String'];
  /** Название */
  readonly name?: Maybe<Scalars['String']>;
  /** Организация */
  readonly organization: LegalEntity;
  /** Получатель */
  readonly destination: Destination;
};

export type Record = {
  __typename?: 'Record';
  /** ГРН записи */
  readonly grn?: Maybe<Scalars['String']>;
  /** Дата записи в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Тип записи */
  readonly type?: Maybe<Scalars['String']>;
  /** Сведения о регистрирующем органе */
  readonly authority?: Maybe<Authority>;
  /** Документы */
  readonly documents?: Maybe<ReadonlyArray<Maybe<RecordDocument>>>;
};

export type RecordDocument = {
  __typename?: 'RecordDocument';
  /** Номер документа */
  readonly number?: Maybe<Scalars['String']>;
  /** Название документа */
  readonly name?: Maybe<Scalars['String']>;
  /** Дата регистрации документа в формате Unix Timestamp */
  readonly date?: Maybe<Scalars['Timestamp']>;
};

export type Region = {
  __typename?: 'Region';
  /** Полное наименование */
  readonly full?: Maybe<Scalars['String']>;
  /** Код региона */
  readonly code?: Maybe<Scalars['String']>;
  /** Наименование региона */
  readonly name?: Maybe<Scalars['String']>;
  /** Тип региона */
  readonly type?: Maybe<Scalars['String']>;
};

export type Registration = {
  __typename?: 'Registration';
  /** Дата регистрации в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Тип создания юридического лица */
  readonly typeOfCreation?: Maybe<TypeOfCreation>;
  /** Сведения о регистрирующем органе по месту нахождения юридического лица */
  readonly authority?: Maybe<Authority>;
};

export type RegistryHolder = {
  __typename?: 'RegistryHolder';
  /** Дата в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Информация о компании */
  readonly company?: Maybe<Company>;
};

export type ReorganizationParticipant = {
  __typename?: 'ReorganizationParticipant';
  /** Информация о компании */
  readonly company?: Maybe<Company>;
  /** Будущий статус компании */
  readonly futureStatus?: Maybe<FutureStatus>;
};

export type ReportCreateResult = {
  __typename?: 'ReportCreateResult';
  /** Ссылка на скачивание счета на оплату */
  readonly invoiceLink: Scalars['String'];
  readonly reportId: Scalars['String'];
};

export enum ReportDocType {
  ContractorReport = 'CONTRACTOR_REPORT',
}

export type ReportOperation = {
  __typename?: 'ReportOperation';
  /** Создание отчета фз115 */
  readonly create: ReportCreateResult;
};

export type ReportOperationCreateArgs = {
  ogrn: Scalars['String'];
};

export type Risk = {
  __typename?: 'Risk';
  /** Код риска */
  readonly risk: Scalars['String'];
  /** Результат проверки риска */
  readonly title: Scalars['String'];
  /** Описание результата проверки риска */
  readonly description: Scalars['String'];
  /** Источник */
  readonly source: Scalars['String'];
  /** Дополнительная информация по риску */
  readonly occurrences: ReadonlyArray<Occurrence>;
};

export type RiskOccurrence = {
  __typename?: 'RiskOccurrence';
  /** Краткое описание */
  readonly title: Scalars['String'];
  /** Подробное описание */
  readonly description: Scalars['String'];
  /** Степень риска */
  readonly severity: SeverityType;
  /** Вес риска */
  readonly weight: Scalars['Float'];
  /** Дата возникновения риска */
  readonly date: Scalars['ZDateTime'];
};

export type RiskSource = {
  __typename?: 'RiskSource';
  /** Название источника */
  readonly name: Scalars['String'];
  /** Дата обновления источника */
  readonly date: Scalars['ZDateTime'];
};

export type RoleInformation = {
  __typename?: 'RoleInformation';
  /** Наименование выданной роли */
  readonly name: Scalars['String'];
};

export type SearchFilter = {
  readonly status?: Maybe<SearchStatusFilter>;
  readonly registry?: Maybe<SearchRegistryFilter>;
  readonly forms?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly regions?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum SearchRegistryFilter {
  Companies = 'COMPANIES',
  Individuals = 'INDIVIDUALS',
  CompaniesAndIndividuals = 'COMPANIES_AND_INDIVIDUALS',
}

/** Результат поиска */
export type SearchResult = {
  __typename?: 'SearchResult';
  /** Список компаний */
  readonly companies: ReadonlyArray<Company>;
  /** Список ЮЛ и ИП */
  readonly entities: ReadonlyArray<LegalEntity>;
  /** Количество результатов поиска */
  readonly total?: Maybe<Scalars['Long']>;
  /** Количество результатов на странице */
  readonly count?: Maybe<Scalars['Int']>;
  /** Номер страницы */
  readonly page?: Maybe<Scalars['Int']>;
};

export enum SearchStatusFilter {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Any = 'ANY',
}

export type SelectionListElement = {
  __typename?: 'SelectionListElement';
  readonly listId: Scalars['String'];
  readonly itemId: Scalars['String'];
  readonly value: Scalars['FlexiblePackageObject'];
};

export enum ServiceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum SeverityType {
  /** Высокий риск */
  Severe = 'SEVERE',
  /** Средний риск */
  Medium = 'MEDIUM',
  /** Низкий риск */
  Low = 'LOW',
  /** Незначительный риск */
  Notice = 'NOTICE',
  /** Риск отсутствует */
  None = 'NONE',
}

/** Субъекты малого и среднего бизнеса */
export type SmallMediumBusiness = {
  __typename?: 'SmallMediumBusiness';
  /** micro/small/medium */
  readonly category: SmbCategory;
  /** Микропредприятие, Малое предприятие, Среднее предприятие */
  readonly description: Scalars['String'];
  /** Количество сотрудников (До 15 человек, 16 – 100 человек, 101 — 250 человек) */
  readonly employees: Scalars['String'];
  /** Сведения о производимой субъектом МСП продукции */
  readonly products?: Maybe<ReadonlyArray<SmbProduct>>;
  /** Дата включения ЮЛ или ИП в реестр в формате Unix Timestamp */
  readonly inclusionDate?: Maybe<Scalars['Timestamp']>;
};

/** Константы категорий СМП */
export enum SmbCategory {
  Micro = 'MICRO',
  Small = 'SMALL',
  Medium = 'MEDIUM',
}

/** Сведения о производимой субъектом МСП продукции */
export type SmbProduct = {
  __typename?: 'SmbProduct';
  /** Код продукта ОК 034-2014 (КПЕС 2008)  (ОКПД 2 –расширенный состав) */
  readonly code?: Maybe<Scalars['String']>;
  /** Наименование продукта ОК 034-2014 (КПЕС 2008)  (ОКПД 2 –расширенный состав) */
  readonly name?: Maybe<Scalars['String']>;
  /** Флаг, указывающий является ли данная продукция инновационной, высокотехнологичной */
  readonly innovative?: Maybe<Scalars['Boolean']>;
};

export type Statistic = {
  __typename?: 'Statistic';
  /** Общее количество сертификатов и деклараций */
  readonly totalCount: Scalars['Long'];
};

export type Status = {
  __typename?: 'Status';
  /** Флаг, показывающий ликвидирована ли компания */
  readonly isLiquidated?: Maybe<Scalars['Boolean']>;
  /** Текстовое описание статуса */
  readonly text?: Maybe<Scalars['String']>;
  /** Дата последнего изменения статуса в формете Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Текущий статус компании */
  readonly currentStatus?: Maybe<CurrentStatus>;
  /** Будущий статус компании */
  readonly futureStatus?: Maybe<FutureStatus>;
  /** Участники реорганизации */
  readonly reorganizationParticipants?: Maybe<ReadonlyArray<Maybe<ReorganizationParticipant>>>;
};

export type StorageData = {
  __typename?: 'StorageData';
  readonly data: Scalars['FlexiblePackageObject'];
  /** Последнее изменение */
  readonly lastModification?: Maybe<LastModification>;
};

export type StoragePublicData = {
  __typename?: 'StoragePublicData';
  /** Адрес организации */
  readonly address?: Maybe<PublicDataAddress>;
  /** Информация о налогообложении и количестве сотрудников компании */
  readonly tax?: Maybe<Tax>;
  /** Контакты организации */
  readonly contacts?: Maybe<Contact>;
  /** Информация о банках */
  readonly bankAccounts?: Maybe<ReadonlyArray<Maybe<BankAccount>>>;
  /** Описание компании */
  readonly description?: Maybe<Scalars['String']>;
};

export type StructureMember = Person | Company | ForeignOrganization;

/** Информация об отправителе */
export type SubmitterInfo = {
  __typename?: 'SubmitterInfo';
  /** Организация */
  readonly organization: LegalEntity;
  /** Имя */
  readonly name: Scalars['String'];
  /** Адрес электронной почты */
  readonly email?: Maybe<Scalars['String']>;
  /** Признак зарегистрированности пользователя */
  readonly isRegistered: Scalars['Boolean'];
  /** Получатель */
  readonly destination: Destination;
};

/** Информация о подписках */
export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  /** Идентификатор подписки */
  readonly id: Scalars['String'];
  /** Название подписки */
  readonly name?: Maybe<Scalars['String']>;
  /** url для отправки */
  readonly url?: Maybe<Scalars['String']>;
  /** Включить выписки */
  readonly includeExtract?: Maybe<Scalars['Boolean']>;
  /** Активная подписка */
  readonly active?: Maybe<Scalars['Boolean']>;
  /** Список компаний в подписке */
  readonly ogrns: ReadonlyArray<Scalars['String']>;
  readonly dispatchTime?: Maybe<Scalars['String']>;
};

export type SubscriptionOperation = {
  __typename?: 'SubscriptionOperation';
  /** Создание подписки */
  readonly create: SubscriptionInfo;
  /** Изменение подписки */
  readonly change: SubscriptionInfo;
  /** Удаление подписки */
  readonly delete: Scalars['String'];
  /** Добавление списка компаний в подписку */
  readonly addCompanies: SubscriptionInfo;
  /** Удаление списка компаний из подписки */
  readonly deleteCompanies: SubscriptionInfo;
  /** Перезапись списка компаний в подписке */
  readonly overwriteCompanies: SubscriptionInfo;
  /** Ручной запуск обновлений по подписке */
  readonly triggerUpdate?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionOperationCreateArgs = {
  dispatchTime?: Maybe<Scalars['String']>;
  ogrns?: Maybe<ReadonlyArray<Scalars['String']>>;
  includeExtract?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type SubscriptionOperationChangeArgs = {
  dispatchTime: Scalars['String'];
  includeExtract: Scalars['Boolean'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  subscriptionId: Scalars['String'];
  url: Scalars['String'];
};

export type SubscriptionOperationDeleteArgs = {
  subscriptionId: Scalars['String'];
};

export type SubscriptionOperationAddCompaniesArgs = {
  ogrns: ReadonlyArray<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

export type SubscriptionOperationDeleteCompaniesArgs = {
  ogrns: ReadonlyArray<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

export type SubscriptionOperationOverwriteCompaniesArgs = {
  ogrns: ReadonlyArray<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

export type SubscriptionOperationTriggerUpdateArgs = {
  date: Scalars['Long'];
  type?: Maybe<SubscriptionUpdateType>;
  subscriptionId: Scalars['String'];
  overrideTime?: Maybe<Scalars['String']>;
};

export enum SubscriptionUpdateType {
  Full = 'FULL',
  Changed = 'CHANGED',
}

export type SystemDateTimeRoot = {
  __typename?: 'SystemDateTimeRoot';
  readonly value?: Maybe<Scalars['Timestamp']>;
};

export type SystemHealthRoot = {
  __typename?: 'SystemHealthRoot';
  readonly ok?: Maybe<Scalars['String']>;
};

export type SystemOperation = {
  __typename?: 'SystemOperation';
  readonly log: LogResponse;
};

export type SystemOperationLogArgs = {
  metadata?: Maybe<ReadonlyArray<LogMetadata>>;
  index?: Maybe<ReadonlyArray<LogMetadata>>;
  details?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  reporter?: Maybe<Scalars['String']>;
  type: LogType;
  message: Scalars['String'];
};

export type SystemRoot = {
  __typename?: 'SystemRoot';
  readonly health?: Maybe<SystemHealthRoot>;
  readonly version?: Maybe<SystemVersionRoot>;
  readonly dateTime?: Maybe<SystemDateTimeRoot>;
};

export type SystemVersionRoot = {
  __typename?: 'SystemVersionRoot';
  readonly value?: Maybe<Scalars['String']>;
};

export type Tax = {
  __typename?: 'Tax';
  /** Система налогообложения */
  readonly system?: Maybe<Scalars['String']>;
  /** Количество сотрудников в организации */
  readonly employees?: Maybe<Scalars['String']>;
};

/** Задолженность по налогам */
export type TaxArrears = {
  __typename?: 'TaxArrears';
  /** Наименование задолженности */
  readonly code: Scalars['String'];
  /** Сумма задолжности */
  readonly taxAmount: Scalars['Long'];
  /** Сумма пени */
  readonly penaltiesAmount: Scalars['Long'];
  /** Сумма штрафа */
  readonly finesAmount: Scalars['Long'];
  /** Общая сумма задолжности */
  readonly totalAmount: Scalars['Long'];
};

export type TaxArrearsData = {
  __typename?: 'TaxArrearsData';
  /** Информация о суммах недоимки и задолженности по пеням и штрафам */
  readonly values: ReadonlyArray<Maybe<TaxArrearsValue>>;
  /** Список всех налогов */
  readonly layout: TaxLayout;
};

export type TaxArrearsValue = {
  __typename?: 'TaxArrearsValue';
  /** Год задолженности */
  readonly year?: Maybe<Scalars['String']>;
  /** Информация о суммах недоимки и задолженности по пеням и штрафам */
  readonly data: ReadonlyArray<Maybe<TaxArrears>>;
};

/** Сведения ФНС */
export type TaxationData = {
  __typename?: 'TaxationData';
  /** Сведения о среднесписочной численности работников организации */
  readonly averageEmployeesNumber?: Maybe<Scalars['Int']>;
  /** Сведения о специальных налоговых режимах, применяемых налогоплательщиками */
  readonly taxSystemsApplication?: Maybe<ReadonlyArray<Maybe<TaxSystemsApplicationType>>>;
  /** РСМП, реестр субъектов малого и среднего предпринимательства */
  readonly rsmpRegistry?: Maybe<SmallMediumBusiness>;
  /**
   * Сведения об уплаченных организацией в календарном году, предшествующем году
   * размещения указанных сведений в информационно-телекоммуникационной сети
   * "Интернет" в соответствии с пунктом 1.1 статьи 102 Налогового кодекса
   * Российской Федерации, суммах налогов и сборов (по каждому налогу и сбору) без
   * учета сумм налогов (сборов), уплаченных в связи с ввозом товаров на таможенную
   * территорию Евразийского экономического союза, сумм налогов, уплаченных
   * налоговым агентом, о суммах страховых взносов
   **/
  readonly taxesPaid: TaxPaidData;
  /** Сведения о налоговых правонарушениях и мерах ответственности за их совершение */
  readonly taxOffenses: TaxOffensesData;
  /** Сведения о суммах недоимки и задолженности по пеням и штрафам */
  readonly taxArrears: TaxArrearsData;
};

/** Сведения ФНС */
export type TaxationDataTaxesPaidArgs = {
  filter?: Maybe<YearsFilter>;
};

/** Сведения ФНС */
export type TaxationDataTaxOffensesArgs = {
  filter?: Maybe<YearsFilter>;
};

/** Сведения ФНС */
export type TaxationDataTaxArrearsArgs = {
  filter?: Maybe<YearsFilter>;
};

export type TaxDicInfo = {
  __typename?: 'TaxDicInfo';
  /** Код налога */
  readonly order: Scalars['Int'];
  /** Наименование */
  readonly code: Scalars['String'];
  /** Отображаемое название */
  readonly displayName: Scalars['String'];
};

export type TaxInspection = {
  __typename?: 'TaxInspection';
  /** Дата постановки на учет в формате Unix Timestamp */
  readonly grnDate?: Maybe<Scalars['Timestamp']>;
  /** Сведения о налоговом органе, в котором юридическое лицо состоит на учете */
  readonly authority?: Maybe<Authority>;
};

export type TaxLayout = {
  __typename?: 'TaxLayout';
  /** Cписок налогов */
  readonly allTaxes: ReadonlyArray<TaxDicInfo>;
};

export type TaxOffenses = {
  __typename?: 'TaxOffenses';
  /** Год нарушений */
  readonly year: Scalars['String'];
  /** Сумма штрафа */
  readonly finesAmount?: Maybe<Scalars['Long']>;
};

export type TaxOffensesData = {
  __typename?: 'TaxOffensesData';
  /** Данные о налоговых правонарушениях и мерах ответственности за их совершение */
  readonly values: ReadonlyArray<Maybe<TaxOffenses>>;
};

export type TaxPaid = {
  __typename?: 'TaxPaid';
  /** Наименование налогов или взносов */
  readonly code: Scalars['String'];
  /** Сумма уплаченных налогов или взносов */
  readonly amount: Scalars['Long'];
};

export type TaxPaidData = {
  __typename?: 'TaxPaidData';
  readonly values: ReadonlyArray<Maybe<TaxPaidValue>>;
  readonly layout: TaxLayout;
};

export type TaxPaidValue = {
  __typename?: 'TaxPaidValue';
  /** Год, по которому предоставляется информация */
  readonly year?: Maybe<Scalars['String']>;
  /** Данные по налогам и взносам */
  readonly data: ReadonlyArray<Maybe<TaxPaid>>;
};

export enum TaxSystemsApplicationType {
  /** ОСНО */
  Osno = 'OSNO',
  /** ЕСХН */
  Eshn = 'ESHN',
  /** ЕНВД */
  Envd = 'ENVD',
  /** УСН */
  Usn = 'USN',
  /** СРП */
  Srp = 'SRP',
}

/** данные сторонних источников */
export type ThirdPartyData = {
  __typename?: 'ThirdPartyData';
  readonly hh?: Maybe<HhData>;
};

export type TypeOfCreation = {
  __typename?: 'TypeOfCreation';
  /** Код регистрации */
  readonly code?: Maybe<Scalars['String']>;
  /** Причина регистрации */
  readonly name?: Maybe<Scalars['String']>;
};

/** Сущность компании, которую не удалось идентифицировать */
export type UnverifiedEntity = {
  __typename?: 'UnverifiedEntity';
  /** Наименование */
  readonly name?: Maybe<Scalars['String']>;
  /** Адрес */
  readonly address?: Maybe<Address>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  /** Идентификационный номер */
  readonly id: Scalars['String'];
  /** Имя */
  readonly name?: Maybe<Scalars['String']>;
  /** Должность */
  readonly position?: Maybe<Scalars['String']>;
};

export type UserTokenInfo = {
  __typename?: 'UserTokenInfo';
  readonly id: Scalars['String'];
  readonly alias?: Maybe<Scalars['String']>;
  /** Часть символов токена для его опознания */
  readonly token: Scalars['String'];
  /** Время окончания действия */
  readonly expiring: Scalars['Timestamp'];
  /** Время выдачи токена */
  readonly issuedAt: Scalars['Timestamp'];
  /** статус: активен/удален/заблокирован */
  readonly status: UserTokenStatus;
  readonly apiType: ApiType;
};

export enum UserTokenStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Blocked = 'BLOCKED',
}

export type Verification = {
  __typename?: 'Verification';
  readonly date?: Maybe<VerificationDate>;
  /** Признак подтверждённости компании в сервисе Контрагентио */
  readonly isVerified: Scalars['Boolean'];
  readonly data?: Maybe<StoragePublicData>;
};

export type VerificationDate = {
  __typename?: 'VerificationDate';
  /** Дата подтверждения организации в формате Unix Timestamp */
  readonly first?: Maybe<Scalars['Timestamp']>;
  /** Дата последнего изменения в формате Unix Timestamp */
  readonly last?: Maybe<Scalars['Timestamp']>;
};

export type VerifiedOrganizationData = {
  __typename?: 'VerifiedOrganizationData';
  readonly possibleChoices: ReadonlyArray<SelectionListElement>;
  readonly actualData: StorageData;
  readonly dialogRegulations: ReadonlyArray<Maybe<DialogRegulation>>;
  readonly publicData?: Maybe<StoragePublicData>;
};

export type VerifiedOrganizationDataPossibleChoicesArgs = {
  list?: Maybe<Scalars['String']>;
};

export type YearsFilter = {
  readonly years: ReadonlyArray<Maybe<Scalars['Int']>>;
};
