import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncPaymentPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "payment-page" */ './PaymentPage').then((res) => res.PaymentPage),
  loading() {
    return <SlimLoader pending />;
  },
});
