import { useQuery } from '@apollo/react-hooks';
import { ACCOUNT_INFORMATION_QUERY, IAccountInformationResponse } from './queries/me';
import { IUserDataProviderRenderProps, mapProps } from './UserDataProvider';
import { FetchPolicy } from 'apollo-client';

export const useAccountData = (
  { fetchPolicy }: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'cache-first' },
): IUserDataProviderRenderProps => {
  const result = useQuery<IAccountInformationResponse>(ACCOUNT_INFORMATION_QUERY, {
    fetchPolicy,
  });
  return mapProps(result);
};
