import gql from 'graphql-tag';
import {
  ConversationStatus,
  MessageType,
  IFileAttachment,
  IMessageParticipant,
  IDialogParticipant,
} from '../../common/types';
import { PackageStatus } from '@app/common-declarations/dialog';

const shallowDialogFragment = gql`
  fragment shallowDialog on PackageInfo {
    id
    status
    submitter {
      organization {
        ...shallowDialogCompany
        ...shallowDialogIndividual
      }
      email
      name
      destination
    }
    receiver {
      organization {
        ...shallowDialogCompany
        ...shallowDialogIndividual
      }
      email
      name
      destination
    }
  }

  fragment dialogPerson on Person {
    firstName
    lastName
    middleName
    inn
  }

  fragment shallowDialogCompany on Company {
    ogrn
    inn
    shortName
    fullName
    correctedName: shortName(autoCorrection: true)
    form {
      compact
      full
    }
    management {
      head {
        ... on Person {
          ...dialogPerson
        }
      }
      position
      code
    }
  }

  fragment shallowDialogIndividual on Individual {
    ogrnip
    inn
    firstName
    lastName
    middleName
    form {
      compact
      full
    }
  }

  fragment shallowParticipant on MessageAddress {
    user: userInfo {
      id
      name
    }
    organization {
      ...shallowDialogCompany
      ...shallowDialogIndividual
    }
    relation: destination
  }

  fragment shallowMessageBody on MessageInfo {
    id
    text
    type
    attachments {
      name
      url
      status
      ext
    }
    timestamp
    to {
      ...shallowParticipant
    }
    from {
      ...shallowParticipant
    }
  }
`;

export const GET_MESSAGES_QUERY = gql`
  ${shallowDialogFragment}

  query getConversations(
    $grn: String
    $status: ConversationStatusFilter
    $dialogStatus: PackageStatus
    $direction: PackageDirectionType
    $first: Int
    $after: String
  ) {
    conversations(
      first: $first
      after: $after
      filter: { grn: $grn, status: $status, direction: $direction, dialogStatus: $dialogStatus }
    ) {
      edges {
        cursor
        conversation {
          id
          dialog: packageInfo {
            ...shallowDialog
          }
          status
          latestMessage {
            ...shallowMessageBody
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_CONVERSATIONS_STATUS_QUERY = gql`
  query getConversationsStatus($first: Int, $status: ConversationStatusFilter) {
    conversations(first: $first, filter: { status: $status }) {
      totalCount
      edges {
        conversation {
          status
        }
      }
    }
  }
`;

export const GET_CONVERSATION_COUNT_QUERY = gql`
  query getConversationCount(
    $grn: String
    $status: ConversationStatusFilter
    $direction: PackageDirectionType
    $first: Int
    $after: String
  ) {
    conversations(first: $first, after: $after, filter: { grn: $grn, status: $status, direction: $direction }) {
      totalCount
    }
  }
`;

export const LEAD_MUTATION = gql`
  mutation addLead($additionalInfo: String, $from: LeadSource!, $email: String!) {
    lead {
      create(additionalInfo: $additionalInfo, from: $from, email: $email)
    }
  }
`;

type ShallowMessageParticipant = IMessageParticipant<ShallowConversationIndividual | ShallowConversationCompany>;
type ShallowDialogParticipant = IDialogParticipant<ShallowConversationIndividual | ShallowConversationCompany>;

export interface ShallowDialog {
  id: string;
  submitter: ShallowDialogParticipant;
  receiver: ShallowDialogParticipant;
  status: PackageStatus;
}

export interface ShallowConversationMessage {
  id: string;
  text: string;
  type: MessageType;
  attachments: IFileAttachment[];
  timestamp: number;
  to: ShallowMessageParticipant;
  from: ShallowMessageParticipant;
}

export interface ShallowConversation {
  id: string;
  dialog: ShallowDialog;
  status: ConversationStatus;
  latestMessage: ShallowConversationMessage;
  messages: ShallowConversationMessage[];
}

export interface ShallowConversationPerson {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  inn?: string;
}

export interface ShallowConversationIndividual extends ShallowConversationPerson {
  ogrnip: string;
  inn?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  form: {
    compact?: string;
    full?: string;
  };
}

export interface ShallowConversationCompany {
  ogrn: string;
  inn?: string;
  shortName?: string;
  fullName: string;
  correctedName: string;
  form: {
    compact?: string;
    full?: string;
  };
  management: ReadonlyArray<{
    grnDate: number;
    head: {} | ShallowConversationPerson;
    position: string;
    code: string;
  }>;
}

export interface GetMessagesResponse {
  conversations: {
    totalCount: number;
    edges: Array<{
      conversation: ShallowConversation;
      cursor: string;
    }>;
  };
}

export interface GetCountResponse {
  conversations: {
    totalCount: number;
  };
}

export interface GetConversationsStatusResponse {
  conversations: {
    totalCount: number;
    edges: Array<{
      conversation: {
        status: 'READ' | 'UNREAD';
      };
    }>;
  };
}

export interface AddLead {
  lead: {
    create: LeadVariables;
  };
}

export interface LeadVariables {
  additionalInfo: string;
  from: string;
  email?: string;
}

export interface GetMessagesVariables {
  grn?: string;
  direction?: 'INCOMING' | 'OUTGOING';
  status?: 'READ' | 'UNREAD' | 'ALL';
  dialogStatus?: 'COMPLETE';
  first?: number;
  after?: string;
}
