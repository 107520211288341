import React, { useEffect, useRef } from "react";

interface IScriptLoaderProps {
    async: string
    children?: any, 
    src: string,
    onLoad: any 
}

export const ScriptLoader = (props: IScriptLoaderProps) => {
    const ref = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
      if (
        ref.current!.lastElementChild &&
        ref.current!.lastElementChild.getAttribute("src") === props.src
      ) {
        return;
      }
      const script = document.createElement("script");
      script.setAttribute("async", props.async);
      script.setAttribute("src", props.src);
  
      script.onload = props.onLoad;
  
      ref.current!.appendChild(script);
    }, [props.async, props.src, props.onLoad]);
  
    return <div ref={ref}>{props.children}</div>;
}


