import Loadable from 'react-loadable';
import React from 'react';
import { SlimLoader } from 'kontragentio-reusable-components';

export const AsyncSearchEntryPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "search-entry" */ './SearchEntryPage').then((res) => res.SearchEntryPage),
  loading() {
    return <SlimLoader pending />;
  },
});
