import { useMutation } from '@apollo/react-hooks';
import { useTokenProvider } from '@app/connectivity/components/token-provider/useTokenProvider';
import {
  AUTHENTICATION_MUTATION,
  IAuthenticationRequestVariables,
  IAuthenticationResponse,
} from './queries/authentication';
import { IRegistrationRequestVariables, REGISTRATION_MUTATION } from './queries/registration';
import { IPasswordResetResponse, IPasswordResetVariables, RESET_PASSWORD_MUTATION } from './queries/reset-password';

export const useSignInAction = () => {
  const tokenProvider = useTokenProvider();
  const [login] = useMutation<IAuthenticationResponse, IAuthenticationRequestVariables>(AUTHENTICATION_MUTATION);

  return async (email: string, password: string) => {
    // tokenProvider.clearToken();

    const result = await login({
      variables: {
        email,
        password,
      },
    });

    if (result && result.data) {
      tokenProvider.setToken(result.data.authentication.token);
    } else {
      tokenProvider.clearToken();
    }
  };
};

export const useSignUpAction = () => {
  const tokenProvider = useTokenProvider();
  const [register] = useMutation<IAuthenticationResponse, IRegistrationRequestVariables>(REGISTRATION_MUTATION);

  return async (email: string, password: string, details: { firstName: string; lastName: string }) => {
    const result = await register({
      variables: {
        email,
        password,
        details,
      },
    });

    if (result && result.data) {
      tokenProvider.setToken(result.data.authentication.token);
    } else {
      tokenProvider.clearToken();
    }
  };
};

export const useSignOffAction = () => {
  const tokenProvider = useTokenProvider();

  return async () => {
    tokenProvider.clearToken();
  };
};

export const useResetPasswordAction = () => {
  const [register] = useMutation<IPasswordResetResponse, IPasswordResetVariables>(RESET_PASSWORD_MUTATION);

  return async (email: string) => {
    return await register({
      variables: {
        email,
      },
    });
  };
};
