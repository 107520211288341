import jwt from 'jsonwebtoken';

export function isTokenOutdated(token?: string) {
  if (!token) {
    return false;
  }

  const decoded = jwt.decode(token) as Record<string, string>;
  if (!decoded.hasOwnProperty('authorities')) {
    return true;
  }
}
